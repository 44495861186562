import { Field, useField } from 'formik';
import React from 'react';

type HandleInputProps = {
  label?: string;
  name: string;
  type?:string
  show?:boolean
  disabled?:boolean
  placeholder?:string
}

const HandleInput: React.FC<HandleInputProps> = ({ label, name,type = "text",placeholder,disabled=false,show=true }) => {

  const [field, meta] = useField(name);

  return (
    <div className="mb-4">
      {label && (<label className="form-label required">{label}</label>)}
      <Field 
        type={type}
        min={type === 'number' ? 0 : null}
        id={name}
        className={"form-control "}
        style={{ "display": !show?'none':'bloc' }}
        placeholder={placeholder}
        disabled={disabled}
        {...field}
      />
      {meta.touched && meta.error ? (
        <div className='fv-plugins-message-container'>
        <div className='fv-help-block'>{meta.error}</div>
      </div>
      ) : null}
    </div>
  );
};

export default HandleInput;