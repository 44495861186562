import {FC, useState} from 'react'
import { useQueryClient } from 'react-query'
import { Toast } from '../../../../../../../_components/SweetAlerts/SweetAlert'
import { QUERIES } from '../../../../../../../_metronic/helpers'
import { DestinationType } from '../../../core/_models'
import { destinationSetCanonical } from '../../../core/_request'


type Props = {
  listing: DestinationType
}


const IsCanonicalCell: FC<Props> = ({listing}) => {
  const queryClient = useQueryClient()
  const [isChecked, setIsChecked] = useState<boolean>(listing?.isCanonical||false);
  
  const handleChange = async () => {
    const updatedStatus = !isChecked;
    setIsChecked(updatedStatus)

    try {
      destinationSetCanonical(listing.id+"",updatedStatus)
      queryClient.invalidateQueries([`${QUERIES.LISTING_VENTE_LIST}`])

      Toast.fire({
        icon: 'success',
        title: 'updated'
      })
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: 'error'
      })
    }
   
  };

  return (
  <div>
    <div className="form-check form-switch form-check-custom  me-10">
      <input 
        className="form-check-input h-20px w-30px" 
        type="checkbox" 
        checked={isChecked}
        onChange={handleChange}
      />
    </div>
  </div>  
  )
}

export {IsCanonicalCell}
