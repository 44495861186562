import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import ArticlePage from '../modules/features/Articles/ArticlePage'
import SEOPage from '../modules/features/PageSeo/SEOPage'

// import {  } from '../modules/features/settings/settingsArticles/ArticlePage'



const PrivateRoutes = () => {
  const ListingVentePage = lazy(() => import('../modules/features/listing-vente/ListingVentePage'))
  const RestaurantPage = lazy(() => import('../modules/features/restaurant/RestaurantPage'))
  const ExplorePage = lazy(() => import('../modules/features/explore/ExplorePage'))
  const ExploreRegionPage = lazy(() => import('../modules/features/Explore-region/ExploreRegionPage'))
  const ActivityPage = lazy(() => import('../modules/features/Activity/ActivityPage'))
  const ConciergePage = lazy(() => import('../modules/features/Concierge/ConciergePage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const UsersPage = lazy(() => import('../modules/features/user-management/UsersPage'))
  const GuestsPage = lazy(() => import('../modules/features/guests-management/GuestPage'))
  const OwnersPage = lazy(() => import('../modules/features/owners-management/OwnersPage'))
  const ReservationGuestyPage = lazy(() => import('../modules/features/guesty/reservation-guesty/ReservationGuestyPage'))
  const ListingGuestyPage = lazy(() => import('../modules/features/guesty/listing-guesty/ListingGuestyPage'))
  const SettingsPage = lazy(() => import('../modules/features/settings/SettingsPage'))
  const ChatPage = lazy(() => import('../modules/features/guesty/chat/ChatPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
      
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/inbox-management/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/guests-management/*'
          element={
            <SuspensedView>
              <GuestsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/owners-management/*'
          element={
            <SuspensedView>
              <OwnersPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/listing-vente/*'
          element={
            <SuspensedView>
              <ListingVentePage />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/listing-guesty/reservation/*'
          element={
            <SuspensedView>
              <ReservationGuestyPage />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/listing-guesty/listing/*'
          element={
            <SuspensedView>
              <ListingGuestyPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/settings/*'
          element={
            <SuspensedView>
              <SettingsPage />
            </SuspensedView>
          }
        />
         <Route
          path='apps/discover/*'
          element={
            <SuspensedView>
              <RestaurantPage />
            </SuspensedView>
          }
        /> 
        <Route
          path='apps/explore/region/*'
          element={
            <SuspensedView>
              <ExploreRegionPage />
            </SuspensedView>
          }
        /> 
        <Route
        path='apps/explore/destination/*'
        element={
          <SuspensedView>
            <ExplorePage />
          </SuspensedView>
        }
      />
      
       <Route
        path='apps/explore/sub-destination/*'
        element={
          <SuspensedView>
            <ActivityPage />
          </SuspensedView>
        }
      />
         <Route
          path='apps/articles/*'
          element={
            <SuspensedView>
              <ArticlePage/>
            </SuspensedView>
          }
        />
           <Route
          path='apps/settings/conciergerie/*'
          element={
            <SuspensedView>
              <ConciergePage/>
            </SuspensedView>
          }
        />
           <Route
          path='apps/settings/SEO/*'
          element={
            <SuspensedView>
              <SEOPage/>
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
