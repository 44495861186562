import axios from 'axios'
import { getAuth } from './AuthHelpers'
import {AuthModel, UserModel, UserRoleModel} from './_models'
import { User } from '../../features/user-management/users-list/core/_models'

export const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/users/me`
export const LOGIN_URL = `${API_URL}/auth/login`
export const CHECK_EMAIL_IF_EXIST_PASSWORD_URL = `${API_URL}/auth/users/forgetPasswordEmail`
export const RESET_PASSWORD_URL = `${API_URL}/auth/users/changePassword`
export const VERIFY_ACCOUNT_URL = `${API_URL}/auth/users/createPassword`

export const CHECK_TOKEN_EXPIRE_URL = `${API_URL}/auth/user/verifyToken`
export const GET_ROLES = `${API_URL}/role/roles`



export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {email,password})
}

export function getUserByToken() {
  const auth = getAuth()
  const authorization = auth ? `Bearer ${auth.authToken}` : ''

  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL,{headers:{ Authorization:authorization }})
}



// export function getRoles() {
//   const auth = getAuth()
//   const authorization = auth ? `Bearer ${auth.authToken}` : ''

//   return axios.get<UserRoleModel>(GET_ROLES,{headers:{ Authorization:authorization }})
// }

export function checkEmailIfExist(email: string) {
  return axios.post(CHECK_EMAIL_IF_EXIST_PASSWORD_URL,{email:email})
}

export function checkTokenExpire(token : string) {
  return axios.get<User>(GET_USER_BY_ACCESSTOKEN_URL,{headers:{ Authorization:`Bearer ${token}`}})
    .then((res)=>res.data)
}

export function resetPassword(email: string, password: string,token:string) {
  return axios.patch(RESET_PASSWORD_URL, {email,password},{headers:{ Authorization:`Bearer ${token}`}})
}

export function verifyAccount(email: string, password: string) {
  return axios.patch(VERIFY_ACCOUNT_URL, {email,password})
}