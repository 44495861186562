import { Visibility } from "@mui/icons-material"
import FormControl from "../../../../../../../../_components/formControl/FormControl"

const ArticleSEO = () => {
  return (
    <>
      <div className="card card-custom card-flush mb-6">
        <div className="card-body">
          <div className='col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-md-5 '>
            <label className='col-lg-4 col-form-label required fw-bold fs-6'>SEO</label>
            <div className='row'>
              <div className='col-sm-6 fv-row'>
                <FormControl
                  label='Slug'
                  name="name"
                  type="text"
                  disabled={true}
                  placeholder='slug ...'
                />
              </div>
              <div className='col-sm-6 fv-row'>
              
              </div>
              <div className='col-sm-6 fv-row'>
              
                <FormControl
                  label='meta_titre'
                  name={`seo.titre`}
                  type="text"
                  placeholder='meta_titre ...'
                />
              </div>
              <div className='col-sm-6 fv-row'>
              <FormControl
                  label='meta_title'
                  name={`seo.title`}
                  type="text"
                  placeholder='meta_title ...'
                />
              </div>
            </div>
            <div className='row'>
            <div className='col-sm-6 fv-row'>
                <FormControl
                  label='meta_description fr'
                  name={`seo.descriptionFR`}
                  type='textarea'
                  placeholder='meta_description fr...'
                />
              </div>
              <div className='col-sm-6 fv-row'>
                <FormControl
                  label='meta_description en'
                  name={`seo.descriptionENG`}
                  type='textarea'
                  placeholder='description en ...'
                />
              </div>
            </div>
            </div>
            <div className='row'>
             
              <div className='col-sm-6 fv-row'>
                <FormControl
                  name={`seo.id`}
                  type='text'
                  show={false}
                />
              </div>
            </div>
          </div>
        </div>
     
    </>
  )
}

export default ArticleSEO