import React from 'react'
import LogoDark from '../../../../../_metronic/assets/images/Logo/LogoDark.png' 
import { ReservationDetailsResponse } from '../../../../modules/features/guesty/reservation-guesty/reservation-view/Core/_models'

type HeaderProps = {
    reservation:ReservationDetailsResponse
}
const Header:React.FC<HeaderProps> = ({reservation}) =>{
  return (
    <> 
        <div className="row my-sm-10 my-16">
            <div className="col-lg-12 d-flex justify-content-center align-items-center">
            <img src={LogoDark} alt="logo" width={'150px'} />
            </div>
        </div>
        <div className="row my-10 mx-lg-16">
            <div className="col-lg-8">
            <div className="card rounded-0 min-h-100">
                <div className="card-body d-flex justify-content-center align-items-center">
                <div className="row">
                    <div className="col-lg-4">
                    <img src={reservation.listing?.picture?.thumbnail} alt={reservation.listing.title} width={'100%'} />
                    </div>
                    <div className="col-lg-8 mt-10 mt-lg-0 d-flex justify-content-center align-items-center">
                        <div className="text-center">
                            <h3 className="text-uppercase text-secondary mb-4">À PROPOS DE VOTRE RÉSERVATION</h3>
                            <p className="text-secondary">Nous sommes impatients d’organiser votre séjour. Veuillez vous assurer que tout soit correctement rempli. Nous utiliserons ces informations pour éditer votre contrat de location.</p>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <div className="col-lg-4">
            <div className="card rounded-0 min-h-100">
                <div className="card-body d-flex align-items-center">
                <div className="col-lg-12">
                    <p className="text-secondary mb-1">Numéro de réservation</p>
                    <h5 className="pb-5">{reservation.confirmationCode}</h5>
                    <h3 className="text-uppercase text-dark">{reservation.listing.title}</h3>
                    <p className="text-dark mb-1">{reservation.listing.address?.full}</p>
                </div>
                </div>
            </div>
            </div>
        </div>
    </>
  )
}

export default Header