import clsx from 'clsx'
import {
   toAbsoluteUrl
  } from '../../../helpers'
import {
   HeaderUserMenu,
   ThemeModeSwitcher
  } from '../../../partials'
import { useAuth } from '../../../../app/modules/auth'

const itemClass = 'ms-1 ms-lg-3'

const userAvatarClass = 'symbol-35px symbol-md-40px'

const Navbar = () => {
  const { currentUser } = useAuth()

  return (
    <div className='app-navbar flex-shrink-0'>
      <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          {currentUser?.avatarUrl ? (
            <img src={currentUser?.avatarUrl} alt='Metronic' />
          ):(
            <div className='d-flex align-items-center justify-content-center symbol  h-40px w-40px text-white  bg-dark overflow-hidden me-5'>
              {currentUser?.firstName.charAt(0)}
            </div>
          )}
        </div>
        <HeaderUserMenu />
      </div>
    </div>
  )
}

export {Navbar}
