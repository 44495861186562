import {
  FC,
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from 'react'
import { useQuery } from 'react-query'
import { QUERIES, WithChildren } from '../../../../../../_metronic/helpers'
import { getArticles } from '../../../settings/core/_request'
import { useQueryRequest } from './QueryRequestProvider'
import { initialQueryState, PaginationState, stringifyRequestQuery } from '../../../restaurant/restaurant-create/Core/_models'

type RestaurantContextProps = {
  response?: any | undefined
  refetch: () => void
  isLoading: boolean
  query: string
  isError:boolean
  error:unknown
}
const initRestaurantContextPropsState = {
  refetch: () => {}, 
  isLoading: false,
  query: '',
  isError:false,
  error:""
}
const QueryResponseContext = createContext<RestaurantContextProps>(initRestaurantContextPropsState)

const QueryResponseProvider: FC<WithChildren> = ({children}) => {
  const {state} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery,query])

  const {
    isFetching,
    refetch,
    data: response,
    isError,
    error
  } = useQuery(
    `${QUERIES.RESTAURANT_LIST}-${query}`,
    () => {
  
      return getArticles()
    },
    {cacheTime: 0, keepPreviousData: false, refetchOnWindowFocus: false}
  )

  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching,isError,error, refetch, response, query}}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponseContext = () => {
  return useContext(QueryResponseContext)
}

const useQueryResponseData = () => {
  const {response} = useQueryResponseContext()
  if (!response) {
    return []
  }

  return response.content || []
}

const useQueryResponseError= () => {
  const {isError,error} = useQueryResponseContext()
  
  return {isError,error}
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    ...initialQueryState,
  }
  const {response} = useQueryResponseContext()

  if (!response || !response.totalPages || !response.totalElements || !response.pageable) {
    return defaultPaginationState
  }

  return {
    page: response.page,
    totalPages : response.totalPages , 
    totalElements: response.totalElements ,
    size:response.size, 
    pageable: response.pageable,
    first:response.first,
    last:response.last
  }
}

const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponseContext()
  return isLoading
}

export {
  QueryResponseProvider, 
  useQueryResponseContext,
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponsePagination,
  useQueryResponseError
}
