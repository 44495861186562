import React, { useState, useEffect } from 'react';
import { FieldArray, Field, FormikProps, FormikHelpers } from 'formik';
import { ArticleDetailsType, ArticleType } from '../../../../settings/core/_models';
import FormControl from '../../../../../../../_components/formControl/FormControl';
import Swal from 'sweetalert2';
import { deleteArticle, deleteArticleDetails } from '../../../../settings/core/_request';
import { ID } from '../../../../../../../_metronic/helpers';

const EditDetailsPage = ({  article,formikProps }: { article: ArticleType,formikProps: FormikProps<ArticleType> }) => {
  // Initialize the state variable with an empty array
  const [articleDetails, setArticleDetails] = useState<ArticleDetailsType[]>([]);

  // Use useEffect to update the state when 'article' changes
  useEffect(() => {
    if (article) {
      setArticleDetails(article.listDetails);
    }
  }, [article]);

  const handleDeleteProperty = (articleDetailsId: ID | undefined | null, name:string) => {
   
    if (articleDetailsId !== undefined) {
      Swal.fire({
        title: 'Confirmation',
        text: 'Êtes-vous sûr de vouloir supprimer cette article ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Supprimer',
        cancelButtonText: 'Annuler',
      }).then((result) => {
        if (result.isConfirmed) {
          // Make an API call to delete the property
          deleteArticleDetails(articleDetailsId)
            .then(() => {
              // Update the state to remove the deleted property
             
  
              // Handle success
              Swal.fire(
                'Supprimé!',
                `L'article dont le nom est ${name} a été supprimé.`,
                'success'
              );
            })
            .catch((error) => {
              // Handle API call error
              Swal.fire('Erreur!', 'Une erreur est survenue lors de la suppression.', 'error');
            });
        }
      });
    }
  };


  return (
    <>
      <div className="card card-custom card-flush mb-6">
        <div className="card-body">
          <div className='col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-md-5 '>
            <div className='row'>
              <div className="my-8">
                <div className='d-flex justify-content-between align-items-center'>
                  <h6 className="text-gray-800 fs-12 fw-bolder me-1 mb-12">Contenu de l'article </h6>
                </div>
                
                <FieldArray
        name='listDetails'
        render={(arrayHelpers) => (
          <div>
           
            {formikProps.values.listDetails
            //  .slice() // Create a shallow copy of the array
            //  .sort((a:any, b:any) => (a.order || 0) - (b.order || 0)) // Sort by id
            .map((detail, index) => (
              <div key={index}>
                {index!=0 ? <hr></hr>:null}
                 <code className='fs-5 mx-0 px-0 mb-6'> Paragraphe numero {index+1}</code>
                <div className='row mt-4'>
                    <div className="row">
                        <div className="col-md-3">
                        <FormControl
                      label={`Order`}
                      name={`listDetails[${index}].order`}
                      type='text'
                      disabled
                    />
                        </div>
                    </div>
                  <div className='col-md-6'>
                    <FormControl
                      label={`Titre`}
                      name={`listDetails[${index}].titre`}
                      type='text'
                    />
                  </div>
                  <div className='col-md-6'>
                 
                    <FormControl
                      label={`Title`}
                      name={`listDetails[${index}].title`}
                      type='text'
                    />
                  </div>
                 
                </div>
                <div className='row'>
                <div className='col-md-6'>
                    <FormControl
                      label={`Description (FR)`}
                      name={`listDetails.${index}.descriptionFR`}
                      type='textarea'
                    />
                  </div>
                  <div className='col-md-6'>
                    <FormControl
                      label={`Description (ENG)`}
                      name={`listDetails.${index}.descriptionENG`}
                      type='textarea'
                    />
                  </div>
                </div>
            
                <button
                  type='button'
                  className='btn btn-sm btn-danger mb-2 btn btn-primary'
                  onClick={() => {arrayHelpers.remove(index);handleDeleteProperty(article?.listDetails[index]?.id,article.name)}}
                  
                >
                  Supprimer
                </button>
              </div>
            ))}
               
            <button
              type='button'

              className='btn btn-sm btn-primary mb-2 btn btn-primary'
              style={
                {
                    float:'right'
                }
              }
              onClick={() => arrayHelpers.push({ title: '', descriptionFR: '', titre: '', descriptionENG: '' })}
            >
              Ajouter un nouveau paragraphe
            </button>
          </div>
        )}
      />
    
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditDetailsPage;
