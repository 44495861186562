import {ArticleListHeader} from './components/header/ArticleListHeader'
import {ArticleTable} from './table/ArticleTable'
import {KTCard, QUERIES} from '../../../../../_metronic/helpers'
import { QueryResponseProvider, useQueryResponseError, useQueryResponseLoading } from './core/QueryResponseProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { ListingViewProvider, useListingView } from './core/ListingViewProvider'
import { ArticleDeleteModal } from './article-delete/articleDeleteModal'
import Error505 from '../../../../../_components/errors/505'
import Loading from '../../../../../_components/Loading/Loading'
import { useQuery } from 'react-query'
import { addSEOArticle, getSEOByType, updateSEOByType } from '../../settings/core/_request'
import { Form, Formik } from 'formik'
import { Toast } from '../../../../../_components/SweetAlerts/SweetAlert'
import FormControl from '../../../../../_components/formControl/FormControl'
import { Button } from 'react-bootstrap'
import { seoSchema } from '../../settings/core/_validation'
import { initialSEO } from '../../settings/core/_initState'



const ArticleList = () => {
  const {itemIdForDelete} = useListingView()
  const {isError} = useQueryResponseError()
  const isLoading = useQueryResponseLoading()
  
const { data: seo, error } = useQuery(
  `${QUERIES.SEO_LIST}-press`,
  () => {
   
      return getSEOByType('press');
    
  },
  {
    cacheTime: 0,
    enabled: true,
    onError: (err) => {
      // Handle error
    },
  }
);

  return (
    <>
      {!isLoading ? (
      <>
        {(!isError) ? (
          <> 
            <KTCard>
              
              <ArticleListHeader />
              <ArticleTable />
            </KTCard>
            {itemIdForDelete !== undefined && <ArticleDeleteModal />}


             <div className="card mt-10">
              <div className="card-header px-4 py-8">
                  <h3>SEO</h3>
              </div>
              <div className="card-body">
              <div>
      <Formik
        initialValues={seo!=undefined ?seo:initialSEO}
        validationSchema={seoSchema}
        onSubmit={async (values) => {
          try {
            values.type='press';
           if(seo?.id)
            updateSEOByType(values).then((res)=>{
              if(res){
                Toast.fire({
                  icon: 'success',
                  title: 'updated'
                })
              }
            })
            else 
            addSEOArticle(values).then((res)=>{
              if(res){
                Toast.fire({
                  icon: 'success',
                  title: 'Created'
                })
              }
            })
            
          } catch (error) {
            Toast.fire({
              icon: 'error',
              title: 'Oops !!'
            })
          }
        }}
      >
        {({ isSubmitting }) => (

              <Form>
                <div className='d-flex justify-content-between align-items-center mt-10'>
                  <h5 className="text-gray-800 fs-2 fw-bolder me-1">SEO</h5>
                </div>
                
                  <div className="separator my-10"></div>
                <div className="row">
                  <div className="col-lg-6">
                    <FormControl
                      label='Titre'
                      name="titre"
                      type='text'
                      placeholder='Titre ...'
                    />
                  </div>
                  <div className="col-lg-6">
                    <FormControl
                      label='Title'
                      name="title"
                      type='text'
                      placeholder='Title ...'
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <FormControl
                      label='descriptionFR'
                      name="descriptionFR"
                      type='textarea'
                      placeholder='descriptionFR ...'
                    />
                    <FormControl
                      label='descriptionENG'
                      name="descriptionENG"
                      type='textarea'
                      placeholder='descriptionENG ...'
                    />
                  </div>
                </div>
                <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
                  <Button type='submit' className='btn btn-primary' disabled={isSubmitting} >
                    Sauvegardez {isSubmitting && (<span className="spinner-border spinner-border-sm align-middle ms-2"></span>)}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
                
              </div>
             </div>
          </>
        ):(
          <>
            <Error505 />
          </>
        )}
      </>
      ):(
      <>
        <Loading />
      </>
      )}
    </>
  )
}

const ArticleListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListingViewProvider >
        <ArticleList />
      </ListingViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {ArticleListWrapper}
