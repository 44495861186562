import React from 'react'
import DestinationFormDestination from '../components/SEOFormDestination/DestinationFormDestination'

const DestinationSeo: React.FC = () => {

  return (
    <>
      <DestinationFormDestination />
    </>
  )
}

export {DestinationSeo}
