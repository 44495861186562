import { useState } from "react"

import { Field, Form, Formik } from "formik"
import { User } from "../../../features/user-management/users-list/core/_models"
import FormControl from "../../../../../_components/formControl/FormControl"
import { Button } from "react-bootstrap"
import LogoDark from "../../../../../_metronic/assets/images/Logo/LogoDark.png"
import { resetPasswordSchema } from "../../core/_validation"
import { verifyAccount } from "../../core/_requests"
import { useNavigate } from "react-router-dom"
import { Toast } from "../../../../../_components/SweetAlerts/SweetAlert"

type VerifyAccountProps = {
  user:User
}
const VerifyAccount:React.FC<VerifyAccountProps> = ({user}) => {
  const [error,setError] = useState('')
  const navigate = useNavigate()
  return (
    <div className="card">
      <div className="card-body">
        <Formik
          initialValues={{
            email:user.email,
            password:'',
            confirmPassword:''
          }}
          validationSchema={resetPasswordSchema}
          onSubmit={async(values, {setSubmitting})=>{
            setSubmitting(true)
            try {
              const res = await verifyAccount(values.email,values.password)
              if(res.status === 200){
                Toast.fire({
                  icon: 'success',
                  title: 'password added'
                })
                navigate('/')
              }

            } catch (err) {
              setError('Oops!!')

            }finally{
              setSubmitting(false)
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="row">
                <div className='text-center mb-11'>
                  <img src={LogoDark} alt='logo' className='w-150px w-lg-180px my-4'/>
                </div>
              </div>
              {error && (
                <div className="row">
                  <div className='text-center mb-11'>
                    {error}
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-lg-12">
                  <div className="mb-4">
                    <label className="form-label required">Email</label>
                    <Field
                      label='Email'
                      name='email'
                      type='text'
                      placeholder='Email ...'
                      className='form-control'
                      disabled
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <FormControl
                    label='Mot de pass'
                    name='password'
                    type='password'
                    placeholder='Mot de pass ...'
                  />
                </div>
                <div className="col-lg-12">
                  <FormControl
                    label='Repetez Mot de pass'
                    name='confirmPassword'
                    type='password'
                    placeholder='Repetez Mot de pass ...'
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 d-flex flex-end">
                  <Button type='submit' className='btn btn-primary w-100' disabled={isSubmitting} >
                    Sauvegardez {isSubmitting && (<span className="spinner-border spinner-border-sm align-middle ms-2"></span>)}
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default VerifyAccount