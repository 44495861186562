import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useField } from "formik";
import { ImageSubsection } from "../../app/modules/features/settings/core/_models";
import { Button, Pagination } from "react-bootstrap";

import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { Toast } from '../SweetAlerts/SweetAlert';
import { QUERIES } from '../../_metronic/helpers';

import { Swiper, SwiperSlide } from 'swiper/react';
import { deleteImageSubsection } from "../../app/modules/features/settings/core/_request";

type HandleImageProps = {
  name:string
  preview?:boolean
  imageUrl?:string
  width?:string

  imageList?:ImageSubsection[]
}
const HandleImageList:React.FC<HandleImageProps> = ({name,imageUrl,preview,width,imageList}) => {
  const [field, , helpers] = useField(name);
  const { setValue } = helpers;
  const { value } = field;
  const [images,setImages]=useState<ImageSubsection[]>()

  const onDrop = useCallback(
    (acceptedFiles:File[]) => {

     acceptedFiles.forEach(( file, index) => {
        let img : ImageSubsection ={
          "imageByte":file,
          "order":""+index,
        }
        setImages((prevImages) => prevImages?.concat(img))
        setValue(value.concat(img));
    });
      
    },
    [setValue, value]
  );
  const queryClient = useQueryClient()
  
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true,
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/jpg': []
    }
  });
  const deleteItem = useMutation(
    (id: string) => deleteImageSubsection(id),
    {
      onSuccess: () => {
        Toast.fire({
          icon: 'success',
          title: 'Image deleted',
        });
  
        // Invalidate the relevant query after successful deletion
        queryClient.invalidateQueries([`${QUERIES.LISTING_VENTE_LIST}-listing`]);
      },
      onError: (error) => {
        Toast.fire({
          icon: 'error',
          title: 'Oops! Error when deleting image',
        });
      },
    }
  );
  
  // Call the mutate function when you want to trigger the mutation
  const handleDeleteClick = (id: string) => {
    console.log('delete image')
    deleteItem.mutate(id);
  };


  const deleteImagePreview = (preview : string)=>{
  
    let test=value?.filter((img:ImageSubsection)=> {return img.id ?  img.id!=preview : img.imageByte?.name!=preview })
  
    setImages(value?.filter((img:ImageSubsection)=> {return img.id ?  img.id!=preview : img.imageByte?.name!=preview }))
   
    let imagesValuesList = field.value
    imagesValuesList = imagesValuesList.filter((img:any) => {return img.id ?  img.id!=preview : img.imageByte?.name!=preview })
    setValue(imagesValuesList) 
    imageList?.forEach((image:any) => {  image.id == preview &&(handleDeleteClick( image.id) )}) 

  }

  


  return (
    <>
      <div className="dropzone">
        <div
            className="dz-message needsclick mt-2"
            {...getRootProps()}
        >
            <input {...getInputProps()} />
            {isDragActive ? (
              <h4 className="text-center">Déposez votre photo ici.</h4>
            ) : (
              <h4 className="text-center">Déposez votre photo ici.</h4>
            )}
        </div>
      </div>
      <div className="dropzone-previews mt-5" id="file-previews">
      { imageList && imageList?.length > 0 && (
        <Swiper
        pagination={{
          type: "fraction",
        }}
        // modules={[Pagination]}
        className="mySwiper"
        breakpoints={{
          // when window width is >= 640px
          200: {
            width: 200,
            slidesPerView: 1,
            spaceBetween: 10
          },
          // when window width is >= 640px
          640: {
            width: 640,
            slidesPerView: 3,
            spaceBetween: 30
          },
          // when window width is >= 768px
          768: {
            width: 768,
            slidesPerView: 4,
            spaceBetween: 50
          },
        }}
      >
        { value && value.map((item:ImageSubsection,idx:number)=>(
          <SwiperSlide key={`images-${idx}`}>
            <div  className='mb-10'>
            {item.imageUrl ?
              <img
                  src={item.imageUrl} 
                  alt={item.imageByte? item?.imageByte?.name : item?.alt} 
                  data-dz-thumbnail=""
                  width="150px"
                  height="150px"
                  className="rounded bg-light mb-2"
                  
              />
            :
            <img
                 src={item.imageByte &&(URL.createObjectURL(item?.imageByte))} 
                  alt={item.imageByte? item?.imageByte?.name : item?.alt} 
                  data-dz-thumbnail=""
                  width="150px"
                  height="150px"
                  className="rounded bg-light mb-2"
                  
              />
            }
              <Button
                onClick={() =>deleteImagePreview(item?.imageByte?.name || ""+item?.id)} 
                // style={{cursor : "pointer", position:"absolute",top:4,right:4}}  
                className="btn btn-sm btn-danger  mb-2"
                style={{width:"150px"}}
              >Supprimer</Button>
            </div>
          </SwiperSlide>
        ))       
        }
      </Swiper>
   
      )}
      </div>
    </>
  )
}

export default HandleImageList
