import { ArticleFormEditImagesWarpper } from './ArticleFormEditImagesWarpper';


const ArticleFormPageWarpper = () => {

  return (
    <>      
      <ArticleFormEditImagesWarpper />
    </>
  )
}

const ArticleEditImages = () => (
  <ArticleFormPageWarpper />
)

export {ArticleEditImages}
