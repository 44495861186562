import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useListView } from '../../../core/ListViewProvider'
import { KTSVG } from '../../../../../../../_metronic/helpers'


const DestinationListToolbar = () => {
  const intl = useIntl()
  const {setItemIdForUpdate} = useListView()
  
  const openDestinationModal = () => {
    setItemIdForUpdate(null)
  }
  
  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/* begin::Add bien */}
      <Link to='#' onClick={openDestinationModal} type='button' className='btn btn-primary' >
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Ajouter
      </Link>
      {/* end::Add bien */}
    </div>
  )
}

export {DestinationListToolbar}
