
import { Field, FieldInputProps, FormikHelpers, useField } from "formik"
import FormControl from "../../../../../../../../_components/formControl/FormControl"

import { useEffect, useState } from 'react';
import SelectUnique from 'react-select';
import OptionsType from 'react-select';
import CreatableSelect from 'react-select/creatable';
// import { getCategories } from "../../../../article-create/Core/_requests";
import { useQuery } from "react-query";
import { ArticleType } from "../../../../../settings/core/_models";


const ArticleDetails = ({ helpers,article }: { helpers: FormikHelpers<ArticleType>,article: ArticleType })  => {




  const [isLoading,setIsLoading] = useState<boolean>(false) 
 

  const handleSelectValue = (selectedOption:any,  name:string)=> {
   
    // Map selected options to an array of values
    const selectedValues = selectedOption.map((option: { value: string }) => option.value);
    
        helpers.setFieldValue(name, selectedValues); // Update the Formik field
};
  
  return (
    <>
      <div className="card card-custom card-flush mb-6">
        <div className="card-body">
          <div className='col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-md-5 '>
         
            <div className='row'>
            <div className="my-8">
                   <div className='d-flex justify-content-between align-items-center'>
                    <h6 className="text-gray-800 fs-12 fw-bolder me-1 mb-12">Image de couverture de L'article </h6>
                  </div>
                      <FormControl
                        name='imageByte'
                        type='image' 
                        preview={false}
                        imageUrl={article.imageUrl}
                      />
                    </div>
                 
            </div>
           
            </div>
        </div>
        
      </div>
    </>
  )
}

export default ArticleDetails