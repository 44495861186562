import { Form, Formik } from "formik"

import Header from "./header/header"
import Content from "./content/Content"
import { ReservationDetailsResponse } from "../../../modules/features/guesty/reservation-guesty/reservation-view/Core/_models"

type CheckInFormLayoutProps = {
  reservation:ReservationDetailsResponse
}
const CheckInFormView:React.FC<CheckInFormLayoutProps> = ({reservation}) => {
  return (
    <>
    {(reservation && reservation._id)  ? (
      <Formik 
        initialValues={{"file":"","confirm":false,"anniversaire":""}}
        onSubmit={async (values, { setSubmitting }) => {

        }}
      >
        {({ isSubmitting }) => (
          <Form>
          <div className="container">
            <Header
              reservation={reservation}
            />
            <Content
              reservation={reservation}
              isSubmitting={isSubmitting}
            />
          </div>
        </Form>
        )}
      </Formik>
    ):(
      <><h1>error</h1></>
    ) }
      
    </>
  )
}
export default CheckInFormView