import { useQuery } from 'react-query'
import { DestinationEditModalForm } from './DestinationEditModalForm'
import { useListView } from '../../../core/ListViewProvider'
import { QUERIES, isNotEmpty } from '../../../../../../../_metronic/helpers'
import { getDestinationById } from '../../../core/_request'
import Loading from '../../../../../../../_components/Loading/Loading'
import Error505 from '../../../../../../../_components/errors/505'

type DestinationEditModalFormWrapperProps = {
  type:string
  key:any
}

const DestinationEditModalFormWrapper:React.FC<DestinationEditModalFormWrapperProps> = ({type},{key}) => {
  const {itemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  
  const {
    isLoading,
    data: destination,
    error,
  } = useQuery(
    `${QUERIES.DESTINATION}-${itemIdForUpdate}`,
    () => {
      return getDestinationById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
      },
    }
  )

  if (isLoading) {
    return <Loading />
  }

  if (error) {
    return <Error505 />
  }

  if (itemIdForUpdate && destination) {
    return <DestinationEditModalForm  destination={destination} type={type} key={key}/>
  }

  if (!itemIdForUpdate) {
    return <DestinationEditModalForm destination={undefined} type={type}  key={key}/>
  }

  
  return null
}

export {DestinationEditModalFormWrapper}
