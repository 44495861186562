/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import { useAuth } from '../../../../../app/modules/auth'
import { isAccouantAdmin, isAdmin, isAdminListingVente, isAdminWebSite } from '../../../../helpers/_helpers'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  if(!currentUser){
    return <>not user found</>
  }




  return (
    <>
      {(isAdmin(currentUser.roles)) && (
        <SidebarMenuItem
          to='/dashboard'
          icon='/media/icons/duotune/art/art002.svg'
          title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
          fontIcon='bi-app-indicator'
        />
      )}
      {(isAdmin(currentUser.roles) || isAdminListingVente(currentUser.roles)) && (
        <SidebarMenuItemWithSub
          to='/apps/listing-vente'
          title={intl.formatMessage({id: 'MENU.Vente'})}
          icon='/media/icons/duotune/general/gen001.svg'
          fontIcon='bi-person'
        >
          <SidebarMenuItem to='/apps/listing-vente/list' title={intl.formatMessage({id: 'MENU.Biens en vente'})} hasBullet={true} />
          <SidebarMenuItem to='/apps/listing-vente/vendus/list' title={intl.formatMessage({id: 'MENU.Biens vendus'})} hasBullet={true} />
          <SidebarMenuItem to='/apps/listing-vente/demandes/vente' title={intl.formatMessage({id: 'MENU.FROM_SALE_REQUEST'})} hasBullet={true} />
          <SidebarMenuItem to='/apps/listing-vente/demandes/achat' title={intl.formatMessage({id: 'MENU.FROM_BUY_REQUEST'})} hasBullet={true} />
          
        </SidebarMenuItemWithSub>
      )}

      
       {isAdmin(currentUser.roles) && (
        <SidebarMenuItemWithSub
          to='/apps/listing-guesty'
          title={intl.formatMessage({id: 'MENU.Location'})}
          fontIcon='bi-chat-left'
          icon='/media/icons/duotune/ecommerce/ecm008.svg'
        >
          <SidebarMenuItem to='/apps/listing-guesty/listing/list' title={intl.formatMessage({id: 'MENU.BIEN_LOCATION'})} hasBullet={true} />
          <SidebarMenuItem to='/apps/listing-guesty/reservation/list' title={intl.formatMessage({id: 'MENU.RESERVATION'})}  hasBullet={true}/>
          <SidebarMenuItem to='/apps/listing-guesty/reservation/list' title={intl.formatMessage({id: 'MENU.FROM_RENT_REQUEST'})}  hasBullet={true}/>
          
          {/* <SidebarMenuItem to='/apps/listing-guesty/inbox' title='Inbox'  hasBullet={true}/> */}
        </SidebarMenuItemWithSub>
      )}
        {isAdmin(currentUser.roles) && (
        <SidebarMenuItemWithSub
          to='apps/settings/conciergerie'
          title={intl.formatMessage({id: 'MENU.CONCIERGERIES'})}
          fontIcon='bi-chat-left'
          icon='/media/icons/duotune/files/fil012.svg'
        >
          <SidebarMenuItem to='apps/settings/conciergerie/list' title={intl.formatMessage({id: 'MENU.CONCIERGERIES_REGION'})} hasBullet={true} />
          <SidebarMenuItem to='/apps/settings/conciergerie/services/list' title={intl.formatMessage({id: 'MENU.CONCIERGERIES_SERVICE'})}  hasBullet={true}/>
          <SidebarMenuItem to='/apps/settings/conciergerie/demandes' title={intl.formatMessage({id: 'MENU.FROM_CONCIERGE_REQUEST'})}  hasBullet={true}/>
          
          {/* <SidebarMenuItem to='/apps/listing-guesty/inbox' title='Inbox'  hasBullet={true}/> */}
        </SidebarMenuItemWithSub>
      )}
     {(isAdmin(currentUser.roles)) && (
        <SidebarMenuItem
          to='/apps/settings/demandes/autre'
          icon='/media/icons/duotune/files/fil003.svg'
          title={intl.formatMessage({id: 'MENU.INFO_REQUEST'})}
          fontIcon='bi-app-indicator'
        />
      )}
         {isAdmin(currentUser.roles) && (
        <SidebarMenuItemWithSub
          to='apps/explore'
          title={intl.formatMessage({id: 'MENU.EXPLORE'})}
          fontIcon='bi-chat-left'
          icon='/media/icons/duotune/maps/map002.svg'
        >
          <SidebarMenuItem to='apps/explore/region/list' title={intl.formatMessage({id: 'MENU.EXPLORE_REGION'})} hasBullet={true} />
          <SidebarMenuItem to='apps/explore/destination/list' title={intl.formatMessage({id: 'MENU.EXPLORE_DESTINATION'})}  hasBullet={true}/>
          <SidebarMenuItem to='/apps/explore/sub-destination/list' title={intl.formatMessage({id: 'MENU.EXPLORE_CITY'})}  hasBullet={true}/>
          
        </SidebarMenuItemWithSub>
      )}

{isAdmin(currentUser.roles) && (
        <SidebarMenuItemWithSub
          to='apps/discover'
          title={intl.formatMessage({id: 'MENU.DESCOVER'})}
          fontIcon='bi-chat-left'
          icon='/media/icons/duotune/general/gen021.svg'
        >
          <SidebarMenuItem to='apps/discover/activities/list' title={intl.formatMessage({id: 'MENU.ACTIVITY'})} hasBullet={true} />
          <SidebarMenuItem to='apps/discover/restaurants/list' title={intl.formatMessage({id: 'MENU.RESTAURANT'})}  hasBullet={true}/>
          <SidebarMenuItem to='apps/discover/festivities/list' title={intl.formatMessage({id: 'MENU.FESTIVITY'})}  hasBullet={true}/>
          
        </SidebarMenuItemWithSub>
      )}
     
          {isAdmin(currentUser.roles) && (    
              <SidebarMenuItem
                  to='/apps/articles/list'
                  icon='/media/icons/duotune/art/art005.svg'
                  title={intl.formatMessage({id: 'MENU.PRESS'})}
                  fontIcon='bi-app-indicator'
                  // hasBullet={true}
                />
        )}
         {isAdmin(currentUser.roles) && (
             
             <SidebarMenuItem to='/apps/articles/blog/list' title={intl.formatMessage({id: 'MENU.BLOG'})}  
             icon='/media/icons/duotune/art/art005.svg'
             fontIcon='bi-app-indicator'
             />
             
            
           
         )}

      {isAdmin(currentUser.roles) && (
            <SidebarMenuItemWithSub
                to='/apps/users'
                title={'Gestion des utilisateurs'}
                // title={intl.formatMessage({id: 'MENU.vendre'})}
                icon='/media/icons/duotune/ecommerce/user.svg'
                fontIcon='bi-person'
              >
                {isAdmin(currentUser.roles) && (
                    <SidebarMenuItem
                      to='/apps/user-management/users'
                      // icon='/media/icons/communication/com001'
                      icon='/media/icons/duotune/ecommerce/setting.svg'
                      title={intl.formatMessage({id: 'MENU.USER_MANAGEMENT'})}
                      fontIcon='bi-user'
                    />
                )}
                {isAccouantAdmin(currentUser.roles)  && (
                      <SidebarMenuItem
                        to='/apps/guests-management/guests'
                        icon='/media/icons/duotune/ecommerce/clients.svg'
                        title={intl.formatMessage({id: 'MENU.GUESTS_MANAGEMENT'})}
                        fontIcon='bi-layers'
                      />
                    )}
                  {isAccouantAdmin(currentUser.roles) && (
                    <SidebarMenuItem
                      to='/apps/owners-management/owners'
                      icon='/media/icons/duotune/ecommerce/owner.svg'
                      title={intl.formatMessage({id: 'MENU.OWNERS_MANAGEMENT'})}
                      fontIcon='bi-layers'
                    >

                    </SidebarMenuItem>
                  )}

              </SidebarMenuItemWithSub>
        
      )}
 {isAdmin(currentUser.roles) && (
      <SidebarMenuItemWithSub
          to='/apps/settings/pages'
          title={intl.formatMessage({id: 'MENU.SEO'})}
          // title={intl.formatMessage({id: 'MENU.vendre'})}
          icon='/media/icons/duotune/ecommerce/seo.svg'
          fontIcon='bi-person'
        >
           {isAdmin(currentUser.roles) && (
              <SidebarMenuItem
                to='/apps/settings/seo/destination'
                // icon='/media/icons/communication/com001'
                // icon='/media/icons/duotune/ecommerce/setting.svg'
                title={intl.formatMessage({id: 'MENU.SEO_DESTINATION_PAGE'})}
                hasBullet={true}
              />
          )}
           {isAccouantAdmin(currentUser.roles)  && (
                <SidebarMenuItem
                  to='/apps/settings/seo/rent'
                  title={intl.formatMessage({id: 'MENU.SEO_RENT_PAGE'})} 
                  hasBullet={true}
                />
              )}
            {isAccouantAdmin(currentUser.roles) && (
              <SidebarMenuItem
                to='/apps/settings/seo/sale'
                title={intl.formatMessage({id: 'MENU.SEO_SALE_PAGE'})}
                hasBullet={true}
              >

              </SidebarMenuItem>
            )}

        </SidebarMenuItemWithSub>
   
 )}


      {/* {isAccouantAdmin(currentUser.roles) && (
        <SidebarMenuItem
          to='/apps/inbox-management/inbox'
          icon='/media/icons/duotune/general/gen051.svg'
          title={intl.formatMessage({id: 'MENU.INBOX_MANAGEMENT'})}
          fontIcon='bi-layers'
        />
      )} */}
     
        {(isAdmin(currentUser.roles) || isAdminWebSite(currentUser.roles)) && (
        <SidebarMenuItemWithSub
          to='/apps/settings/generale'
          title={'Administration'}
          // title={intl.formatMessage({id: 'MENU.vendre'})}
          icon='/media/icons/duotune/general/gen025.svg'
          fontIcon='bi-person'
        >
          {/* <SidebarMenuItem to='apps/settings/generale' title={intl.formatMessage({id: 'MENU.generale'})} hasBullet={true} />
          <SidebarMenuItem to='apps/settings/home' title={intl.formatMessage({id: 'MENU.home'})} hasBullet={true} />
          <SidebarMenuItem to='apps/settings/pages' title={intl.formatMessage({id: 'MENU.pages'})} hasBullet={true} />
          <SidebarMenuItem to='apps/settings/location' title={intl.formatMessage({id: 'MENU.location'})} hasBullet={true} />
          <SidebarMenuItem to='apps/settings/vente' title={intl.formatMessage({id: 'MENU.vente'})} hasBullet={true} /> */}
                   
          <SidebarMenuItem to='apps/settings/home' title={'Accueil'}hasBullet={true} />
          <SidebarMenuItem to='apps/settings/generale' title={'Général'} hasBullet={true} />
          <SidebarMenuItem to='apps/settings/faq' title={'FAQs'} hasBullet={true} />
          <SidebarMenuItem to='apps/settings/Review' title={'Avis'} hasBullet={true} />
          {/* <SidebarMenuItem to='apps/settings/conciergerie/list' title={'Conciergerie'} hasBullet={true} /> */}
          {/* <SidebarMenuItem to='apps/activities/list' title='Explorer' hasBullet={true} /> */}
          {/* <SidebarMenuItem to='apps/articles/list' title={'Presse'} hasBullet={true} /> */}
          {/* <SidebarMenuItem to='apps/settings/Articles' title={'Articles'} hasBullet={true} /> */}
          {/* <SidebarMenuItemWithSub
              to='/apps/articles'
              title='Presse'
              //  fontIcon='bi-chat-left'
                hasBullet={true}
              //  icon='/media/icons/duotune/art/art002.svg'
           >
                <SidebarMenuItem to='/apps/articles/list' title='Articles' hasBullet={true} />
                <SidebarMenuItem to='/apps/articles/create' title='Ajouter un article'  hasBullet={true}/>
           </SidebarMenuItemWithSub> */}

          <SidebarMenuItem to='apps/settings/pages' title={'Pages'}hasBullet={true} />
          {/* <SidebarMenuItem to='apps/settings/sections' title={'Sections'} hasBullet={true} /> */}
          {/* <SidebarMenuItem to='apps/settings/destinations' title={'Destinations'} hasBullet={true} /> */}
          {/* <SidebarMenuItem to='apps/settings/location' title={'Location'} hasBullet={true} /> */}
          {/* <SidebarMenuItem to='apps/settings/vente' title={'Vente'} hasBullet={true} /> */}
         

        </SidebarMenuItemWithSub>



            // <div className='app-sidebar-footer flex-column-auto pt-2 pb-6 px-6' id='kt_app_sidebar_footer'>
  //     {(isAdmin(currentUser.roles) || isAdminWebSite(currentUser.roles)) && (
  //     <Link
  //       to={'/apps/settings/generale'}
  //       className='btn btn-flex flex-center btn-custom btn-primary  text-nowrap px-0 h-40px w-100'
  //     >
  //       <span className='btn-label'>{intl.formatMessage({id: 'MENU.SETTINGS'})}</span>
  //       <KTSVG path='/media/icons/duotune/general/gen005.svg' className='btn-icon svg-icon-2 m-0' />
  //     </Link>
  //     )}
  //   </div>
      )}
    </>
  )
}

export {SidebarMenuMain}
