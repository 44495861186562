import { FastField, Field, useField,useFormikContext ,FormikHelpers} from "formik"
import FormControl from "../../../../../../../_components/formControl/FormControl"
import Select from 'react-select/creatable';
import SelectUnique from 'react-select';
import { useEffect, useState } from 'react';
// import { getCategories } from '../../../../core/_request'
import { useQuery } from "react-query"
import { ArticleType } from "../../../../settings/core/_models";

const ArticleDetails =({ helpers }: { helpers: FormikHelpers<ArticleType> }) => {

  const [selectedRadioValue, setSelectedRadioValue] = useState("show");
  const handleRadioChange = (value:string) => {

    setSelectedRadioValue(value);
    
  };

  // const {
  //   isLoading,
  //   data: data,
  //   error,
  // } = useQuery(
  //   `Get all section categories`,
  //   () => {
  //     return getCategories()
  //   },
  //   {
  //     cacheTime: 0,
  //     onError: (err) => {
  //     },
  //   }
  // )
  
  var options :{value:any; label:any}[]=[];
  
 

  // if (data!=undefined) {
  //   var categories= data.filter(category => category!='');
  //    options = categories.map((value) => ({
  //     value: value,
  //     label: value,
  //   }));
    
  //  }
  //  var cat=
  
   

  // var options:any=[] ;
  // const categories = ['test1', 'test2', 'test3'];
  // let categoryList: any[] = []; // Initialize cat as an empty array
// if(data)
// data.forEach((category, index) => {
//     // console.log(category)
//     categoryList =  category!='' ?  [...categoryList, { value: category, label: index }] : [...categoryList] ;
//   });
// }


  // Function to handle radio button change
 

 

  

  return (
 
      
     
      <div className="card card-custom card-flush mb-6">
        <div className="card-body">
          <div className='col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-md-5 '>
           
            <div className='row'>
         
               
                <div className="card card-custom card-flush mb-6">
                    <div className="card-body" style={{     minHeight: "360px" }}>
                        <label className="form-label required mb-4">Image de couverture :</label>                    
                        <FormControl
                            type="image"
                            name="imageByte"
                        />
                    </div>
                </div>







            
                
            </div>
          </div>
        </div>
      </div>
   
  )
}

export default ArticleDetails