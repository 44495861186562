/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useLayoutEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import am4geodata_worldLow from '@amcharts/amcharts4-geodata/worldLow'
import * as am5percent from '@amcharts/amcharts5/percent'
import * as am5plugins_sliceGrouper from "@amcharts/amcharts5/plugins/sliceGrouper";

const DashboardPage: FC = () => (
  <>
    <div className='row'>
      <div className='col-md-7'>
        <div className='card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-md-10 mb-5 mb-xl-10'>
          <NewComponent2 />
        </div>
      </div>
      <div className='col-md-5'>
        <div className='card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-md-10 mb-5 mb-xl-10'>
          <NewComponent3 />
        </div>
      </div>
    </div>
    <div className='row'>
      <div className='col-md-5'>
        <div className='card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-md-10 mb-5 mb-xl-10'>
          <NewComponent />
        </div>
      </div>
      <div className='col-md-7'>
        <div className='card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-md-10 mb-5 mb-xl-10'>
          <NewComponent1 />
        </div>
      </div>
    </div>
  </>
)

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      {/* <DashboardPage /> */}
    </>
  )
}

const NewComponent: FC = () => {
  useLayoutEffect(() => {
    let root = am5.Root.new('chartdiv')

    root.setThemes([am5themes_Animated.new(root)])

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panY: false,
        layout: root.verticalLayout,
      })
    )

    // Define data
    let data = [
      {
        category: 'Research',
        value1: 1000,
        value2: 588,
      },
      {
        category: 'Marketing',
        value1: 1200,
        value2: 1800,
      },
      {
        category: 'Sales',
        value1: 850,
        value2: 1230,
      },
    ]

    // Create Y-axis
    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
      })
    )

    // Create X-Axis
    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        renderer: am5xy.AxisRendererX.new(root, {}),
        categoryField: 'category',
      })
    )
    xAxis.data.setAll(data)

    // Create series
    let series1 = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: 'Series',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'value1',
        categoryXField: 'category',
      })
    )
    series1.data.setAll(data)

    let series2 = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: 'Series',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'value2',
        categoryXField: 'category',
      })
    )
    series2.data.setAll(data)

    // Add legend
    let legend = chart.children.push(am5.Legend.new(root, {}))
    legend.data.setAll(chart.series.values)

    // Add cursor
    chart.set('cursor', am5xy.XYCursor.new(root, {}))
    root._logo?.dispose()

    return () => {
      root.dispose()
    }
  }, [])

  return <div id='chartdiv' style={{width: '100%', height: '500px'}}></div>
}

const NewComponent1: FC = () => {
  useLayoutEffect(() => {
    let root = am5.Root.new('chartdiv1')

    root.setThemes([am5themes_Animated.new(root)])

    let chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.verticalLayout,
      })
    )

    // Define data

    // Create Y-axis

    // Define data
    let data = [
      {
        country: 'France',
        sales: 100000,
      },
      {
        country: 'Spain',
        sales: 160000,
      },
      {
        country: 'United Kingdom',
        sales: 80000,
      },
    ]

    // Create series
    let series = chart.series.push(
      am5percent.PieSeries.new(root, {
        name: 'Series',
        valueField: 'sales',
        categoryField: 'country',
      })
    )
    series.data.setAll(data)
    root._logo?.dispose()

    return () => {
      root.dispose()
    }
  }, [])

  return <div id='chartdiv1' style={{width: '100%', height: '500px'}}></div>
}

const NewComponent2: FC = () => {
  useLayoutEffect(() => {
    let root = am5.Root.new('chartdiv2')

    root.setThemes([am5themes_Animated.new(root)])

    let chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.horizontalLayout,
      })
    )

    // Define data
    var data = [
      {
        country: 'France',
        sales: 100000,
      },
      {
        country: 'Spain',
        sales: 160000,
      },
      {
        country: 'United Kingdom',
        sales: 80000,
      },
      {
        country: 'Netherlands',
        sales: 90000,
      },
      {
        country: 'Portugal',
        sales: 25000,
      },
      {
        country: 'Germany',
        sales: 70000,
      },
      {
        country: 'Austria',
        sales: 75000,
      },
      {
        country: 'Belgium',
        sales: 40000,
      },
      {
        country: 'Poland',
        sales: 60000,
      },
    ]

    // Create series
    let series = chart.series.push(
      am5percent.PieSeries.new(root, {
        name: 'Series',
        valueField: 'sales',
        categoryField: 'country',
        legendLabelText: '[{fill}]{category}[/]',
        legendValueText: '[bold {fill}]{value}[/]',
      })
    )
    series.data.setAll(data)
    series.labels.template.set('forceHidden', true)
    series.ticks.template.set('forceHidden', true)

    // Add legend
    var legend = chart.children.push(
      am5.Legend.new(root, {
        centerY: am5.percent(50),
        y: am5.percent(50),
        layout: root.verticalLayout,
      })
    )

    legend.data.setAll(series.dataItems)

    root._logo?.dispose()

    return () => {
      root.dispose()
    }
  }, [])

  return <div id='chartdiv2' style={{width: '100%', height: '500px'}}></div>
}


const NewComponent3: FC = () => {
  useLayoutEffect(() => {

    var root = am5.Root.new("chartdiv3");
    
    root.setThemes([
      am5themes_Animated.new(root)
    ]);
    
    var chart = root.container.children.push( 
      am5percent.PieChart.new(root, {
        layout: root.verticalLayout
      }) 
    );
    
    // Define data
    var data = [{
      country: "United States",
      sales: 350
    }, {
      country: "Canada",
      sales: 301
    }, {
      country: "Mexico",
      sales: 201
    }, {
      country: "Germany",
      sales: 165
    }, {
      country: "Australia",
      sales: 139
    }, {
      country: "Austria",
      sales: 128
    }, {
      country: "United Kingdom",
      sales: 99
    }, {
      country: "Belgium",
      sales: 60
    }, {
      country: "The Netherlands",
      sales: 50
    }, {
      country: "France",
      sales: 30
    }, {
      country: "Spain",
      sales: 20
    }];
    
    // Create series
    var series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: "sales",
        categoryField: "country"
      })
    );
    series.data.setAll(data);
    
    // Add legend
    var legend = chart.children.push(am5.Legend.new(root, {
      centerX: am5.percent(50),
      x: am5.percent(50),
      layout: root.gridLayout
    }));
    
    legend.data.setAll(series.dataItems);
    
    var grouper = am5plugins_sliceGrouper.SliceGrouper.new(root, {
      series: series,
      legend: legend,
      clickBehavior: "break"
    });

    root._logo?.dispose()

    return () => {
      root.dispose()
    }
  }, [])

  return <div id='chartdiv3' style={{width: '100%', height: '500px'}}></div>
}
export {DashboardWrapper}
