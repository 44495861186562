import {useState} from 'react'
import * as Yup from 'yup'
import {Form, Formik} from 'formik'
import LogoDark from '../../../../_metronic/assets/images/Logo/LogoDark.png'
import FormControl from '../../../../_components/formControl/FormControl'
import { Button } from 'react-bootstrap'
import { checkEmailIfExist } from '../core/_requests'

const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

export function ForgotPassword() {
  const [isSuccess,setIsSucces] = useState<boolean>(false)
  const [error,setError] = useState('')
  
  return (
    <div className="card">
      <div className="card-body">
        <Formik
          initialValues={initialValues}
          validationSchema={forgotPasswordSchema}
          onSubmit={async (values, {setSubmitting})=>{
            setSubmitting(true)
            try {
              const res = await checkEmailIfExist(values.email)
              if(res.status === 200){
                setIsSucces(true)
              }
            } catch (err) {
              setError('Email not exist')
            }finally{
              setSubmitting(false)
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="row">
                <div className='text-center mb-11'>
                  <img src={LogoDark} alt='logo' className='w-150px w-lg-180px my-4'/>
                </div>
              </div>

              {isSuccess && (
                <div className="row">
                  <div className='text-center mb-11'>
                    <p>We are send mail with link to reset your password,  please check your email</p>
                  </div>
                </div>
              )}
              {error && (
                <div className="row">
                  <div className='text-center mb-11'>
                    <p className='text-danger'>Email not exist</p>
                  </div>
                </div>
              )}

              <div className="row">
                <div className="col-lg-12">
                  <FormControl
                    label='Email'
                    name='email'
                    type='text'
                    placeholder='Email ...'
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 d-flex flex-end">
                  <Button type='submit' className='btn btn-primary w-100' disabled={isSubmitting} >
                    Sauvegardez {isSubmitting && (<span className="spinner-border spinner-border-sm align-middle ms-2"></span>)}
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}
