import { Link } from 'react-router-dom'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import { useIntl } from 'react-intl'

const ArticleListToolbar = () => {
  const intl = useIntl()

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/* begin::Add bien */}
      <Link to='/apps/articles/create' type='button' className='btn btn-primary' >
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        {intl.formatMessage({id: 'Article.ADD'})}
      </Link>
      {/* end::Add bien */}
    </div>
  )
}

export {ArticleListToolbar}
