const QUERIES = {
  USERS_LIST: 'users-list',
  TOKEN: 'token',
  LISTING_VENTE_LIST: 'listing-vente-list',
  RESERVATION_GUESTY_LIST: 'reservation-guesty-list',
  RESERVATION_OWNERS_LIST: 'reservation-guesty-owners-list',
  RESERVATION_GUEST_LIST: 'reservation-guesty-guest-list',
  CONTRAT_DETAILS: 'contrat-details',
  SEO_LIST: 'seo',
  SECTION_LIST: 'section',
  FAQ_LIST: 'FAQ_LIST',
  FAQ_NAME: 'FAQ_NAME',
  FAQ: 'FAQ',
  SUB_SECTION_LIST: 'sub-sections-list',
  SUB_SECTION: 'sub-section',
  ROLES: 'roles',
  CONTACT: 'CONTACT',
  SOCIAL: 'SOCIAL',
  LOGO: 'LOGO',
  DESTINATION:'DESTINATION',
  DESTINATION_NAME:'DESTINATION_NAME',
  GUESTS:'GUESTS',
  OWNERS:'OWNERS',
  ARTICLE:'ARTICLE',
  REVIEW:'REVIEW',

  RESTAURANT_LIST: 'restaurant-list',
ARTICLE_LIST: 'article-list',
ARTICLE_SEO_LIST: 'article-SEO',
SELECTED_PROP_LIST: 'selected_properties',
Explore_LIST:'explore-list',
Activity_LIST:'activity-list'
}

export {QUERIES}
