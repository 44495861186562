import {Field, Form,Formik} from 'formik'
import { Button } from 'react-bootstrap'
import { useQueryClient } from 'react-query';
import FormControl from '../../../../../../../../_components/formControl/FormControl';

import { Toast } from '../../../../../../../../_components/SweetAlerts/SweetAlert';
import { QUERIES } from '../../../../../../../../_metronic/helpers';
import { LoadingHelper } from '../../../../../../../../_metronic/helpers/LoadingHelper';
// import { RestaurantImageInitState } from '../../../../../../restaurant/restaurant-create/Core/_models';
// import { ArticleResponseTypeProps } from '../../../../article-create/Core/_models';

import { addImageArticle } from '../../../../../settings/core/_request';
import { ArticleType } from '../../../../../settings/core/_models';
import { RestaurantImageInitState } from '../../../../../restaurant/restaurant-create/Core/_initState';

type ArticleFormEditImagesProps = {
  isListingLoading:boolean
  listing:ArticleType
}

const FormEditImages:React.FC<ArticleFormEditImagesProps> = ({isListingLoading,listing}) => {
  const queryClient = useQueryClient()

  return (
    <>      
      <Formik
        initialValues={RestaurantImageInitState}
        onSubmit={async (values, { setSubmitting,resetForm  }) => {
          const formData = new FormData();
          
          values.imagesList.forEach((image: any) => {
            formData.append('images', image);
          });

          try {
            const res = await addImageArticle(listing.id,formData)
            if(res){
              Toast.fire({
                icon: 'success',
                title: 'image uploaded'
              })
              resetForm()
              queryClient.invalidateQueries([`${QUERIES.RESTAURANT_LIST}-listing`])
              
            }else{
              Toast.fire({
                icon: 'error',
                title: 'Oops! error when uploaded image'
              })
            }

          } catch (error) {
          }finally{
            setSubmitting(false)
          }

        }}
      >
        {({ values,isSubmitting }) => (
        <Form >
          <FormControl
            name='imagesList'
            type='images'
          />
          {/* save */}
          {values.imagesList && values.imagesList.length > 0 && (
            <div className='d-flex justify-content-end mt-5' data-kt-user-table-toolbar='base'>
              <Button type='submit' className='btn btn-primary' disabled={isSubmitting} >
                Sauvegardez {isSubmitting && (<span className="spinner-border spinner-border-sm align-middle ms-2"></span>)}
              </Button>
          </div>
          )}  
          {isSubmitting && (<LoadingHelper />)}
        </Form>
        )}
      </Formik>
    </>
  )
}
export default FormEditImages
