import React, { useState, useEffect } from 'react';
import { ArticleDetailsType, ArticleType } from '../../../../settings/core/_models';
import FormEditImages from './ListingImages/FormEditImages';
import Images from './ListingImages/Images';
import { useLayout } from '../../../../../../../_metronic/layout/core';
import { Button } from 'react-bootstrap';
import clsx from 'clsx';
import { LoadingHelper } from '../../../../../../../_metronic/helpers/LoadingHelper';

import FormControl from '../../../../../../../_components/formControl/FormControl';
import { Formik, Form, Field, ErrorMessage, FormikProps } from 'formik'; // Import Formik components

// Import EditDetailsPage component (if it's in a separate file)
import EditDetailsPage from './EditDetailsPage';
import { Toast } from '../../../../../../../_components/SweetAlerts/SweetAlert';
import { updateArticle, updateArticleDetails } from '../../../../settings/core/_request';

type ArticleFormEditImagesProps = {
  isListingLoading: boolean;
  listing: ArticleType;
};

const ArticleFormEditImages: React.FC<ArticleFormEditImagesProps> = ({
  isListingLoading,
  listing,
}) => {
  const { config } = useLayout();
  if (!config.app?.footer?.display) {
    return null;
  }

  // Initialize 'article' state with 'listDetails' from 'listing'

  


  // const handleSaveDetails = (updatedDetails: ArticleDetailsType[]) => {
  //   // Update the 'listDetails' property of 'article'
  //   setArticle((prevArticle) => ({
  //     ...prevArticle,
  //     listDetails: updatedDetails,
  //   }));
  // };

  return (
    <>
      <div className='row'>
      {listing.type=='PAPIER' ?<div className='col-lg-12'>
          <div className='card card-custom card-flush mb-6' style={{ marginBottom:"0px" }}>
            <div className='card-body'>
              <h5 className='mb-6'>images</h5>

              <FormEditImages isListingLoading={isListingLoading} listing={listing} />
              <Images isListingLoading={isListingLoading} listing={listing} />
            </div>
          </div>
        </div>:
     null
        }


        <div className='col-md-12'>
        <Formik
            initialValues={listing} // Set your initial values here
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true)
              try {
                var itemWithHighestOrder = listing.listDetails.reduce(
                  (prevItem:any, currentItem:any) => {
                    if (!prevItem || currentItem.order > prevItem.order) {
                      return currentItem;
                    } else {
                      return prevItem;
                    }
                  },
                  null
                );
                itemWithHighestOrder= itemWithHighestOrder.order;
                values?.listDetails?.forEach((element, index) => {
                    if(element.id==undefined){
                      itemWithHighestOrder++;
                      element.order=itemWithHighestOrder;
                    }
                });
                var result=true;
                values.listDetails.forEach((element, index)=>{
                  updateArticleDetails(element,listing.id).then((res)=>{
                      
                  }).catch((err:any)=>{
                    result=false
                    console.log('error',err);
                  })
                })
               {result? 
              Toast.fire({
                icon: 'success',
                title: 'edited'
              }):Toast.fire({
                icon: 'error',
                title: 'error when updates'
              })}
              
               
    
              } catch (ex) {
                  console.error(ex)
                 
              } finally {
                setSubmitting(true)
                setSubmitting(false)
              }
    
            }}
          >
            {(formikProps: FormikProps<ArticleType>) => (
              <Form>
                   <div className='col-lg-12'>
        <div className='card card-custom card-flush mb-6' style={{ marginBottom:"0px" }}>
          <div className='card-body'>
            <h5 className='mb-6'>Video</h5>
           
                       <FormControl
                          name='imagesList[0]'
                          type='video'
                          preview
                          imageUrl='imagesList[0].imageUrl'
                        />
                     
            
          </div>
        </div>
      </div>
                <EditDetailsPage article={listing} formikProps={formikProps} />
                <div className='app-footer justify-content-end  fixed-bottom' id='kt_app_footer'>
                    {config?.app?.footer?.containerClass ? (
                      <div className={clsx('app-container', config.app.footer.containerClass)}>
                          <Button type='submit' className='btn btn-primary w-100' disabled={formikProps.isSubmitting} >
                            Sauvegardez {formikProps.isSubmitting && (<span className="spinner-border spinner-border-sm align-middle ms-2"></span>)}
                          </Button>
                      </div>
                    ) : (
                      <Button type='submit' className='btn btn-primary w-100' disabled={formikProps.isSubmitting} >
                        Sauvegardez {formikProps.isSubmitting && (<span className="spinner-border spinner-border-sm align-middle ms-2"></span>)}
                      </Button>
                    )}
                </div>
               
              </Form>
            )}
          </Formik>



        </div>
      </div>
    </>
  );
};

export default ArticleFormEditImages;
