import { KTCard, KTCardBody } from "../../_metronic/helpers"
import { Error500 } from "../../app/modules/errors/components/Error500"

const Error505 = () => {
  return (
    <KTCard>
      <KTCardBody>
        <div className="d-flex flex-column flex-center my-16">
          <Error500 />
        </div>
      </KTCardBody>
    </KTCard>
  )
}

export default Error505