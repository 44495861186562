import {Field, Form,Formik} from 'formik'

import { Button } from 'react-bootstrap'

import { useEffect, useState } from 'react';

import { useQuery, useQueryClient } from 'react-query';
import { DestinationType } from '../../../core/_models';
import { useListView } from '../../../core/ListViewProvider';
import { QUERIES, isNotEmpty } from '../../../../../../../_metronic/helpers';
import { initialDestination } from '../../../core/_initState';
import { Toast } from '../../../../../../../_components/SweetAlerts/SweetAlert';
import FormControl from '../../../../../../../_components/formControl/FormControl';
import { addDestination, getDestinationByType, updateDestination } from '../../../core/_request';
import DestinationFormList from './DestinationFormList';
import { DestinationNameWrapper } from './DestinationNameWrapper';
import { getdestinationList } from '../../../../listing-vente/core/_requests';
import  Select from 'react-select';
import { Divider } from '@mui/material';
import { AxiosError } from 'axios';

type UserEditModalFormProps = {
  destination?:DestinationType
  type:string
  key:any
}

const DestinationEditModalForm:React.FC<UserEditModalFormProps> = ({destination,type,key}) => {
  const {setItemIdForUpdate} = useListView()
  const queryClient = useQueryClient()

  const [region, setRegion] = useState<string>(destination?.region||""); // Provide type annotation here
  const [SelectedDestination, setSelectedDestination] = useState<string>(destination?.destination||""); // Provide type annotation here
  
  const [isError] = useState(false)

  const cancel = () => {
    queryClient.invalidateQueries([`${QUERIES.DESTINATION}-${type}`])
    setItemIdForUpdate(undefined)
  }
  const isForAdd = destination !=null ? true : false;

  

  const {
    isLoading,
    data: destinationList,
    error,
  } = useQuery(
    `${QUERIES.DESTINATION}-LIST-RENT`,
    () => {
      return getdestinationList()
    },
    {
      cacheTime: 0,
      enabled: true,
      onError: (err) => {
      },
    }
  )

  
  let regionOptions: { value: string; label: string; }[] = [];
  let alpesOptions: { value: string; label: string; }[] = [];
  let coteAzurOptions: { value: string; label: string; }[] = [];
  // let destinationOptions: { value: string; label: string; }[] = [];
  const [destinationOptions, setDestinationOptions] = useState<{ value: string; label: string; }[]>([]);

    regionOptions = [{
      value: "Alpes",
      label: "Alpes",
    },{
      value: "Côte D'Azur",
      label: "Côte d'azur",
    },{
      value: "Baléares",
      label: "Baléares",
    },
    {
      value: "DEFAULT",
      label: "Générale",
    }
  ];
  useEffect(() => {  
      if(type==="DESTINATION"){
        regionOptions=regionOptions.filter((item)=>item.value!="DEFAULT")
        console.log('test',type,regionOptions)
      }
      
   }, [type==="DESTINATION"]);
   
    
    useEffect(() => {
      if (region && destinationList) {
        const filteredDestinations = destinationList.filter((item: any) => item.sector === region);
        const dest = filteredDestinations.map((destination: any) => ({
          value: destination.slug,
          label: destination.text
        }));
        setDestinationOptions(dest);
        
        // Log the updated state inside a callback passed to setState
       
        setDestinationOptions(dest => {
          console.log("Updated destinationOptions:", dest);
          return dest;
        });
      }
      if(region==='Alpes')
        setDestinationOptions((prevOptions) => [
          { value: "alpes", label: "Alpes" },
          ...prevOptions // Add existing options
        ]);
      else if(region==='Côte D\'Azur')
        setDestinationOptions((prevOptions) => [
          { value: "Côte D\'Azur", label: "Côte D\'Azur" },
          ...prevOptions // Add existing options
        ]);
        else if(region==='Baléares')
        setDestinationOptions((prevOptions) => [
          { value: "Baléares", label: "Baléares" },
          ...prevOptions // Add existing options
        ]);
    }, [region, destinationList]);

    
 
  
  return (
  <>
    <Formik
      initialValues={destination ? destination : initialDestination}
      // validationSchema={subSectionSchema}
      onSubmit={async (values, {setSubmitting})=>{
        setSubmitting(true)
        try {

          values.region=region;
          values.destination=SelectedDestination
          if(isNotEmpty(destination)){
            try {
              const res = await updateDestination(values);
              Toast.fire({
                icon: 'success',
                title: 'mise à jour réussie'
              });
            } catch (error: any) {
              if (error.response && error.response.data.includes('unique_name_type')) {
                console.error('Error updating destination:', error);
                Toast.fire({
                  icon: 'error',
                  // title:'Erreur',
                  title: `Une destination du même nom {${values.destination}} et du même type {${values.type}} existe déjà.`
                });
              } else {
                Toast.fire({
                  icon: 'error',
                  title: 'Error adding destination'
                });
              }
            }
            
            queryClient.invalidateQueries([`${QUERIES.DESTINATION}-${type}-${key}`])
          }else{
            try { 
              values.type = type
              const res = await addDestination(values);
            
                Toast.fire({
                  icon: 'success',
                  title: 'destination added'
                })
                console.log(`${QUERIES.DESTINATION}-${type}-${key}`)
                queryClient.invalidateQueries([`${QUERIES.DESTINATION}-${type}-${key}`])
            }
            catch (error: any) {

              if (error.response&& error.response.data['messageList'].length>0 && error.response.data['messageList'][0].includes('unique_name_type')) {
                console.error('Error adding destination:', error);
                Toast.fire({
                  icon: 'error',
                  // title:'Erreur',
                  title: `Une destination du même nom {${values.destination}} et du même type {${values.type}} existe déjà.`
                });
              } else {
                Toast.fire({
                  icon: 'error',
                  title: 'Error adding destination'
                });
              }
            }
            
          }
          queryClient.invalidateQueries([`${QUERIES.DESTINATION}-${type}-${key}`])

        } catch (err) {
          // console.log('eroor',err)
          // Toast.fire({
          //   icon: 'error',
          //   title: 'Oops! error'
          // })
        }finally{

          
          setSubmitting(false)
          cancel()
        }
      }}
    >
      {({ values,isSubmitting }) => (
        <Form>
          {isError && (
          <div className="row">
            <div className="col-lg-6 text-center">
              <p></p>
            </div>
          </div>
          )}
          <div className="row">
            
          </div>
          <div className="row">
            
            {/* <div className="col-lg-6">
              
              <>
                <FormControl
                  label='Déstination'
                  name="name"
                  type='text'
                  placeholder='Déstination ...'
                   disabled={isForAdd}
                />
              </>
              
            </div> */}
                  <div className="col-lg-6 mb-4">
                <label htmlFor="region" className='label required'>Région</label>
                <Select
                    options={type==="DESTINATION"?regionOptions.filter((item)=>item.value!="DEFAULT"):regionOptions}
                    name="region"
                    onChange={(selectedOption:any) => { if (selectedOption) {
                      // helperType.setValue(selectedOption.value);
                      console.log(selectedOption.value)
                      setRegion(selectedOption.value);
                    } }}
                    placeholder="Région"
                    defaultValue={regionOptions.find((item) => {return item.value.toLocaleLowerCase() == destination?.region?.toLocaleLowerCase()} )}
                    key={regionOptions.find((item) => item.value.toLocaleLowerCase() === destination?.region?.toLocaleLowerCase()  )?.value} 
                  />

              </div>
              {((destination?.destination || region) && region !=='DEFAULT') && (

                <div className="col-lg-6 mb-4">
                  <label htmlFor="destination" className='label required'>Destination:</label>
                  <Select
                    options={destinationOptions}
                    name="destination"
                    onChange={(selectedOption:any) => { if (selectedOption) {
                      // helperType.setValue(selectedOption.value);
                      console.log(selectedOption.value)
                      setSelectedDestination(selectedOption.value);

                    } }}
                    placeholder="Destination"
                    defaultValue={destinationOptions.find((item) => {return item.value == destination?.destination} )}
                    key={destinationOptions.find((item) => item.value === destination?.destination  )?.value} 
                  />
                </div>
              )}
            <div className="col-md-12">

                  <Divider orientation="horizontal" variant="middle"></Divider>
            </div>
            </div>
           
       
          <div className="row mt-4">
            <div className="col-lg-6">
              <FormControl
                label='metaTitle FR'
                name='metaTitle'
                type='text'
                placeholder='metaTitle fr ...'
              />
            </div>

            <div className="col-lg-6">
              <FormControl
                label='metaTitle ENG'
                name='metaTitleEn'
                type='text'
                placeholder='metaTitle ENG ...'
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <FormControl
                label='metaDescription FR'
                name="metaDescription"
                type='textarea'
                placeholder='metaDescription fr  ...'
              />
            </div>
            <div className="col-lg-6">
              <FormControl
                label='metaDescription ENG'
                name="metaDescriptionEn"
                type='textarea'
                placeholder='metaDescription ENG  ...'
              />
            </div>
            </div>
             <div className="row">
            {/* <DestinationFormList /> */}
          </div>
       
          <div className="row">
            <div className="col-lg-12 d-flex flex-end">
              <Button type='submit' className='btn btn-primary' disabled={isSubmitting} >
                sauvegarder {isSubmitting && (<span className="spinner-border spinner-border-sm align-middle ms-2"></span>)}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  </>
  )
}

export {DestinationEditModalForm}
