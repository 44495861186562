import { useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query'

import { Link } from 'react-router-dom'
import { useListView } from '../../../core/ListViewProvider'
import { Toast } from '../../../../../../../_components/SweetAlerts/SweetAlert'
import { QUERIES } from '../../../../../../../_metronic/helpers'
import { deleteDestination } from '../../../core/_request'

type DestinationDeleteModalProps = {
  type:string
}
const DestinationDeleteModal:React.FC<DestinationDeleteModalProps> = ({type}) => {
  const {setItemIdForDelete,itemIdForDelete} = useListView()
  const queryClient = useQueryClient()

  const deleteItem = useMutation(() => deleteDestination(itemIdForDelete), {
    onSuccess: () => {
      handleCancel()
      Toast.fire({
        icon: 'success',
        title: 'deleted'
      })
      queryClient.invalidateQueries([`${QUERIES.DESTINATION}-${type}`])
    },
    onError:(error) => {
      handleCancel()
      Toast.fire({
        icon: 'error',
        title: 'error when delete'
      })
    },
  })

  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])
  if(!setItemIdForDelete){
    return <></>
  }
  const handleCancel = () => {
    setItemIdForDelete(undefined)
  }
  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          <div className='modal-content'>
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              <div className="text-center">
                <h5 className="fw-bolder fs-1 mb-5">Are you sure delete this listing</h5>
                <div className="separator separator-dashed border-danger opacity-25 mb-5"></div>
                <div className="mb-9">
                  Are you sure delete this listing
                </div>
                <div className="d-flex flex-center flex-wrap">
                  <Link to="#" onClick={handleCancel} className="btn btn-outline btn-outline-danger btn-active-danger m-2">Cancel</Link>
                  <Link to="#" onClick={async () => await deleteItem.mutateAsync()} className="btn btn-danger m-2">Yes</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show' ></div>
    </>
  )
}

export {DestinationDeleteModal}
