import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import { ArticleCreate } from './article-create/ArticleCreate'
import { ArticleEditImages } from './article-edit/article-edit-images/ArticleEditImages'
// import { ArticleEditSide } from './article-edit/restaurantEditSide'

import { ArticleListWrapper } from './article-list/ArticleList'
// import ArticleView from './article-view/ArticleView'
import { useIntl } from 'react-intl'
import { ArticleEditSide } from './article-edit/ArticleEditSide'
import { ArticleEditInformation } from './article-edit/article-edit-information/ArticleEditInformation'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Liste des articles',
    path: 'apps/articles',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ArticlePage = () => {
  const intl = useIntl()
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}> Liste des articles
                {/* {intl.formatMessage({id: 'LISTING.TITLE'})} */}
                </PageTitle>
              <ArticleListWrapper />
            </>
          }
        />
        <Route
          path='create'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>{intl.formatMessage({id: 'Article.ADD'})}</PageTitle>
              <ArticleCreate />
            </>
          }
        />
        <Route
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>{intl.formatMessage({id: 'Article.EDIT'})}</PageTitle>
              <ArticleEditSide />
            </>
          }
        >
          <Route
            path='edit/information/:name'
            element={
              <>
                <ArticleEditInformation />
              </>
            }
          />
          <Route
            path='edit/contenu/:name'
            element={
              <>
                <ArticleEditImages />
              </>
            }
          />
          <Route index element={<Navigate to='/apps/articles/list' />} />
        </Route>
       
      </Route>
      <Route index element={<Navigate to='/apps/articles/list' />} />
    </Routes>
  )
}

export default ArticlePage
