import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useField } from "formik";
import { Button } from "react-bootstrap";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Image } from "../../app/modules/features/listing-vente/core/_models";


type HandleImageProps = {
  name:string
}
const HandleImages:React.FC<HandleImageProps> = ({name}) => {
  const [field, , helpers] = useField(name);
  const { setValue } = helpers;
  const { value } = field;
  const [images,setImages] = useState<Array<File>>([])

  const onDrop = useCallback(
    (acceptedFiles:File[]) => {
      setImages((prevImages) => prevImages.concat(acceptedFiles))
      setValue(value.concat(acceptedFiles));
    },
    [setValue, value]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true,
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/jpg': []
    }
  });


  //Delete Image
  const deleteImagePreview = (preview : string)=>{
    let imagesList = images
    imagesList = imagesList.filter(image => image.name !== preview)
    setImages(imagesList) 

    let imagesValuesList = field.value
    imagesValuesList = imagesValuesList.filter((image:any) => image.name !== preview)
    setValue(imagesValuesList) 
  }
  return (
    <>
      <div className="dropzone">
        <div
            className="dz-message needsclick mt-2"
            {...getRootProps()}
        >
            <input {...getInputProps()} />
            {isDragActive ? (
              <h4 className="text-center">Déposez votre photo ici.</h4>
            ) : (
              <h4 className="text-center">Déposez votre photo ici.</h4>
            )}
        </div>
      </div>
      <div className="dropzone-previews mt-5" id="file-previews">
      { value.length > 0 && (
        <Swiper
        pagination={{
          type: "fraction",
        }}
        modules={[Pagination]}
        className="mySwiper"
        breakpoints={{
          // when window width is >= 640px
          200: {
            width: 200,
            slidesPerView: 1,
            spaceBetween: 10
          },
          // when window width is >= 640px
          640: {
            width: 640,
            slidesPerView: 3,
            spaceBetween: 30
          },
          // when window width is >= 768px
          768: {
            width: 768,
            slidesPerView: 4,
            spaceBetween: 50
          },
        }}
      >
        { value && value.map((item:File,idx:number)=>(
          <SwiperSlide key={`images-${idx}`}>
            <div  className='mb-10'>
              <img
                  src={URL.createObjectURL(item)} 
                  alt={item.name} 
                  data-dz-thumbnail=""
                  width="150px"
                  height="150px"
                  className="rounded bg-light mb-2"
                  
              />
            
              <Button 
                onClick={() =>deleteImagePreview(item.name)} 
                // style={{cursor : "pointer", position:"absolute",top:4,right:4}}  
                className="btn btn-sm btn-danger  mb-2"
                style={{width:"150px"}}
              >Supprimer</Button>
            </div>
          </SwiperSlide>
        ))       
        }
        ...
      </Swiper>
      // <div className="dropzone-previews mt-3" id="file-previews">
      //   <div
      //     className="m-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
      //   >
      //     <div  className="d-flex m-2" style={{overflow:"auto"}}>
      //     { images && images.map((f:File, i:any) =>  (
      //       <div key={i + "-img"} className="m-2">
      //         <div>
      //           <img
      //               src={URL.createObjectURL(f)} 
      //               alt={f.name} 
      //               data-dz-thumbnail=""
      //               width="150px"
      //               height="150px"
      //               className="rounded bg-light mb-2"
      //           />
      //           <Button 
      //             onClick={()=>deleteImagePreview(f.name)} 
      //             // style={{cursor : "pointer", position:"absolute",top:4,right:4}}  
      //             className="btn btn-sm btn-danger  mb-2"
      //             style={{width:"150px"}}
      //           >Supprimer</Button>
      //         </div>
      //       </div>
      //       )
      //     )}
      //     </div>
      //   </div>
      // </div>
      )}
      </div>
    </>
  )
}

export default HandleImages
