import { Formik, Form ,FormikConsumer, ErrorMessageProps} from 'formik';




//Components
import ArticleInfo from './ArticleInfo/ArticleInfo';
import ArticleDetails from './ArticleDetails/ArticleDetails';
import ArticleSEO from './SEO/ArticleSEO';
import { Toast } from '../../../../../../../_components/SweetAlerts/SweetAlert';
// import { Art } from '../../../../core/_validation';
import { ArticleType } from '../../../../settings/core/_models';
import { updateArticle } from  '../../../../settings/core/_request';
import { LoadingHelper } from '../../../../../../../_metronic/helpers/LoadingHelper';
import clsx from 'clsx';
import { useLayout } from '../../../../../../../_metronic/layout/core';
import { updateSEOByType } from '../../../../settings/core/_request';
import { SeoType } from '../../../../settings/core/_models';
// import { handleArticleObject, handleArticleObjectResponse } from '../../../../core/_helpers';
import { Button } from 'react-bootstrap';
import {  handleUpdateArticleFormData } from '../../../../settings/core/_helpers';


type ArticleFormEditInformationProps = {
  isListingLoading:boolean
  listing:ArticleType 
}


const ArticleFormEditInformation:React.FC<ArticleFormEditInformationProps> = ({isListingLoading,listing}) => {
  const {config} = useLayout()
  if (!config.app?.footer?.display) {
    return null
  }
  if(listing && listing.contenue!=undefined){

    const [title, subtitle] = listing.contenue.split('\n');
    listing.title=title;
    listing.subtitle=subtitle;

  }

  return (
    <>      
      <Formik
        initialValues={listing}
        // validationSchema={ArticleSchema}
          onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true)
          try {


            
            if(values.subtitle)
                values.contenue=values.title+"\n"+values.subtitle;
            else 
                values.contenue=values.title+"";
          
            var fd:FormData=handleUpdateArticleFormData(values);
            updateArticle(fd).then((fd)=>{
              Toast.fire({
                icon: 'success',
                title: 'updates'
              })
            }).catch((err)=>{
              Toast.fire({
                icon: 'error',
                title: 'error when updates'
              })

            })
            const seo: SeoType={
              "titre":values.name,
              "title":values.seo?.title,
              "descriptionFR":values.seo?.descriptionFR,
              "descriptionENG":values.seo?.descriptionENG,
              "id":values.seo?.id,
              "type":'article-'+values.name,
            }
            updateSEOByType(seo).then((res)=>{
              Toast.fire({
                icon: 'success',
                title: 'updates'
              })
            }).catch((err)=>{
              Toast.fire({
                icon: 'error',
                title: 'error when updates'
              })

            })

          } catch (ex) {
              console.error(ex)
             
          } finally {
            setSubmitting(true)
            setSubmitting(false)
          }

        }}
      >
        {({ isSubmitting , ...formikProps}) => (
        <Form >
          <div className='row g-5 g-xl-10 mb-2 mb-xl-2'>
            <div className='col-md-8 col-lg-8 col-xl-8 col-xxl-8 mb-md-5 '>
            
              <ArticleInfo isForEdit={true}/>
            </div>
            <div className='col-md-4 col-lg-4 col-xl-4 col-xxl-4 mb-md-5 '>
              <ArticleDetails  helpers={formikProps} article={listing}/>
            </div>
          </div>
         
          {/* SEO */}
          <div className='row g-5 g-xl-10 mb-2 mb-xl-2'>
            <div className='col-sm-12 mb-md-5 '>
              <ArticleSEO />
            </div>
          </div>
          {/* save */}
          {/* save */}
          <div className='app-footer justify-content-end  fixed-bottom' id='kt_app_footer'>
            {config?.app?.footer?.containerClass ? (
              <div className={clsx('app-container', config.app.footer.containerClass)}>
                  <Button type='submit' className='btn btn-primary w-100' disabled={isSubmitting} >
                    Sauvegardez {isSubmitting && (<span className="spinner-border spinner-border-sm align-middle ms-2"></span>)}
                  </Button>
              </div>
            ) : (
              <Button type='submit' className='btn btn-primary w-100' disabled={isSubmitting} >
                Sauvegardez {isSubmitting && (<span className="spinner-border spinner-border-sm align-middle ms-2"></span>)}
              </Button>
            )}
          </div>
          {/* <div className='row g-5 g-xl-10 mb-2 mb-xl-2'>
            <div className='col-sm-12 mb-md-5 '>
              <div className="card card-custom card-flush mb-6">
                <div className="card-body">
                    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
                      <Button type='submit' className='btn btn-primary' disabled={isSubmitting} >
                        Sauvegardez {isSubmitting && (<span className="spinner-border spinner-border-sm align-middle ms-2"></span>)}
                      </Button>
                    </div>
                </div>
              </div>
            </div>
          </div> */}
          {isSubmitting && (
            <div className="overlay overlay-block rounded">
                <div className="overlay-wrapper p-5">
                  <LoadingHelper />
                </div>
            </div>
          )}
        </Form>
        )}
        
      </Formik>
    </>
  )
}
export default ArticleFormEditInformation
