/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Link} from 'react-router-dom'
import {Outlet, useLocation} from 'react-router'
import clsx from 'clsx'
import { ListViewProvider } from './core/ListViewProvider'

const SettingsSide: React.FC = () => {
  const location = useLocation()
  
  return (
    <>

          <div className="row">
      <div className="col-lg-12">
        <div className='card mb-5'>
          <div className='card-body pt-9 pb-0'>
            <ListViewProvider>
              <Outlet />
            </ListViewProvider>
          </div>
        </div>
      </div>
    </div>
    </>
    
  )
}

export {SettingsSide}
