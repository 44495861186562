import { useQuery } from "react-query"
import { useParams } from "react-router-dom"
import { QUERIES, isNotEmpty } from "../../../../_metronic/helpers"
import { LoadingHelper } from "../../../../_metronic/helpers/LoadingHelper"
import CheckInFormView from "./CheckInFormView"
import { getReservationById } from "../../../modules/features/guesty/reservation-guesty/reservation-view/Core/_requests"

const CheckInFormWarpper = () => {
  const {_id} = useParams()
  const enabledQuery: boolean = isNotEmpty(_id)

  const {
    isLoading,
    data,
    error,
    isError,
  } = useQuery(
    `${QUERIES.RESERVATION_GUESTY_LIST}-${_id}`,
    () => {
      return getReservationById(_id)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
    }
  )

  if (isLoading) {
    return   <LoadingHelper />
  }

  if (isError) {
    return <div>error....</div>
  }

  if (!isLoading && !error && data) {
    return <CheckInFormView reservation={data} />
  }

  return null
}

export {CheckInFormWarpper}
