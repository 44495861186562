import { Explore, ExploreObject } from "./_models";

export const ExploreInitState : ExploreObject  =  {
    
    titre:'',
    title:'',
    descriptionFR:'',
    descriptionENG:'',
    region:'',
    type:'',
    slug:'',
    category:'',
    // image: new Image(),
    // meta_title:"",
    // meta_titre:"",
    // meta_description:"",
    // meta_descriptionEN:"",
    // seo_id:0
}

// export type RestaurantImageType ={
//     imagesList: File[]
//   }
// export const  RestaurantImageInitState : RestaurantImageType = {
//     imagesList: [] 
// }




