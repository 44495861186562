import {FC, useState, createContext, useContext, Dispatch, SetStateAction} from 'react'
import {
  ID,
  WithChildren,
} from '../../../../../../_metronic/helpers'

export type ListingViewContextProps = {
  itemIdForDelete?: ID
  setItemIdFordelete: Dispatch<SetStateAction<ID>>
}

export const initialListView: ListingViewContextProps = {
  setItemIdFordelete: () => {},
}

const ListingViewContext = createContext<ListingViewContextProps>(initialListView)
  const ListingViewProvider: FC<WithChildren> = ({children}) => {
  const [itemIdForDelete, setItemIdFordelete] = useState<ID>(initialListView.itemIdForDelete)

  return (
    <ListingViewContext.Provider
      value={{
        itemIdForDelete,
        setItemIdFordelete,
      }}
    >
      {children}
    </ListingViewContext.Provider>
  )
}

const useListingView = () => useContext(ListingViewContext)

export {ListingViewProvider, useListingView}
