import { useState } from 'react'
import clsx from 'clsx'
import { VenteDestination } from './cards/VenteDestination'


export function SettingsVente() {
  const [tab, setTab] = useState('vente')

  return (
    <>
      <div className='card card-custom'>
        <div className='card-header card-header-stretch overflow-auto'>
          <ul
            className='nav nav-stretch nav-line-tabs
            fw-bold
            border-transparent
            flex-nowrap
          '
            role='tablist'
          >
            <li className='nav-item'>
              <a
                href='#'
                className={clsx(`nav-link text-dark cursor-pointer`, {active: tab === 'vente'})}
                onClick={() => setTab('Conciergerie Home Booker')}
                role='tab'
              >
                Vente
              </a>
            </li>
          </ul>
        </div>

        <div className='card-body'>
          <div className='tab-content pt-3'>
            <div className={clsx('tab-pane', {active: tab === 'vente'})}>
              <VenteDestination />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
