import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useField } from "formik";

type HandleVideoProps = {
  name:string
  preview?:boolean
  imageUrl?:string
}
const HandleVideo:React.FC<HandleVideoProps> = ({name,imageUrl,preview}) => {
  const [field, , helpers] = useField(name);
  const { setValue } = helpers;
  const { value } = field;

  const onDrop = useCallback(
    (acceptedFiles:File[]) => {
      setValue(acceptedFiles[0]);
      const videoURL = URL.createObjectURL(acceptedFiles[0]);
      const videoElement = document.getElementById("preview_source")  as HTMLVideoElement;
      videoElement.src=videoURL+"?mute=0";
    },
    [setValue]
  );
 



  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      'video/mp4': []
    }
  });

  return (
    <>
    <div className="row">
      <div className="col-lg-6">
        <div className="dropzone">
          <div
              className="dz-message needsclick mt-2"
              {...getRootProps()}
          >
            
              <input {...getInputProps()}  />
              {isDragActive ? (
                <h4 className="text-center">Déposez votre video ici.</h4>
              ) : (
                <h4 className="text-center">Déposez votre video ici.</h4>
              )}
          </div>
        </div>
      </div>
      <div className="col-lg-6">
       
        { (value) ? (
          <div className="dropzone-previews mt-3" id="file-previews">
            { value && (
              <div className="m-2">
                <div>
                <iframe id="preview_source"  width="100%" className="rounded bg-light mb-2"
                   src={URL.createObjectURL(value)}></iframe>
                  {/* <video autoPlay muted width="100%"
                      className="rounded bg-light mb-2">
                    <source src={value} type="video/mp4"  id="preview_source"/>
                      Your browser does not support the video tag.
                  </video> */}
                </div>
              </div>
            )}
          </div>
        ):(
          <div className="dropzone-previews mt-3" id="file-previews">
              <div className="m-2">
                <div>
                <video autoPlay muted width="100%"
                      className="rounded bg-light mb-2">
                    <source src={imageUrl} type="video/mp4" />
                      Your browser does not support the video tag.
                </video>
                 
                </div>
              </div>
          </div>
        )}
      </div>
    </div>
    </>
  )
}

export default HandleVideo
