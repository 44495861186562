import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useField } from "formik";

type HandleImageProps = {
  name:string
  preview?:boolean
  imageUrl?:string
  width?:string
}
const HandleImage:React.FC<HandleImageProps> = ({name,imageUrl,preview,width}) => {
  const [field, , helpers] = useField(name);
  const { setValue } = helpers;
  const { value } = field;

  const onDrop = useCallback(
    (acceptedFiles:File[]) => {
      setValue(acceptedFiles[0]);
    },
    [setValue]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/jpg': [],
      'image/gif': []
    }
  });
  return (
    <>
    <div className="row">
      <div className="col-lg-6">
        <div className="dropzone">
          <div
              className="dz-message needsclick mt-2"
              {...getRootProps()}
          >
              <input {...getInputProps()} />
              {isDragActive ? (
                <h4 className="text-center">Déposez votre photo ici.</h4>
              ) : (
                <h4 className="text-center">Déposez votre photo ici.</h4>
              )}
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        { (value) ? (
          <div className="dropzone-previews mt-3" id="file-previews">
            { value && (
              <div className="m-2">
                <div>
                  <img
                      src={URL.createObjectURL(value)} 
                      alt={value.name} 
                      data-dz-thumbnail=""
                      width=   {width ? width : "100%"}
                      className="rounded bg-light mb-2"
                  />
                </div>
              </div>
            )}
          </div>
        ):(
          <div className="dropzone-previews mt-3" id="file-previews">
              <div className="m-2">
                <div>
                  <img
                      src={imageUrl} 
                      alt={imageUrl} 
                      data-dz-thumbnail=""
                      width=   {width ? width : "100%"}
                      className="rounded bg-light mb-2"
                  />
                </div>
              </div>
          </div>
        )}
      </div>
    </div>
    </>
  )
}

export default HandleImage
