

import FormControl from '../../../../../../../../_components/formControl/FormControl';
import { Field, FieldInputProps, FormikProps, FormikValues } from 'formik';
import { useState } from 'react';
import { checkArticleNameIfExist } from '../../../../../settings/core/_request';


const ArticleInfo = ({ isForEdit }: { isForEdit: boolean }) => {

  const [isNameAvailable, setIsNameAvailable] = useState(false);
  const handleNameChange = async (name:string) => {
    // Call the CHECKName function from _requests module
  
    try {
      const isAvailable = await checkArticleNameIfExist(name);
      setIsNameAvailable(isAvailable);
    } catch (error) {
      console.error('Error checking name availability:', error);
      // Handle error if needed
    }
  };


  return (
    <>
      <div className="card card-custom card-flush mb-6">
        <div className="card-body">
          <div className='col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-md-5 '>
            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Informations</label>
            
            <div className='row'>
              <div className='col-sm-6 fv-row  my-2'>
             
                <span className='form-label required mb-6'>Nom </span>
                <Field
                    name="name"
                    label="Nom"
                    type="text"
                    placeholder="Nom de l'article ..."
                    className="form-control"
                    disable={isForEdit}
                    render={({ field, form }: { field: FieldInputProps<string>, form: FormikProps<FormikValues> }) => (
                      <input
                        {...field}
                      
                        className="form-control"
                        placeholder="Nom de l'article"
                        name="name"
                        disabled={isForEdit}
                        onChange={(e) => {
                          form.setFieldValue('name', e.target?.value); // Manually update form field value
                          handleNameChange(e.target?.value); // Call your custom logic
                        }}
                        value={field?.value} // Ensure the field value is correctly set
                      />
                    )}
                />
                {isNameAvailable && <div style={{ color: 'red' }}>Ce nom est déjà utilisé.</div>}
            
                </div>
               
                <div className='col-sm-6 fv-row'>
                
                 {/* <Form.Select size="lg" name='type'>
                   
                  </Form.Select> */}
                {isForEdit?
                <FormControl
                label='Type'
                name="type"
                type='text'
                placeholder="Titre de l'article"
                disabled={isForEdit||true}
                />
                : <div className='mt-2'>
                  <span className='form-label required mb-8'>Type</span>
                  <Field name="type" as="select" className="form-control ">
                  <option >Type de l'article</option>
                  <option value="WEB">Web</option>
                    <option value="PAPIER">Papier</option>
                  </Field>
                  </div>
                  
                  }  
                </div>
                <span className='form-label mb-6'>NB: Le nom de l'article doit être de la forme [abc ou abc-def].</span>
               
            </div>
            <div className='row'>
              <div className='col-sm-6 fv-row'>
                <FormControl
                  label='Titre'
                  name="title"
                  type='text'
                  placeholder="Titre de l'article"
                  // disabled={true}
                />
              </div>
              <div className='col-sm-6 fv-row'>
                <FormControl
                  label='Sous-titre'
                  name="subtitle"
                  type='text'
                  placeholder="Sous-titre de l'article"
                />
              </div>
            </div>
            
           
            <div className='row'>
              <div className='col-sm-6 fv-row'>
                <FormControl
                  label='urlWeb'
                  name="urlWeb"
                  type='text'
                  placeholder="Lien de l'article"
                />
              </div>
              <div className='col-sm-6 fv-row'>
                <FormControl
                  label='text du lien'
                  name="footerLinkDescription"
                  type='text'
                  placeholder='Text du lien'
                />
              </div>
            </div>
            <div className='row'>
             
             <div className='col-sm-6 fv-row'>
               <FormControl
                 label='Date'
                 name="source"
                 type='text'
                 placeholder='ex: JANVIER 2023 ...'
               />
             </div>
           </div>
           
           
          </div>
        </div>
      </div>
    </>
  )
}

export default ArticleInfo