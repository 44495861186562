import { useQuery } from "react-query"
import { useParams } from "react-router-dom"
import { isNotEmpty, QUERIES } from "../../../../../../_metronic/helpers"
// import ArticleFormEditImages from "./Components/ArticleFormEditImages"

import { getArticleByName } from "../../../settings/core/_request"
import ArticleFormEditImages from "./Components/ArticleFormEditImages"
import { Alert } from "react-bootstrap"

const ArticleFormEditImagesWarpper = () => {
  const {name} = useParams()
  const enabledQuery: boolean = isNotEmpty(name)
  
  const {
    isLoading,
    data: listing,
    error,
  } = useQuery(
    `${QUERIES.RESTAURANT_LIST}-listing`,
    () => {
      return getArticleByName(name||"")
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
      },
    }
  )

  if (!name && error) {
    return <div>Oops !!!</div>
  }

  if (!isLoading && !error && listing) {
   
      return <ArticleFormEditImages isListingLoading={isLoading} listing={listing} />
  
  }

  return null
}

export {ArticleFormEditImagesWarpper}
