import { ExploreInitState } from "../../../explore/explore-list/core/_initState";
import { Activity, ActivityObject } from "./_models";

export const ActivityInitState : ActivityObject  =  {
    
    titre:'',
    title:'',
    descriptionFR:'',
    descriptionENG:'',

    subRegion:'',
    slug:'',
    explore:ExploreInitState
  
    // image: new Image(),
    // meta_title:"",
    // meta_titre:"",
    // meta_description:"",
    // meta_descriptionEN:"",
    // seo_id:0
}

// export type RestaurantImageType ={
//     imagesList: File[]
//   }
// export const  RestaurantImageInitState : RestaurantImageType = {
//     imagesList: [] 
// }




