
import { ArticleEditInformationWarpper } from './ArticleEditInformationWarpper';


const ArticleFormPageWarpper = () => {

  return (
    <>      
      <ArticleEditInformationWarpper />
    </>
  )
}

const ArticleEditInformation = () => (
 
      <ArticleFormPageWarpper />

)

export {ArticleEditInformation}
