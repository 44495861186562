import axios, { AxiosResponse } from 'axios'
import {ID} from '../../../../../../../_metronic/helpers'
import { CancelType, ContratAllType, ContratType, ReservationDetailsResponse } from '../../reservation-view/Core/_models'

const API_URL = process.env.REACT_APP_API_URL

const GET_ALL_RESERVATION_URL = `${API_URL}/guesty/reservations`
const UPDATE_RESERVATION_URL = `${API_URL}/guesty/reservation`


const SEND_CONTRAT_RESERVATION_END_POINT = `${API_URL}/reservation/check-in-form/create`
const SEND_CANCEL_RESERVATION_END_POINT = `${API_URL}/reservation/check-in-form/cancel`
const SEND_PAYEMENT_RESERVATION_END_POINT = `${API_URL}/reservation/check-in-form/payment`


const GET_CONTRAT_BY_RESERVATION_ID_END_POINT = `${API_URL}/reservation/check-in-form/contract`


const getReservationById = (id: ID) : Promise<ReservationDetailsResponse> => {
  return axios
    .get(`${GET_ALL_RESERVATION_URL}/${id}`)
      .then((d:AxiosResponse<ReservationDetailsResponse>) =>  d.data)
}

const updateReservation = (id: ID,status:string) => {
  return axios
  .post(`${UPDATE_RESERVATION_URL}/${id}/${status}`)
    .then((response) => {return response.data})
    .catch((err) => {return err})
}

const sendContratMail = (lang:string,body:ContratType)=>{
  return  axios
  .post(`${SEND_CONTRAT_RESERVATION_END_POINT}/${lang}`,body)
    .then((response) => response)
    .catch((err)=>{return err})

}
const sendCancelMail = (lang:string,body:CancelType)=>{
  return  axios
  .post(`${SEND_CANCEL_RESERVATION_END_POINT}/${lang}?reservationId=${body.reservationId}&firstName=${body.firstName}&lastName=${body.lastName}&email=${body.email}&listingNickname=${body.listingNickName}`)
    .then((response) => response.data)
    .catch((err)=>{return err})
}
const sendPaimentMail = (lang:string,query:string)=>{
  return  axios
  .post(`${SEND_PAYEMENT_RESERVATION_END_POINT}/${lang}?${query}`)
    .then((response) => response.data)
    .catch((err)=>{return err})
}


const getContartByReservationId = (id: ID) : Promise<ContratAllType> => {
  return axios
    .get(`${GET_CONTRAT_BY_RESERVATION_ID_END_POINT}?reservationId=${id}`)
      .then((d:AxiosResponse<ContratAllType>) =>  d.data)
}


export {
  getReservationById,
  updateReservation,
  sendContratMail,
  sendCancelMail,
  sendPaimentMail,
  getContartByReservationId
}

