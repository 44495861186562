import { property } from 'lodash'
import * as Yup from 'yup'

export const sectionSchema = Yup.object().shape({
    title: Yup.string().required('title is required'),
    titre: Yup.string().required('titre is required'),
    descriptionENG: Yup.string().required('descriptionENG is required'),
    descriptionFR: Yup.string().required('descriptionFR is required'),
    type: Yup.string().required('type is required'),
})

export const subsectionBannerSchema = Yup.object().shape({
    title: Yup.string().required('title is required'),
    titre: Yup.string().required('titre is required'),
    descriptionENG: Yup.string().required('descriptionENG is required'),
    descriptionFR: Yup.string().required('descriptionFR is required'),
    // type: Yup.string().required('type is required'),
})


export const SelectedPropSchema = Yup.object().shape({
    tag: Yup.string().required('Tag et obligatoire'),
})


export const faqSchema = Yup.object().shape({
    questionFR: Yup.string().required('questionFR is required'),
    questionEN: Yup.string().required('questionEN is required'),
    // type: Yup.array()
    //     .min(1, 'You must choose at least one'),
    // type: Yup.string().required('You must choose at least one option'),
    type: Yup.mixed().test('type-validation', 'You must choose at least one', function (value) {
      const isArray = Array.isArray(value);
      const isString = typeof value === 'string';
  
      if (isArray) {
        return value.length > 0;
      } else if (isString) {
        return true;
      } else {
        return false;
      }
    }),
    
    answerFR: Yup.string().required('answerFR is required'),
    answerEN: Yup.string().required('answerEN is required'),

})

export const subSectionSchema = Yup.object().shape({
    title: Yup.string().required('title is required'),
    titre: Yup.string().required('titre is required'),
    descriptionENG: Yup.string().required('descriptionENG is required'),
    descriptionFR: Yup.string().required('descriptionFR is required'),
})
export const satisticsSchema = Yup.object().shape({
  titre: Yup.string().required('titre is required'),
  descriptionFR: Yup.string().required('descriptionFR is required'),
  name: Yup.string().required('key is required'),
})
export const seoSchema = Yup.object().shape({
    // title: Yup.string().required('title is required'),
    // titre: Yup.string().required('titre is required'),
    // descriptionENG: Yup.string().required('descriptionENG is required'),
    // descriptionFR: Yup.string().required('descriptionFR is required'),
    type: Yup.string().required('type is required'),
})

export const contactSchema = Yup.object().shape({
    phone: Yup.string().required('phone is required'),
    address: Yup.string().required('address is required'),
    map: Yup.string().required('map is required'),
})


export const socialMediaSchema = Yup.object().shape({
    fb: Yup.string().required('fb is required'),
    insta: Yup.string().required('insta is required'),
    youtube: Yup.string().required('youtube is required'),
    tiktok: Yup.string().required('tiktok is required'),
})

export const ArticleSchema = Yup.object().shape({
    
    name: Yup.string()
    .required('Le nom de l\'article est un champ obligatoire') .test(
        'containsSpacesOrAccentedCharacters',
        "Le champ ne peut pas contenir d'espaces ou de caractères accentués",
        function (value) {
          // Custom validation logic
          if (/\s/.test(value||'') || /[À-ž]/.test(value||'')) {
            return false; // Validation failed
          }
          return true; // Validation passed
        }
      ),
   
    type: Yup.string()
      .required('Le type de l\'article est un champ obligatoire'),
    title: Yup.string()
      .required('Le titre de l\'article est un champ obligatoire'),
    subtitle: Yup.string()
      .required('Le sous-titre de l\'article est un champ obligatoire'),
    imageList: Yup.array().when("type", {
        is: (type:string) => type === "PAPIER", // Check if type is 'PAPIER'
        then: Yup.array()
          .min(1, "L'article de type papier doit contenir au moins une image")
          .max(2, "l'article de type papier ne doit pas contenir plus de deux images "),
        otherwise: Yup.array(),
      }),
      urlWeb : Yup.string().when("type", {
        is: (type:string) => type === "WEB", // Check if type is 'PAPIER'
        then: Yup.string()
          .required( "L'article de type web doit contenir  un lien vers l'article"),
      }),
    listDetails:Yup.array()
     .min(1, "Veuillez ajouter au moins une paraghraphe"),
    imageByte: Yup.mixed()
    .test('fileRequired', 'Image de couverture est obligatoire', function (value) {
      return value !== null && value !== undefined;
    }),

  });



export const ConciergeSchema = Yup.object().shape({
  title: Yup.string().required('title is required'),
  titre: Yup.string().required('titre is required'),
  descriptionEn: Yup.string().required('description EN is required'),
  descriptionFR: Yup.string().required('descriptionFR is required'),
  sector: Yup.string().required('sector is required'),
  slug: Yup.string().required('slug is required'),
})
export const ConciergeItemSchema = Yup.object().shape({
  title: Yup.string().required('title is required'),
  titre: Yup.string().required('titre is required'),
  descriptionEn: Yup.string().required('description EN is required'),
  descriptionFR: Yup.string().required('descriptionFR is required'),
  city: Yup.string().required('city is required'),
  slug: Yup.string().required('slug is required'),
})
export const ConciergeServiceSchema = Yup.object().shape({
  title: Yup.string().required('title is required'),
  titre: Yup.string().required('titre is required'),
  descriptionEn: Yup.string().required('description EN is required'),
  descriptionFR: Yup.string().required('descriptionFR is required'),
  name: Yup.string().required('city is required'),
  slug: Yup.string().required('slug is required'),
})
 
export const ReviewSchema = Yup.object().shape({
  name: Yup.string().required('title is required'),
  // date: Yup.string().required('titre is required'),
  place: Yup.string().required('slug is required'),
  message: Yup.string().required('slug is required'),
})
 