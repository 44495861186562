import { Field, useField } from 'formik';
import React from 'react';

type HandleCheckboxProps = {
  label?: string;
  name: string;
  type?:string
  placeholder?:string
}

const HandleCheckbox: React.FC<HandleCheckboxProps> = ({ label, name,type = "text",placeholder }) => {

  const [field, meta] = useField(name);

  return (
    <div className="mb-4">
      <div className="form-check form-check-custom form-check-solid form-check-sm">
        <Field
          type="checkbox"
          id="confirm"
          className="form-check-input"
          checked={field.value}
          {...field}
        />
        {label && (<label className="form-check-label">{label}</label>)}
      </div>
      {meta.touched && meta.error ? (
        <div className='fv-plugins-message-container'>
        <div className='fv-help-block'>{meta.error}</div>
      </div>
      ) : null}
    </div>
  );
};

export default HandleCheckbox;