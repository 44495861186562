import {FC, useState, createContext, useContext} from 'react'
import { ID, ListViewContextProps, WithChildren, initialListView } from '../../../../../_metronic/helpers'

const ListViewContext = createContext<ListViewContextProps>(initialListView);

const ListViewProvider: FC<WithChildren> = ({ children }) => {
  const [itemIdForUpdate, setItemIdForUpdate] = useState<ID>(
    initialListView.itemIdForUpdate
  );

  const [itemIdForDelete, setItemIdForDelete] = useState<ID>(
    initialListView.itemIdForDelete  // Corrected the initialization here
  );

  return (
    <ListViewContext.Provider
      value={{
        itemIdForUpdate,
        setItemIdForUpdate,
        itemIdForDelete,
        setItemIdForDelete,
      }}
    >
      {children}
    </ListViewContext.Provider>
  );
};

const useListView = () => useContext(ListViewContext);

export { ListViewProvider, useListView };