import { KTCard, KTCardBody } from "../../_metronic/helpers"

const Expire = () => {
  return (
    <KTCard>
      <KTCardBody>
        <div className="d-flex flex-column flex-center my-16">
          <h5>Expire</h5>
        </div>
      </KTCardBody>
    </KTCard>
  )
}

export default Expire