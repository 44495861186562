import {useEffect} from 'react'
import { DestinationEditModalFormWrapper } from './DestinationEditModalFormWrapper'
import { DestinationEditModalHeader } from './DestinationEditModalHeader'


type DestinationEditModalProps = {
  type:string
  key:any
}
const DestinationEditModal:React.FC<DestinationEditModalProps> = ({type,key}) => {

  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <div
        className='modal fade  show d-block modal-xl'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable'>
          <div className='modal-content'>
            <DestinationEditModalHeader />
            <div className='modal-body scroll-y m-2'>
              <DestinationEditModalFormWrapper type={type} key={key}/>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export {DestinationEditModal}