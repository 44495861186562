import {useMemo,useState,useEffect} from 'react'

import { useQuery } from 'react-query'
import { getArticles } from '../../../settings/core/_request'
import { MaterialReactTable } from 'material-react-table';
import { Link } from 'react-router-dom'
import { KTSVG } from '../../../../../../_metronic/helpers'
import { MRT_ColumnDef } from 'material-react-table';

import { createTheme, ThemeProvider, useTheme } from '@mui/material';

import { useListingView } from '../core/ListingViewProvider'
import { ArticleType } from '../../../settings/core/_models';
import Loading from '../../../../../../_components/Loading/Loading';
const ArticleTable = () => {
 
  




 const {
    isLoading,
    data: restaurantList,
    error,
  } = useQuery(
    `Get all section categories`,
    () => {
      return getArticles()
    },
    {
      cacheTime: 0,
      onError: (err:any) => {
        if (err.message) {
          console.error(`Error: ${err.message}`);
        } else {
          console.error('An error occurred.');
        }
      },
    }
  )
 
  const globalTheme = useTheme();
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.secondary, //swap in the secondary color as the primary for the table
          info: {
            main: '#fff', //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === 'light'
                ? '#fff' //random light yellow color for the background in light mode
                : '#000', //pure black table in dark mode for fun
          },
          
        },
        typography: {
          button: {
            textTransform: 'none', //customize typography styles for all buttons in table by default
            fontSize: '1.2rem',
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: '1.1rem', //override to make tooltip font size larger
              },
            },
          },
          MuiSwitch: {
            styleOverrides: {
              thumb: {
                color: '#222', //change the color of the switch thumb in the columns show/hide menu to pink
              },
            },
          },
        },
      }),
    [globalTheme],
  );

  const {setItemIdFordelete} = useListingView()
  const openDeleteModal = (id:any) => {
    setItemIdFordelete(id);
  }


const ArticleColumns: MRT_ColumnDef<ArticleType>[] = [
  {
    accessorKey: 'id', //access nested data with dot notation
        header: 'ID',
        size: 10,
  },
  {

    accessorKey: 'type',
        header: 'Type',
        size: 10,
  },
  {
    accessorKey: 'name',
        header: 'Article',
        size: 10,
  },
  {
    accessorKey: 'source', //normal accessorKey
        header: 'Date',
        size: 10,  
  }, {
    accessorKey: 'contenue', //normal accessorKey
        header: 'Contenu',
        size: 340,  
  },
  {
    header: 'Actions',
    // accessorKey: 'id', // Assuming 'id' is your unique identifier
    size: 100,
    Cell: ({ cell }) =>  (
      <div>
        
        <Link
          to={`/apps/articles/edit/information/${cell.row.original.name}`}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen019.svg'
            className='svg-icon-3'
          />
        </Link>
        <a
          onClick={() => openDeleteModal(cell.row.original.id)} 
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen027.svg'
            className='svg-icon-3'
          />
        </a>
      </div>
    ),
  }

  
  // Add more columns as needed...
];


  const typedArticleList = restaurantList as Array<ArticleType>;

  return (
    <div>
      {isLoading ? (
         <Loading />
      ) : error ? (
        <p>Error: {error.message}</p>
      ) : (
        <ThemeProvider theme={tableTheme}>
             <MaterialReactTable columns={ArticleColumns} data={typedArticleList}
              muiTablePaperProps={{
                elevation: 0, //change the mui box shadow
                //customize paper styles
                sx: {
                  paddingLeft:"20px",
                  paddingRight:"20px",
                  borderRadius: '0',
                  border: '1px dashed #e0e0e0',
                },
              }}
              muiTableBodyProps={{
                sx: {
                  //stripe the rows, make odd rows a darker color
                  '& tr:nth-of-type(odd)': {
                    backgroundColor: '#f5f5f5',
                  },
                  paddingLeft:"10px",
                    
                  
                },
              }}
                muiTableHeadCellProps={{
                  //no useTheme hook needed, just use the `sx` prop with the theme callback
                  sx: (theme) => ({
                    color: theme.palette.text.secondary,
                    paddingLeft:"10px",
                    fontSize: {
                      xs: '10px',
                      sm: '11px',
                      md: '12px',
                      lg: '13px',
                      xl: '14px',
                    },
                  }),
                
                }}
                /></ThemeProvider>
              )}
    </div>
  );

  //  (
  //   // <KTCardBody className='py-4'>
  //   //   {rows.length > 0 ? (
  //   //   <>
  //   //     <div className='table-responsive'>
  //   //       <table
  //   //         id='kt_table_users'
  //   //         className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
  //   //         {...getTableProps()}
  //   //       >
  //   //         <thead>
  //   //           <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
  //   //             {headers.map((column: ColumnInstance<ArticleObject>) => (
  //   //               <CustomHeaderColumn key={column.id} column={column} />
  //   //             ))}
               
  //   //           </tr>
  //   //         </thead>
  //   //         <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
  //   //           {rows.length > 0 && (
  //   //             rows.map((row: Row<ArticleObject>, i) => {
  //   //               prepareRow(row)
  //   //               return <CustomRow row={row} key={`row-${i}-${row.id}`} />
  //   //             })
  //   //           )}
  //   //         </tbody>
  //   //       </table>
  //   //     </div>
  //   //     <ArticlePagination />
  //   //   </>
  //   //   ):(
  //   //   <>
  //   //     <div className='d-flex text-center w-100 align-content-center justify-content-center'>
  //   //       No matching records found
  //   //     </div>
  //   //   </>
  //   //   )}
  //   // </KTCardBody>
  //   <></>
  // )
}

export {ArticleTable}
