import { ActivityInitState } from "../../../Activity/activity-list/core/_initState";
import { RestaurantTypeProps } from "./_models";

export const RestaurantInitState : RestaurantTypeProps  =  {
    name: '',
    slug:'',
    tags: [],
    cuisine: [],
    music: [],
    vibes:[],
    titre:'',
    title:'',
    description:'',
    category:'',
    descriptionEN:'',
    facebookLink:'',
    instagramLink:'',
    youtubeLink:'',
    website:'',
    address: {
        "fullAddress": "",
        "country": "",
        "city": "",
        "street": "",
        "zipCode": ""
    },
    imagesList:[],
    meta_title:"",
    meta_titre:"",
    meta_description:"",
    meta_descriptionEN:"",
    seo_id:0,
    activityDTO: ActivityInitState,
    imageUrl:'',
    imageId:'',
    imageByte : undefined
}

export type RestaurantImageType ={
    imagesList: File[]
  }
export const  RestaurantImageInitState : RestaurantImageType = {
    imagesList: [] 
}




