import axios, { AxiosError, AxiosResponse } from "axios"
import { ContactType, DestinationType, FaqType, LogoType, SectionType, SeoType, SocialMediaType, SubSectionType,SelectedProp, ArticleType, ReviewType, ArticleDetailsType, SubSectionExperiences, LocationType } from "./_models"
import { ID } from "../../../../../_metronic/helpers"
import { Title } from "@mui/icons-material"
import { GuestyResponseTypeProps, ListingVente } from "../../listing-vente/core/_models"
import { ConciergeItemType, ConciergeServiceDetailsType, ConciergeServiceType, ConciergeType } from "../../Concierge/concierge-list/core/_models"
import { Toast } from "../../../../../_components/SweetAlerts/SweetAlert"


const API_URL = process.env.REACT_APP_API_URL

const GET_SEO_BY_TYPE = `${API_URL}/content/seo/type`
const UPDATE_SEO_BY_TYPE = `${API_URL}/content/seo/update`

const GET_SECTION_BY_TYPE = `${API_URL}/content/sections/Type`
const GET_SECTION = `${API_URL}/content/sections`
const UPDATE_SECTION_BY_TYPE = `${API_URL}/content/sections/update`


const GET_ALL_SUB_SECTION = `${API_URL}/content/subsections`
const GET_SUB_SECTION_BY_ID = `${API_URL}/content/subsections`
const GET_SUB_SECTION_BY_TYPE = `${API_URL}/content/subsections/BySectionType`
const GET_SUB_SUBSECTION_BY_NAME = `${API_URL}/content/subsections/BySubsectionName`
const ADD_SUB_SECTION_BY_TYPE = `${API_URL}/content/subsections/create`
const UPDATE_SUB_SECTION_BY_TYPE = `${API_URL}/content/subsections/update`
const DELETE_SUB_SECTION = `${API_URL}/content/subsections`

const GET_CONTACT = `${API_URL}/sitting/contactList`
const UPDATE_CONTACT = `${API_URL}/sitting/updateContact`

const GET_SOCIAL_MEDIA = `${API_URL}/sitting/socialMedia`
const UPDATE_SOCIAL_MEDIA = `${API_URL}/sitting/updateSocialMedia`

const GET_LOGO = `${API_URL}/sitting/logo`
const UPDATE_LOGO = `${API_URL}/sitting/update/logo`

const GET_DESTINATION_BY_TYPE = `${API_URL}/destinations/getByType`
const GET_DESTINATION_BY_REGION_TYPE = `${API_URL}/destinations/getByTypeAndSector`
const GET_DESTINATION_BY_ID = `${API_URL}/destinations`
const UPDATE_DESTINATION_BY_TYPE = `${API_URL}/destinations/update`
const ADD_DESTINATION_TYPE = `${API_URL}/destinations/create`
const DELETE_DESTINATION = `${API_URL}/destinations`
const GET_DESTINATION_NAME_SALE = `${API_URL}/sale/properties/address/cities`


const GET_DEFINED_DESTINATION_LIST = `${API_URL}/setting/destination_list/json`

const GET_ARTICLE_BY_TYPE = `${API_URL}/articles`
const GET_ARTICLE_BY_ID = `${API_URL}/articles`
const UPDATE_ARTICLE_BY_TYPE = `${API_URL}/articles/update`
const ADD_ARTICLE_TYPE = `${API_URL}/articles/create`
const DELETE_ARTICLE = `${API_URL}/articles`
const DELETE_IMAGES_ARTICLE = `${API_URL}/articles/images`
const DELETE_ARTICLE_DETAILS = `${API_URL}/articles/details`
const ADD_ARTICLE_IMAGES_URL =`${API_URL}/articles/images/create`
const ADD_SUBSECTION_IMAGES_URL =`${API_URL}/content/subsections/images/create`
const DELETE_IMAGES_SUBSECTION = `${API_URL}/content/subsections/images`
const CHECK_ARTICLE_NAME=`${API_URL}/articles/check-name-exist`
const UPDATE_ARTICLE_DETAILS_BY_TYPE=`${API_URL}/articles/details/update`
const CREATE_ARTICLE_DETAILS_BY_TYPE=`${API_URL}/articles/details/create`


const GET_REVIEW_BY_TYPE = `${API_URL}/reviews`
const GET_REVIEW_BY_ID = `${API_URL}/reviews`
const UPDATE_REVIEW_BY_TYPE = `${API_URL}/reviews/update`
const ADD_REVIEW_TYPE = `${API_URL}/reviews/create`
const DELETE_REVIEW = `${API_URL}/reviews`
const GET_REVIEW_NAME_SALE = `${API_URL}/sale/properties/address/cities`



const GET_FAQ_BY_ID = `${API_URL}/faq`
const GET_FAQ_BY_TYPE = `${API_URL}/faq`
const UPDATE_FAQ_BY_TYPE = `${API_URL}/faq/update`
const CREATE_FAQ_LOCATION = `${API_URL}/faq/locations/create`
const ADD_FAQ_TYPE = `${API_URL}/faq/create`
const DELETE_FAQ = `${API_URL}/faq`

const GET_FAQ_NAME_SALE = `${API_URL}/sale/properties/address/cities`

const DELETE_SELECTED_PROPERTIES=`${API_URL}/content/sections/selected-properties`

const GET_SALE_PROPERTY_BY_NAME = `${API_URL}/sale/properties/find`
const GET_RENT_PROPERTY_BY_NAME = `${API_URL}/guesty/listing/propertiesBySlug`

const ADD_SEO_ARTICLE = `${API_URL}/content/seo/create`


const GET_LIST_CONCIERGE = `${API_URL}/concierge`
const GET_CONCIERGE_BY_SECTOR = `${API_URL}/concierge/by-sector`
const GET_CONCIERGE_SERVICES = `${API_URL}/concierge/item/services`
const GET_CONCIERGE_ITEM = `${API_URL}/concierge/item`



const CHECK_CONCIERGE_SLUG=`${API_URL}/concierge/check-slug-exist`
const CHECK_CONCIERGE_SECTOR=`${API_URL}/concierge/check-sector-exist`
const CHECK_CONCIERGE_ITEM_CITY=`${API_URL}/concierge/item/check-city-exist`
const CHECK_CHECK_CONCIERGE_ITEM_SLUG=`${API_URL}/concierge/item/check-slug-exist`
const CHECK_CONCIERGE_ITEM_SERVICE_SLUG=`${API_URL}/concierge/item/services/check-slug-exist`
const CHECK_CONCIERGE_ITEM_SERVICE_NAME=`${API_URL}/concierge/item/services/check-name-exist`


const UPDATE_CONCIERGE=`${API_URL}/concierge/update`

const UPDATE_CONCIERGE_ITEM=`${API_URL}/concierge/item/update`
const UPDATE_CONCIERGE_SERVICES=`${API_URL}/concierge/item/services/update`

const GET_CONCIERGE_ITEM_BY_SECTOR = `${API_URL}/concierge/item/by-sector`

const ASSIGN_CONCIERGE_SERVICE_TO_ITEM=`${API_URL}/concierge/item/services/assignToConcierge`
const UNLINK_CONCIERGE_SERVICE_TO_ITEM=`${API_URL}/concierge/item/services/unlinkToConcierge`
const UNLINK_CONCIERGE_TO_ITEM=`${API_URL}/concierge/item/unlinkToConcierge`
const CREATE_CONCIERGE=`${API_URL}/concierge/create`
const CREATE_CONCIERGE_ITEM=`${API_URL}/concierge/item/create`


const GET_SERVICE_DETAILS = `${API_URL}/concierge/item/services/details`



const getSEOByType = (type: string | undefined): Promise<SeoType> => {
    return axios
      .get(`${GET_SEO_BY_TYPE}/${type}`)
      .then((d:AxiosResponse<SeoType>) =>  d.data)
}

const updateSEOByType = (body:SeoType) => {
    return axios
      .put(`${UPDATE_SEO_BY_TYPE}`,body)
      .then((d:AxiosResponse<SectionType>) =>  d.data)
}

const getSectionByType = (type: string ): Promise<SectionType> => {
  const data=axios
  .get(`${GET_SECTION_BY_TYPE}?sectionType=${type}`)
  .then((d:AxiosResponse<SectionType>) =>  d.data)
 
  return data;
}
// const getPropsBySectionByType = (type:string): Promise<SelectedProp> => {
//   return axios
//     .get(`${GET_SECTION_BY_TYPE}/selected-properties/section-type/${type}`)
//     .then((d:AxiosResponse<SelectedProp>) =>  d.data)
// }

const updateSectionByType = (body:FormData) => {
  return axios
    .patch<SectionType>(`${UPDATE_SECTION_BY_TYPE}`,body)
}

const getAllSubSection = (): Promise<Array<SubSectionType>> => {
  return axios
    .get(`${GET_ALL_SUB_SECTION}`)
    .then((d:AxiosResponse<Array<SubSectionType>>) =>  d.data)
}

const getSubSectionByType = (type:string): Promise<Array<SubSectionType>> => {
 
  return axios
    .get(`${GET_SUB_SECTION_BY_TYPE}?sectionType=${type}`)
    .then((d:AxiosResponse<Array<SubSectionType>>) =>  d.data)
}

export const getSubSectionByName = (type:string,name:string): Promise<Array<SubSectionType>> => {
 
  return axios
    .get(`${GET_SUB_SUBSECTION_BY_NAME}?sectionType=${type}&name=${name}`)
    .then((d:AxiosResponse<Array<SubSectionType>>) =>  d.data)
}
export const getFirstSubSectionByName = (type:string,name:string): Promise<SubSectionType> => {
 
  return axios
    .get(`${GET_SUB_SUBSECTION_BY_NAME}?sectionType=${type}&name=${name}`)
    .then((d:AxiosResponse<SubSectionType>) =>  d.data)
}
const getSubSectionById = (id:ID): Promise<SubSectionType> => {
  return axios
    .get(`${GET_SUB_SECTION_BY_ID}/${id}`)
    .then((d:AxiosResponse<SubSectionType>) => {  return d.data; })
}
const getExperiences= (id:ID): Promise<SubSectionExperiences> => {
  return axios
    .get(`${GET_SUB_SECTION_BY_ID}/${id}`)
    .then((d:AxiosResponse<SubSectionExperiences>) => {  return d.data; })
}

const addSubSectionByType= (body:FormData,type:string) => {
  return axios
    .post(`${ADD_SUB_SECTION_BY_TYPE}/${type}`,body)
    .then((d) =>  d.data)
}
 
const updateSubSectionByType = (body: FormData) => {
  return axios.patch(`${UPDATE_SUB_SECTION_BY_TYPE}`, body).then((response) => response.data);
};


const updateSubSectionByTypeWithKeys= (body:FormData,type:string) => {
  console.log(type,`${UPDATE_SUB_SECTION_BY_TYPE}/${type}/${body.get('key')}`,body)
  return axios
    .patch(`${UPDATE_SUB_SECTION_BY_TYPE}/${type}/${body.get('key')}`,body)
    .then((d) =>  d.data)
}

const addSubSectionKeyByType= (body:FormData,type:string) => {
console.log("Explore",)
  console.log(type,`${UPDATE_SUB_SECTION_BY_TYPE}/${type}/${body.get('key')}`,body)
  return axios
    .post(`${ADD_SUB_SECTION_BY_TYPE}/${type}/${body.get('key')}`,body)
    .then((d) =>  d.data)
}

const deleteSubSection = (id:ID) => {
  return axios
    .delete(`${DELETE_SUB_SECTION}/${id}`)
    .then((d) =>  d.data)
}

const getContact = (): Promise<ContactType> => {
  return axios
    .get(`${GET_CONTACT}`)
    .then((d:AxiosResponse<ContactType>) =>  d.data)
}
const getSocialMedia = (): Promise<SocialMediaType> => {
  return axios
    .get(`${GET_SOCIAL_MEDIA}`)
    .then((d:AxiosResponse<SocialMediaType>) =>  d.data)
}

const updateContact= (body:ContactType) => {
  return axios
    .patch(`${UPDATE_CONTACT}`,body)
}
const updateSocialMedia= (body:SocialMediaType) => {
  return axios
    .patch(`${UPDATE_SOCIAL_MEDIA}`,body)
}

const updateLogo= (body:FormData) => {
  return axios
    .patch(`${UPDATE_LOGO}`,body)
}

const getLogo = (): Promise<LogoType> => {
  return axios
    .get(`${GET_LOGO}`)
    .then((d:AxiosResponse<LogoType>) =>  d.data)
}

const getDestinationByType = (type:string): Promise<Array<DestinationType>> => {
  return axios
    .get(`${GET_DESTINATION_BY_TYPE}?type=${type}`)
    .then((d:AxiosResponse<Array<DestinationType>>) =>  d.data)
}
export const getDestinationSEO = (region:string='',type:string): Promise<Array<DestinationType>>=>{
  return  axios
  .get(GET_DESTINATION_BY_REGION_TYPE+"?region="+region+"&type="+type)
  .then((d:AxiosResponse<Array<DestinationType>>) => d.data)
}

const getArticles = (): Promise<Array<ArticleType>> => {
  return axios
    .get(`${GET_ARTICLE_BY_TYPE}`)
    .then((d:AxiosResponse<Array<ArticleType>>) =>  d.data)
}



const getReviewByType = (): Promise<Array<ReviewType>> => {
  return axios
    .get(`${GET_REVIEW_BY_TYPE}`)
    .then((d:AxiosResponse<Array<ReviewType>>) =>  d.data)
}


const getDestinationSaleName = (): Promise<Array<String>> => {
  return axios
    .get(`${GET_DESTINATION_NAME_SALE}`)
    .then((d:AxiosResponse<Array<String>>) =>  d.data)
}



const getReviewSaleName = (): Promise<Array<String>> => {
  return axios
    .get(`${GET_REVIEW_NAME_SALE}`)
    .then((d:AxiosResponse<Array<String>>) =>  d.data)
}

const updateDestination = (body: DestinationType) => {
  return axios.put<DestinationType>(`${UPDATE_DESTINATION_BY_TYPE}`, body)
  .then(response => {
    return response.data; // Return the response data on success
  })
  .catch((error: AxiosError) => {
    throw error; // Throw the error instead of returning it
  });
}

const updateArticle = (body:FormData) => {
  return axios
    .patch<ArticleType>(`${UPDATE_ARTICLE_BY_TYPE}`,body)
}
const updateArticleDetails = (body:ArticleDetailsType,id:ID) => {
  
  return axios
    .patch<ArticleDetailsType>(`${UPDATE_ARTICLE_DETAILS_BY_TYPE}/${id}`,body)
}
const createArticleDetails = (body:ArticleDetailsType,id:ID) => {

  return axios
    .post<ArticleDetailsType>(`${CREATE_ARTICLE_DETAILS_BY_TYPE}/${id}`,body)
}

const addSEOArticle = (body:any) => {
  return axios
    .post(`${ADD_SEO_ARTICLE}`,body,{
      headers: {
        "Content-Type": "application/json",
      },
    })
}
const updateReview = (body:ReviewType) => {
  return axios
    .put<ReviewType>(`${UPDATE_REVIEW_BY_TYPE}`,body)
}


const addDestination = (body: DestinationType) => {
  return axios.post<DestinationType>(`${ADD_DESTINATION_TYPE}`, body)
  .then(response => {
    return response.data; // Return the response data on success
  })
  .catch((error: AxiosError) => {
    throw error; // Throw the error instead of returning it
  });
}


const addArticle = (formData:FormData) => {
  return axios
    .post(`${ADD_ARTICLE_TYPE}`,formData)
}


const addReview = (body:ReviewType) => {
  return axios
    .post(`${ADD_REVIEW_TYPE}`,body)
}

const getDestinationById = (id:ID): Promise<DestinationType> => {
  return axios
    .get(`${GET_DESTINATION_BY_ID}/${id}`)
    .then((d:AxiosResponse<DestinationType>) =>  d.data)
}


const getArticleByName = (name:string): Promise<ArticleType> => {
    return axios
    .get(`${GET_ARTICLE_BY_ID}/${name}`)
    .then((d:AxiosResponse<ArticleType>) =>  d.data)
   

}

const getArticleByID = (id:ID): Promise<ArticleType> => {
  var res=axios .get(`${GET_ARTICLE_BY_ID}/?id=${id}`)
    .then((d:AxiosResponse<ArticleType>) =>  d.data)
  return res;

}
const getReviewById = (id:ID): Promise<ReviewType> => {
  return axios
    .get(`${GET_REVIEW_BY_ID}/${id}`)
    .then((d:AxiosResponse<ReviewType>) =>  d.data)
}

const deleteDestination = (id:ID) => {
  return axios
    .delete(`${DELETE_DESTINATION}/${id}`)
    .then((d) =>  d.data)
}

const addImageArticle = (id:ID,body:FormData)=>{
  return  axios
  .post(`${ADD_ARTICLE_IMAGES_URL}/${id}`,body,{
    headers:{
      "Content-Type": "multipart/form-data",
    }
  })
  .then((response) => response.data)
}
const addImagesSubsection = (id:ID,body:FormData)=>{
  return  axios
  .post(`${ADD_SUBSECTION_IMAGES_URL}/${id}`,body,{
    headers:{
      "Content-Type": "multipart/form-data",
    }
  })
  .then((response) => response.data)
}
const deleteImageSubsection = (id:ID) => {
  return axios
    .delete(`${DELETE_IMAGES_SUBSECTION}?id=${id}`)
    .then((d) =>  d.data)
}
const deleteArticle = (id:ID) => {
  return axios
    .delete(`${DELETE_ARTICLE}/${id}`)
    .then((d) =>  d.data)
}
const deleteImageArticle = (id:ID) => {
  return axios
    .delete(`${DELETE_IMAGES_ARTICLE}?id=${id}`)
    .then((d) =>  d.data)
}
const deleteArticleDetails = (id:ID) => {
  return axios
    .delete(`${DELETE_ARTICLE_DETAILS}/${id}`)
    .then((d) =>  d.data)
}


const checkArticleNameIfExist = (name:string) => {
  return axios
    .get(`${CHECK_ARTICLE_NAME}?name=${name}`)
    .then((d) =>  d.data)
}
const deleteReview = (id:ID) => {
  return axios
    .delete(`${DELETE_REVIEW}/${id}`)
    .then((d) =>  d.data)
}

const getFaqById = (id:ID): Promise<FaqType> => {
  return axios
    .get(`${GET_FAQ_BY_ID}/${id}`)
    .then((d:AxiosResponse<FaqType>) =>  d.data)
}


const addFaq = (body:FaqType) => {
  return axios
    .post(`${ADD_FAQ_TYPE}`,body)
}

const updateFaq = (body:FaqType) => {
  return axios
    .put<FaqType>(`${UPDATE_FAQ_BY_TYPE}`,body)
}
export const addFAQLocation = (body:{
  "name": string,
   "parent": (LocationType | null)
}) => {
  return axios
    .post<LocationType>(`${CREATE_FAQ_LOCATION}`,body)
}


const deleteFaq = (id:ID) => {
  return axios
    .delete(`${DELETE_FAQ}/${id}`)
    .then((d) =>  d.data)
}


const getFaqSaleName = (): Promise<Array<String>> => {
  return axios
    .get(`${GET_FAQ_NAME_SALE}`)
    .then((d:AxiosResponse<Array<String>>) =>  d.data)
}


const getFaqByType = (type:string): Promise<Array<FaqType>> => {
  return axios
    .get(`${GET_FAQ_BY_TYPE}?type=${type}`)
    .then((d:AxiosResponse<Array<FaqType>>) =>  d.data)
}


const getSelectedProperties = (type:string): Promise<Array<SelectedProp>> => {
  return axios
    .get(`${GET_SECTION}/selected-properties/section-type/${type}`)
    .then((d:AxiosResponse<Array<SelectedProp>>) =>  d.data)
}
const addSelectedProperties = (body:SelectedProp,type:string) => {
  return axios
    .post(`${GET_SECTION}/selected-properties/create/${type}`,body)
}

const deleteSelectedProp = (id:ID) => {
  return axios
    .delete(`${DELETE_SELECTED_PROPERTIES}/${id}`)
    .then((d) =>  d.data)
}
const getSalePropByTitle = async (nickname: string): Promise<Array<ListingVente> | any> => {
  try {
    const response = await axios.get( `${GET_SALE_PROPERTY_BY_NAME}/${nickname}`);
  
    return response.data;
  } catch (error:any) {
    if (error.response && error.response.status === 404 && error.response.status === 400) {
      // Return a custom error response when 404 is encountered
      return { error: error.message};
    } else {
      
      // Re-throw other errors for further handling
      return error.message;
    }
  }
}
const getRentPropByTitle = async (nickname: string): Promise<Array<GuestyResponseTypeProps> | any> => {
  try {
    const response = await axios.get( `${GET_RENT_PROPERTY_BY_NAME}/${nickname}?page=0`);
    return response.data;
  } catch (error:any) {
    if (error.response && error.response.status === 404 && error.response.status === 400) {
      // Return a custom error response when 404 is encountered
      return { error: error.message};
    } else {
      
      // Re-throw other errors for further handling
      return error.message;
    }
  }

}

 const fetchLocations = async () => {
  try {
    const response = await axios.get(`${GET_FAQ_BY_ID}/locations`); // Replace with your actual API endpoint
    return response.data;
  } catch (error) {
    // Handle error (e.g., log it, show a message)
    console.error('Error fetching locations:', error);
    throw error;
  }
};


// const getSalePropByTitle = (nickname:string): Promise<Array<ListingVente>> => {
//   // return axios
//   //   //  .get(`${GET_SALE_PROPERTY_BY_NAME}/${nickname}`)
//   //    .get(`http://localhost:8080/api/v1/sale/properties/find/${nickname}`)
//   //    .then((d:AxiosResponse<Array<ListingVente>> ) =>  d.data)
//      try {
//       const response =  axios.get(`http://localhost:8080/api/v1/sale/properties/find/${nickname}`) 
//       return (d:AxiosResponse<Array<SelectedProp>> => d.data);
//     } catch (error:any) {
//       if (error.response && error.response.status === 404) {
//         // Handle the 404 error here
//         return error.response;
//       } else {
//         // Handle other errors here, e.g., network issues
//         throw error; // Rethrow the error for further handling
//       }
//     }
// }

// Concierge

export const getListConcierge = (): Promise<Array<ConciergeType>> => {
  return axios
    .get(`${GET_LIST_CONCIERGE}`)
    .then((d:AxiosResponse<Array<ConciergeType>>) =>  d.data)
}
export const getListConciergeItem = (): Promise<Array<ConciergeType>> => {
  return axios
    .get(`${GET_CONCIERGE_ITEM}`)
    .then((d:AxiosResponse<Array<ConciergeType>>) =>  d.data)
}
export const getConciergeItemByCity = (city:ID): Promise<ConciergeItemType> => {
  return axios
  .get(`${GET_LIST_CONCIERGE}/item/by-city/`+city)
    .then((d:AxiosResponse<ConciergeItemType>) =>  d.data)
}


export const getConciergeServiceBySlug = (slug:string): Promise<ConciergeServiceType> => {
  return axios
  .get(`${GET_CONCIERGE_SERVICES}/get-by-slug/`+slug)
    .then((d:AxiosResponse<ConciergeServiceType>) =>  d.data)
}


export const deleteConcierge= (id:ID) => {
  return axios
    .delete(`${GET_LIST_CONCIERGE}/${id}`)
    .then((d) =>  d.data)
}
export const deleteConciergeItem= (id:ID) => {
  return axios
    .delete(`${GET_CONCIERGE_ITEM}/${id}`)
    .then((d) =>  d.data)
}
export const deleteConciergeService= (id:ID) => {
  return axios
    .delete(`${GET_CONCIERGE_SERVICES}/${id}`)
    .then((d) =>  d.data)
}

export const addSEOConcierge = (body:any) => {
  return axios
    .post(`${ADD_SEO_ARTICLE}`,body,{
      headers: {
        "Content-Type": "application/json",
      },
    })
}

export const getConciergeBySector = async (sector: string): Promise<Array<ConciergeType> | any> => {
  try {
    const response = await axios.get( `${GET_CONCIERGE_BY_SECTOR}/${sector}`);
  
    return response.data;
  } catch (error:any) {
    if (error.response && error.response.status === 404 && error.response.status === 400) {
      // Return a custom error response when 404 is encountered
      return { error: error.message};
    } else {
      
      // Re-throw other errors for further handling
      return error.message;
    }
  }
}
export const deleteConciergeServicesDetails = (id:ID) => {
  return axios
    .delete(`${GET_SERVICE_DETAILS}/${id}`)
    .then((d) =>  d.data)
}

export const GetConciergeServicesDetails = async (slug: string): Promise<Array<ConciergeServiceDetailsType> | any> => {
  try {
    const response = await axios.get( `${GET_SERVICE_DETAILS}/concierge-service-slug/${slug}`);
  
    return response.data;
  } catch (error:any) {
    if (error.response && error.response.status === 404 && error.response.status === 400) {
      // Return a custom error response when 404 is encountered
      return { error: error.message};
    } else {
      
      // Re-throw other errors for further handling
      return error.message;
    }
  }
}
export const updateConciergeServicesDetails = (formData:FormData,id:ID) => {
  
  return axios
    .patch<ConciergeServiceDetailsType>(`${GET_SERVICE_DETAILS}/update/${id}`,formData)
}


export const createConciergeServicesDetails = (formData:FormData,id:ID) => {
  return axios
  .post<ConciergeServiceDetailsType>(`${GET_SERVICE_DETAILS}/create/${id}`,formData)
}

export const getConciergeServices = async (): Promise<Array<ConciergeServiceType> | any> => {
  try {
    const response = await axios.get( `${GET_CONCIERGE_SERVICES}`);
  
    return response.data;
  } catch (error:any) {
    if (error.response && error.response.status === 404 && error.response.status === 400) {
      // Return a custom error response when 404 is encountered
      return { error: error.message};
    } else {
      
      // Re-throw other errors for further handling
      return error.message;
    }
  }
}
export const getConciergeItemBySector = async (sector: string): Promise<Array<ConciergeItemType> | any> => {
  try {
    const response = await axios.get( `${GET_CONCIERGE_ITEM_BY_SECTOR}/${sector}`);
  
    return response.data;
  } catch (error:any) {
    if (error.response && error.response.status === 404 && error.response.status === 400) {
      // Return a custom error response when 404 is encountered
      return { error: error.message};
    } else {
      
      // Re-throw other errors for further handling
      return error.message;
    }
  }
}

export const checkConciergerieSlugIfExist = (slug:string) => {
  return axios
    .get(`${CHECK_CONCIERGE_SLUG}?slug=${slug}`)
    .then((d) =>  d.data)
}
export const checkConciergerieSectorIfExist = (sector:string) => {
  return axios
    .get(`${CHECK_CONCIERGE_SECTOR}?sector=${sector}`)
    .then((d) =>  d.data)
}

export const checkConciergerieServiceNameIfExist = (slug:string) => {
  return axios
    .get(`${CHECK_CONCIERGE_ITEM_SERVICE_NAME}?name=${slug}`)
    .then((d) =>  d.data)
}
export const checkConciergerieItemServiceIfExist = (city:string) => {
  return axios
    .get(`${CHECK_CONCIERGE_ITEM_CITY}?city=${city}`)
    .then((d) =>  d.data)
}
export const checkConciergerieServiceSlugIfExist = (slug:string) => {
  return axios
    .get(`${CHECK_CONCIERGE_ITEM_SERVICE_SLUG}?slug=${slug}`)
    .then((d) =>  d.data)
}
export const checkConciergerieItemSlugIfExist = (slug:string) => {
  return axios
    .get(`${CHECK_CHECK_CONCIERGE_ITEM_SLUG}?slug=${slug}`)
    .then((d) =>  d.data)
}

export const createConcierge = (formData:FormData) => {
  formData.delete('id');
  return axios
    .post(`${CREATE_CONCIERGE}`,formData)
}
export const createConciergeItem = (formData:FormData) => {
 
  return axios
    .post(`${CREATE_CONCIERGE_ITEM}`,formData)
}

export const updateConcierge = (formData:FormData) => {
  return axios
    .put(`${UPDATE_CONCIERGE}`,formData)
}

export const updateItemConcierge = (formData:FormData) => {
  return axios
    .put(`${UPDATE_CONCIERGE_ITEM}`,formData)
}
export const updateServiceConcierge = (formData:FormData,conciergeItemIds:[]) => {
  return axios
    .put(`${UPDATE_CONCIERGE_SERVICES}?conciergeItemIds=${conciergeItemIds}`,formData)
}
export const assignServiceConciergeToItem  =  (formData:FormData,id:ID) => {
  return axios
    .post(`${ASSIGN_CONCIERGE_SERVICE_TO_ITEM}/${id}`,formData)
    .then((response) => response.data)
}
export const unlinkServiceToConcierge  =  (idService:ID,idConcierge:ID) => {
  return axios
    .post(`${UNLINK_CONCIERGE_SERVICE_TO_ITEM}/${idService}/${idConcierge}`)
    .then((response) => response.data)
}
export const assignItemtoConcierge =  (formData:FormData,id:ID) => {
  formData.set("id","")
  return axios
    .post(`${CREATE_CONCIERGE_ITEM}/${id}`,formData)
    .then((response) => response.data)
}
export const unlinkItemToConcierge  =  (idItem:ID,idConcierge:ID) => {
  return axios
    .post(`${UNLINK_CONCIERGE_TO_ITEM}/${idItem}/${idConcierge}`)
    .then((response) => response.data)
}

const getDestinationList= (): Promise<JSON> => {
  return axios
    .get(`${GET_DEFINED_DESTINATION_LIST}`)
    .then((d:AxiosResponse<JSON>) =>  d.data)
}

export const destinationSetCanonical = async(id:string,isCanonical:Boolean)=>{
  return  axios
  .put(GET_DESTINATION_BY_ID + '/setCanonical/' + id+"?isCanonical="+isCanonical)
  .then((response) => response.data)
}

export const destinationSetIndex = async(id:string,isCanonical:Boolean)=>{
  return  axios
  .put(GET_DESTINATION_BY_ID + '/setIndex/' + id+"?index="+isCanonical)
  .then((response) => response.data)
}


export {
    getSEOByType,
    getFaqByType,
    updateSEOByType,
    getSectionByType,
    // getPropsBySectionByType,
    getFaqSaleName,
    updateSectionByType,
    getSubSectionByType,
    deleteArticle,
    updateSubSectionByType,
    getAllSubSection,
    deleteSubSection,
    getSubSectionById,
    addSubSectionByType,
    getArticles,
    updateArticle,
    addArticle,
    deleteReview,
    getReviewByType,
    updateReview,
    addReview,
    getContact,
    updateContact,
    getSocialMedia,
    updateSocialMedia,
    getLogo,
    getArticleByName,
    getArticleByID,
    // getArticleSaleName,
    getReviewById,
    getReviewSaleName,
    updateLogo,
    getDestinationByType,
    updateDestination,
    addDestination,
    getDestinationById,
    deleteDestination,
    getFaqById,
    deleteFaq,
    updateFaq,addFaq,
    getDestinationSaleName,
    updateSubSectionByTypeWithKeys,
    addSubSectionKeyByType,
    getSelectedProperties,
    deleteSelectedProp,
    addSelectedProperties,
    getSalePropByTitle,
    getRentPropByTitle,
    deleteImageArticle,
    addImageArticle,
    updateArticleDetails,
    deleteArticleDetails,
    addSEOArticle,
    checkArticleNameIfExist,
    createArticleDetails,
    getExperiences,
    fetchLocations,
    addImagesSubsection,
    deleteImageSubsection 

}
  