import * as Yup from 'yup'

export const resetPasswordSchema = Yup.object().shape({
    email: Yup.string().required('email is required'),
    password: Yup.string()
        .min(8, 'Password must be at least 6 characters')
        .required('password is required'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required'),


})