import {Route, Routes} from 'react-router-dom'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {AuthLayout} from './AuthLayout'
import ResetPasswordWarrper from './components/ResetPassword/ResetPasswordWarrper'
import VerifyAccountWarrper from './components/ChangePassword/VerifyAccountWarrper'

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='reset-password' element={<ResetPasswordWarrper />} />
      <Route path='verify-account' element={<VerifyAccountWarrper />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
