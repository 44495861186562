import {useMemo} from 'react'
import { DestinationType } from '../../../core/_models'
import { MaterialReactTable } from 'material-react-table';
import { KTSVG } from '../../../../../../../_metronic/helpers'
import { MRT_ColumnDef } from 'material-react-table';
import { Link } from 'react-router-dom'
import { createTheme, ThemeProvider, useTheme } from '@mui/material';
import { useListView } from '../../../core/ListViewProvider'
import { IsCanonicalCell } from './IsCanonicalCell';
import { IsIndexCell } from './IsIndexCell';

type LocationDestinationTableProps = {
  destination:Array<DestinationType>
 
}

const LocationDestinationTable:React.FC<LocationDestinationTableProps> = ({destination}) => {
  
  const data = useMemo(() => destination, [destination])
  // const columns = useMemo(() => FaqColumns, [])
  // const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
  //   columns,
  //   data,
  // })

  const globalTheme = useTheme();
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.secondary, //swap in the secondary color as the primary for the table
          info: {
            main: '#fff', //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === 'light'
                ? '#fff' //random light yellow color for the background in light mode
                : '#000', //pure black table in dark mode for fun
          },
          
        },
        typography: {
          button: {
            textTransform: 'none', //customize typography styles for all buttons in table by default
            fontSize: '1.2rem',
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: '1.1rem', //override to make tooltip font size larger
              },
            },
          },
          MuiSwitch: {
            styleOverrides: {
              thumb: {
                color: '#222', //change the color of the switch thumb in the columns show/hide menu to pink
              },
            },
          },
        },
      }),
    [globalTheme],
  );
  

  const {setItemIdForDelete,setItemIdForUpdate} = useListView()
  // const {setItemIdFordelete} = useListingView()
  // const openDeleteModal = (id:any) => {
  //   setItemIdFordelete(id);
  // }
var openDeleteModal:any;
var openEditModal:any;
if(setItemIdForDelete)
   openDeleteModal = (id:number) => {
    setItemIdForDelete(id)
  }
   openEditModal = (id:number) => {
    setItemIdForUpdate(id)
  }

// Define your column definitions using MRT_ColumnDef<Restaurant>
const locationColumns: MRT_ColumnDef<DestinationType>[] = [
  {
    accessorKey: 'id', //access nested data with dot notation
        header: 'ID',
        size: 10,
  },
  {

    accessorKey: 'type',
        header: 'Type',
        size: 10,
  },
  {
    accessorKey: 'region', //normal accessorKey
        header: 'Region',
        size: 40,
  },
  {
    accessorKey: 'destination',
        header: 'Destination',
        size: 30,
  },
  {
    accessorKey: 'isCanonical',
    header: 'Is canonical',
    size: 30,
    Cell: ({ cell }) =>  (
      <div className="d-flex align-items-center">
        <IsCanonicalCell listing={cell.row.original} />
       </div>
    ),
   
  },
  {
    accessorKey: 'index',
    header: 'Index',
    size: 30,
    Cell: ({ cell }) =>  (
      <div className="d-flex align-items-center">
        <IsIndexCell listing={cell.row.original} />
       </div>
    ),
   
  },
  {
    accessorKey: 'metaTitle',
        header: 'Meta-title FR',
        size: 40,
  },
  {
    header: 'Actions',
    accessorKey: 'id', // Assuming 'id' is your unique identifier
    size: 100,
    Cell: ({ cell }) =>  (
      <div>
        
        <a
           onClick={() => openEditModal(cell.row.original.id)}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2'
        >
          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
        </a>
        <a
          onClick={() => openDeleteModal(cell.row.original.id)} 
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen027.svg'
            className='svg-icon-3'
          />
        </a>
      </div>
    ),
  }

  
  // Add more columns as needed...
];
const _ = require("lodash");

 
  destination = _.orderBy(destination, ["name"], ["asc"]);



  return (
    <div className='py-4'>
      {destination.length > 0 ? (
      <>
      <div className='table-responsive'>
        <ThemeProvider theme={tableTheme}>
             <MaterialReactTable columns={locationColumns} data={destination} 
              // enableRowOrdering
              muiTablePaperProps={{
                elevation: 0, //change the mui box shadow
                //customize paper styles
                sx: {
                  paddingLeft:"20px",
                  paddingRight:"20px",
                  borderRadius: '0',
                  border: '1px dashed #e0e0e0',
                },
              }}
              muiTableBodyProps={{
                sx: {
                  //stripe the rows, make odd rows a darker color
                  '& tr:nth-of-type(odd)': {
                    backgroundColor: '#f5f5f5',
                  },
                  paddingLeft:"10px",
                    
                  
                },
              }}
                muiTableHeadCellProps={{
                  //no useTheme hook needed, just use the `sx` prop with the theme callback
                  sx: (theme) => ({
                    color: theme.palette.text.secondary,
                    paddingLeft:"10px",
                    fontSize: {
                      xs: '10px',
                      sm: '11px',
                      md: '12px',
                      lg: '13px',
                      xl: '14px',
                    },
                  }),
                
                }}
                /></ThemeProvider>
        </div>
      </>
      ):(
      <>
        <div className='d-flex text-center w-100 align-content-center justify-content-center'>
          No matching records found
        </div>
      </>
      )}
    </div>
  )
}

export {LocationDestinationTable}
