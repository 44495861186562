import {AppResponse, ID} from '../../../../../../_metronic/helpers'
import qs from 'qs'
import { ActivityObject } from '../../../Activity/activity-list/core/_models'
  export type RestaurantObject = {
    id: ID
    name: string
    slug:string,
    tags: Array<string>,
    cuisine: Array<string>,
    music: Array<string>,
    vibes: Array<string>,
    titre:string,
    title:string,
    description: string
    descriptionEN: string
    category:string
    phone:string
    facebookLink:string,
    instagramLink:string,
    youtubeLink:string,
    website:string,
    typeInformation?:string
    typeActivity?:string
    address: Address
    imagesList: Array<Image>
    imageUrl:string
    imageId:string
    imageByte:File|undefined
    activityDTO?:ActivityObject
  }

export type Restaurant = {
  id?: ID,
  name: string,
 slug:string,
  tags: Array<string>,
  cuisine: Array<string>,
  music: Array<string>,
  vibes: Array<string>,
  titre:string,
  title:string,
  description:string,
  descriptionEN:string,
  category:string,
  facebookLink:string,
  instagramLink:string,
  youtubeLink:string,
  website:string,
  address: AddressType
  imagesList?: Array<Image>

  typeInformation?:string
  typeActivity?:string
  imageUrl:string
  imageId:string
  imageByte:File|undefined
  activityDTO?:ActivityObject
}
export type RestaurantTypeProps = {
  id?: ID,
  name: string, 
  slug:string,
  tags: Array<string>,
  cuisine:Array<string>,
  music: Array<string>,
  vibes: Array<string>,
  titre:string,
  title:string,
  description:string,
  descriptionEN:string,
  category:string,
  youtubeLink:string,
  facebookLink:string,
  instagramLink:string,
  website:string,

  typeInformation?:string
  typeActivity?:string
  address: AddressType
  imagesList?: Array<File>
  meta_title:string,
  meta_titre:string,
  meta_descriptionEN:string,
  meta_description:string,
  seo_id:number
  imageUrl:string
  imageId:string
  imageByte:File|undefined
  activityDTO:ActivityObject
}
export type AddressType = {
  id?: ID
  street: string
  fullAddress: string
  city: string
  zipCode: string
  country: string
  latitude?:number
  longitude?:number
}
export type RestaurantResponseTypeProps = {
  seo_id: ID
  id: ID
  name: '',
  slug:'',
  tags: [],
  cuisine: [],
  music: [],
  vibes:[],
  titre:'',
  title:'',
  description:'',
  descriptionEN:'',
  category:'',
  facebookLink:'',
  instagramLink:'',
  youtubeLink:string,
  website:'',
  phone:'',
  address: AddressType
  imageUrl:''
  imageId:''
  imageByte:File|undefined
  imagesList?: Array<ImageResponse>
  meta_descriptionEN?:string
  meta_description?:string
  meta_title?:string
  meta_titre?:string
  typeInformation?:string
  typeActivity?:string
  activityDTO:ActivityObject
  
}


export type Address = {
  id: number
  street?: string
  fullAddress: string
  city?: string
  zipCode?: string
  country?: string
  longitude?: string
  latitude?: string
}
export type Image = {
  id: number
  alt: string
  image: string
}
export type ImageResponse = {
  id: number
  imageType: string
  imageUrl: string
  imageId:string
  alt:string
}



export type ResponseCategories=Response<Array<string>>
// export type RestaurantQueryResponse = Response<Array<Restaurant>>
export type RestaurantQueryResponse = AppResponse<Restaurant[]>;

export type ResponseRestaurant=Response<Array<Restaurant>>
export type Response<T> = {
  data:T
  totalPages?: number
  totalElements?: number
  last?: boolean
  size?:number
  number?:number
  sort?: {
      "empty": true,
      "unsorted": true,
      "sorted": false
  },
  numberOfElements?:number
  first?: boolean
  empty?: boolean
}
export type Content<T> = {
  content:Array<T>
}



export type QueryState = PaginationState

export type PaginationState = {
  page?: number
  size: number
  totalPages?: number 
  totalElements?: number
  pageable?: pageable
  first?:boolean
  last?:number
}

export type pageable = {
  offset: number
  pageNumber: number
  pageSize: number
  paged: boolean
  sort: any
  unpaged: boolean
}

export const initialQueryState: QueryState = {
  page: 0,
  size: 4,
}

export function stringifyRequestQuery(state: QueryState): string {
  const pagination = qs.stringify(state, {filter: ['page', 'size'], skipNulls: true})

  return [pagination]
    .filter((f) => f)
    .join('&')
    .toLowerCase()
}