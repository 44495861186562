import React, { useState } from 'react';
import { useField } from 'formik';

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css' // import the styles

type HandelTextEditorProps = {
  label?: string;
  name: string;
  type?:string
  placeholder?:string
}

const HandelTextEditor: React.FC<HandelTextEditorProps> = ({ label, name,placeholder }) => {
  const [field, meta,helpers] = useField(name)
  const [modules,setModules] = useState({
    toolbar: [
        [{ 'font': [] }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline'],
        [{'list': 'ordered'}, {'list': 'bullet'}],
        [{ 'align': [] }],
        [{ 'color': [] }, { 'background': [] }],
        ['clean']
      ]
  })

  const [formats] = useState([
    'font',
    'size',
    'bold', 'italic', 'underline',
    'list', 'bullet',
    'align',
    'color', 'background'
  ])

  const handleQuillChange = (value:string) => {
    helpers.setValue(value)
  }

  return (
    <div className="mb-10">
      {label && (<label className="form-label required">{label}</label>)}
        <ReactQuill
          id={name}
          theme="snow"
          modules={modules}
          formats={formats}
          value={field.value}
          onChange={(value)=>handleQuillChange(value)}
        />
        {meta.touched && meta.error ? (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>{meta.error}</div>
          </div>
        ) : null}
    </div>
  );
};

export default HandelTextEditor;