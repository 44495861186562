import { UserRoleModel } from "../../app/modules/auth";

export const isAdmin = (arrRole: Array<UserRoleModel>) =>{
    const admin = arrRole.filter(role => role.name === 'SUPER_ADMIN')
    if(admin.length > 0){
        return true
    }
    return false
} 

export const isAdminListingVente = (arrRole: Array<UserRoleModel>) =>{
    const admin = arrRole.filter(role => role.name === 'LISTING_SALE_ADMIN')
    if(admin.length > 0){
        return true
    }
    return false
}

export const isAdminWebSite = (arrRole: Array<UserRoleModel>) =>{
    const admin = arrRole.filter(role => role.name === 'WEBSITE_ADMIN')
    if(admin.length > 0){
        return true
    }
    return false
} 

export const isAccouantAdmin = (arrRole: Array<UserRoleModel>) =>{
    const admin = arrRole.filter(role => role.name === 'ACCOUNT_ADMIN')
    if(admin.length > 0){
        return true
    }
    return false
} 

