import React from 'react'
import VenteFormDestination from '../components/VenteFormDestination/VenteFormDestination'

const VenteDestination: React.FC = () => {

  return (
    <>
      <VenteFormDestination />
    </>
  )
}

export {VenteDestination}
