import {useEffect, useState} from 'react'
import qs from 'qs'
import {QueryState} from './models'


function isNotEmpty(obj: unknown) {
  return obj !== undefined && obj !== null && obj !== ''
}

// Example: page=1&items_per_page=10&sort=id&order=desc&search=a&filter_name=a&filter_online=false
function stringifyRequestQuery(state: QueryState): string {
  const pagination = qs.stringify(state, {filter: ['page', 'items_per_page'], skipNulls: true})
  const sort = qs.stringify(state, {filter: ['sort', 'order'], skipNulls: true})
  const search = isNotEmpty(state.search)
    ? qs.stringify(state, {filter: ['search'], skipNulls: true})
    : ''

  const filter = state.filter
    ? Object.entries(state.filter as Object)
        .filter((obj) => isNotEmpty(obj[1]))
        .map((obj) => {
          return `filter_${obj[0]}=${obj[1]}`
        })
        .join('&')
    : ''

  return [pagination, sort, search, filter]
    .filter((f) => f)
    .join('&')
    .toLowerCase()
}

// Hook
function useDebounce(value: string | undefined, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay] // Only re-call effect if value or delay changes
  )
  return debouncedValue
}

export type AppResponse<T> = {
  content:T
  totalPages?: number
  totalElements?: number
  last?: boolean
  size?:number
  number?:number
  sort?: {
      "empty": true,
      "unsorted": true,
      "sorted": false
  },
  numberOfElements?:number
  first?: boolean
  empty?: boolean
  pageable:{
    offset: number
    pageNumber:number
    pageSize:number
    paged :boolean
  }
}

export type GuestyResponse<T> = {
  results?: Array<T>
  skip: number
  limit: number
  count:number
  fields?: string
}
 
export type ListContextProps<T> = {
  response: T | undefined
  refetch: () => void
  isLoading: boolean
  query: string
  isError:boolean
  error:unknown
}

export const initListContextPropsState = {
  response:undefined,
  refetch: () => {}, 
  isLoading: false,
  query: '',
  isError:false,
  error:""
}

export {
  stringifyRequestQuery,
  useDebounce,
  isNotEmpty,
}