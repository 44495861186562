import { CheckInFormWarpper } from './components/CheckInFormWarpper'


const CheckInFormLayout = () => {

  return (
    <div className='container'>
      <CheckInFormWarpper />
    </div>
  )
}

export {CheckInFormLayout}
