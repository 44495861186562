import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'

import { QUERIES } from '../../../../../../../_metronic/helpers'
import Error505 from '../../../../../../../_components/errors/505'
import Loading from '../../../../../../../_components/Loading/Loading'
import { getDestinationByType, getDestinationSEO } from '../../../core/_request'
import { useListView } from '../../../core/ListViewProvider'
import { DestinationHeader } from '../../../settingsLocation/components/location-destination-header/DestinationHeader'
import { LocationDestinationTable } from '../../../settingsLocation/components/location-destination-table/LocationDestinationTable'
import { DestinationEditModal } from '../../../settingsLocation/components/location-destination-modal/DestinationEditModal'
import { DestinationDeleteModal } from '../../../settingsLocation/components/location-destination-delete/DestinationDeleteModal'

import { KTCard } from '../../../../../../../_metronic/helpers';
import { AppBar, Tab, Tabs } from '@mui/material';
import { PinDropTwoTone } from '@mui/icons-material';

const VenteFormDestination:React.FC = () => {
  const {itemIdForUpdate , itemIdForDelete} = useListView()
  const [selectedTab, setSelectedTab] = useState('');
  const [value, setValue] = useState('');
  const [key, setKey] = useState(0); // This key is used to force re-render the useQuery hook

  const handleTabChange = (event: any, newValue: string) => {
    setSelectedTab(newValue);
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue);
    setKey((prevKey) => prevKey + 1); // Increment key to force re-render and re-call useQuery
  };

  useEffect(() => {
    setKey((prevKey) => prevKey + 1); // Increment key to force re-render and re-call useQuery when component mounts
  }, []);

  const { isLoading, data: destination, error } = useQuery(
    `${QUERIES.DESTINATION}-SALE-${key}`, // Include key in the query key to force re-fetch when key changes
    () => {
      return getDestinationSEO(value, 'SALE');
    },
    {
      cacheTime: 0,
      enabled: true,
      onError: (err) => {},
    }
  );

  if (isLoading) {
    return <Loading/>
  }

  if (error) {
    return <Error505/>
  }

  if (!isLoading && !error && destination) {
    return <>
       <KTCard>
                <div className='my-4'>
                  <DestinationHeader />
                </div>
                <AppBar position="static" color="transparent">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons={true}
                    visibleScrollbar={true}
                    indicatorColor="primary"
                    textColor="primary"
                  >
                    <Tab label="All " icon={<PinDropTwoTone />} value={''} />
                    <Tab label="Aples" icon={<PinDropTwoTone />} value={'alpes'} />
                    <Tab label="Côte d'Azur" icon={<PinDropTwoTone />} value={"Côte D'Azur"} />
                    <Tab label="Baléares" icon={<PinDropTwoTone />} value={'Baléares'} />
                  </Tabs>
                </AppBar>

                {destination.length > 0 ? (
                  <LocationDestinationTable destination={destination} key={key}/>
                ) : (
                  <div className="text-center m-10 p-10">No data found</div>
                )}

                {itemIdForUpdate !== undefined && <DestinationEditModal type={'SALE'} key={key}/>}
                {itemIdForDelete !== undefined && <DestinationDeleteModal type={'SALE'}  key={key}/>}
              </KTCard>
      {/* <DestinationHeader />
      {destination.length > 0 ? <LocationDestinationTable  destination={destination} /> : <div className='text-center m-10 p-10'>No data found</div> }
      {itemIdForUpdate !== undefined && <DestinationEditModal type={'SALE'} key={key}/>}
      {itemIdForDelete !== undefined && <DestinationDeleteModal type={'SALE'} key={key}/>} */}
    </>
  }

  return null

}

export default VenteFormDestination