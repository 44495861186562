import React from "react"
import HandelTextArea from "./HandelTextArea";
import HandleImage from "./HandleImage";
import HandleImages from "./HandleImages";
import HandleInput from "./HandleInput";
import HandleInputRadio from "./HandleInputRadio";
import HandleInputDate from "./HandleInputDate";
import HandleCheckbox from "./HandleCheckbox";
import HandelTextEditor from "./HandelTextEditor";
import HandleVideo from "./HandleVideo";
import { ImageSubsection } from "../../app/modules/features/settings/core/_models";
import HandleImageList from "./HandleImageListSubsection";

type HandleInputProps = {
    label?: string;
    name: string;
    type:string
    placeholder?:string
    options?:Array<string>
    preview?:boolean
    imageUrl?:string
    rows?:number
    style?:any
    disabled?:boolean
    show?:boolean
    width?:string,
    imageList?:ImageSubsection[]
}

const FormControl:React.FC<HandleInputProps> = ({ label, name, type,options, placeholder,imageUrl,rows,disabled,show,width,imageList }) => {
    
    switch (type) {
        case ('password'):
            return <HandleInput placeholder={placeholder} type={type} name={name} label={label} />
        case ('text'):
            return <HandleInput placeholder={placeholder} type={type} name={name} label={label} disabled={disabled} show={show}/>
        case 'number':
            return <HandleInput placeholder={placeholder} type={type} name={name} label={label} />
        case 'textarea':
            return <HandelTextArea placeholder={placeholder} type={type} name={name} label={label} rows={rows} />
        case 'textEditor':
            return <HandelTextEditor placeholder={placeholder} name={name} label={label}  />
        case 'radio':
            return <HandleInputRadio  type={type} name={name} label={label} />
        case 'images':
            return <HandleImages  name={name} />
        case 'image':
            return <HandleImage  name={name} preview imageUrl={imageUrl} width={width} />
        case 'imagesListCustomize':
                return <HandleImageList  name={name} preview imageList={imageList} width={width} />
        case 'date':
            return <HandleInputDate placeholder={placeholder} type={type} name={name} />
        case 'checkbox':
            return <HandleCheckbox placeholder={placeholder} type={type} name={name} label={label} />
        case 'video':
            return <HandleVideo name={name} preview imageUrl={imageUrl} />
        default:
            return null;
    }
}

export default FormControl

