import { FastField, useField } from 'formik';
import React from 'react';

type HandleInputProps = {
  label?: string;
  name: string;
  type?:string
}

const HandleInputRadio: React.FC<HandleInputProps> = ({ label, name,type }) => {

  const [, meta] = useField(name);

  return (
    <>
      {label && (<label className="form-label required">{label}</label>)}
      <div className='row mb-4'>
     
        <div className='col-sm-4 col-md-6 col-lg-6 col-xl-6'>
          <div className="form-check form-check-custom  form-check-sm my-4">
            <FastField
              name={name}
              type={type}
              value="CHALET"
              className="form-check-input"
            />
            <label className="form-check-label">
              CHALET
            </label>
          </div>
        </div>
      
        <div className='col-sm-4 col-md-6 col-lg-6 col-xl-6'>      
          <div className="form-check form-check-custom  form-check-sm my-4">
            <FastField
              name={name}
              type={type}
              value="TERRAIN"
              className="form-check-input"
            />
            <label className="form-check-label">
              TERRAIN
            </label>
          </div>
        </div>
      </div>
      {meta.touched && meta.error ? (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>{meta.error}</div>
        </div>
      ) : null}
    </>
  );
};

export default HandleInputRadio;