import { useField,FormikProps } from "formik";
import FormControl from "../../../../../../../_components/formControl/FormControl";
import { ArticleType } from "../../../../settings/core/_models";

const ListingImages = ({ formikProps }: { formikProps: FormikProps<ArticleType> }) => {

   
  
  return (
    <>
   
        <div className="row">
            <div className="col-lg-12">
                <div className="card card-custom card-flush mb-6">
                    <div className="card-body">
                    {/* {formikProps.values.imageList[0].imageByte?.name} */}
                    {formikProps.values.type=='PAPIER' ?
                    <>
                        <label className="form-label required mb-4">Images de l'article</label>                    
                        <FormControl
                            type="images"
                            name="imageList"
                        /> </>:
                       <><label className="form-label required mb-4">Video de l'article</label>                    
                        <FormControl
                            type="video"
                            name="imageList[0]"
                            preview
                            // imageUrl={formikProps.values.imageList[0].imageUrl}
                            // imageUrl={formikProps.values.imageList[0].imageUrl}
                        />
                        </>
                        }
                    </div>
                </div>
            </div>
        </div>
  
    </>
  )
}

export default ListingImages
