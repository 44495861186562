import axios, { AxiosResponse } from 'axios'
import { ID } from '../../../../../_metronic/helpers'
import { ListingsVenteQueryResponse } from '../listing-vente-list/core/_models'
import { DestinationList, GeneralType, ListingVenteResponseTypeProps,StrenghtsType } from './_models'
import { DestinationType } from '../../settings/core/_models'

const API_URL = process.env.REACT_APP_API_URL


const GET_LISTINGS_VENTE_URL = `${API_URL}/sale/properties`
const LISTINGS_EDIT_VENTE_URL = `${API_URL}/sale/properties/edit`
const ADD_LISTINGS_VENTE_URL = `${API_URL}/sale/properties/property`

const GET_LISTINGS_VENTE_BY_SLUG_URL = `${API_URL}/sale/properties/allBySlug`
const END_POINT_LISTING_VENTE_UPDATE_STATUS = `/updateStatus`

const END_POINT_STRENGHTS = `${API_URL}/sale/properties/strenghts`
const END_POINT_STRENGHTS_CREATE = `${API_URL}/sale/properties/strenghts/create`
const END_POINT_GENERAL = `${API_URL}/sale/properties/general`
const END_POINT_GENERAL_CREATE = `${API_URL}/sale/properties/general/create`


const DELETE_LISTINGS_IMAGES_VENTE_URL = `${API_URL}/sale/properties/image`
const DELETE_LISTINGS_DIANOSTIC_VENTE_URL = `${API_URL}/sale/properties/dianostics`
const ADD_LISTINGS_IMAGES_VENTE_URL = `${API_URL}/sale/properties/image/create`
const ADD_LISTINGS_DIANOSTIC_VENTE_URL = `${API_URL}/sale/properties/dianostics/create`

const DELETE_LISTINGS_VENTE_AGENCEMENT_URL = `${API_URL}/sale/properties/layout`

const END_POINT_ADDRESS_TAGS = `${API_URL}/sale/properties/address`
const END_POINT_DESTINATION_LIST = `${API_URL}/setting/destination_list`




const getAllListingsVente = (query:string): Promise<ListingsVenteQueryResponse> => {
  return axios
    .get(`${GET_LISTINGS_VENTE_URL}?${query}&sort=id,DESC`)
    .then((d:AxiosResponse<ListingsVenteQueryResponse>) =>  d.data)
}
export const getAllListingsVenteByDest = (destination:string): Promise<ListingsVenteQueryResponse> => {
  return axios
    .get(`${GET_LISTINGS_VENTE_URL}/byDestination/${destination}?sort=id,DESC`)
    .then((d:AxiosResponse<ListingsVenteQueryResponse>) =>  d.data)
}

const getListingsVenteBySlug = (slug: string | undefined)=> {
  return axios
    .get(`${GET_LISTINGS_VENTE_BY_SLUG_URL}/${slug}`)
    .then((d:AxiosResponse<ListingVenteResponseTypeProps>) =>  d.data)
}

const addListingsVente = (body:FormData) => {
  return axios
    .post(`${ADD_LISTINGS_VENTE_URL}`,body,{
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
}

const editListingsVente = (body:ListingVenteResponseTypeProps)=> {
  return axios
    .put(`${LISTINGS_EDIT_VENTE_URL}`,body)
    .then((response) => response.data)
}

const deleteListing = (id:ID)=> {
  return axios
    .delete(`${GET_LISTINGS_VENTE_URL}/${id}`)
    .then((response) => response.data)
}


const getStrenghts = async()=>{
  return  axios
    .get(`${END_POINT_STRENGHTS}`)
    .then((response) => response.data)
}

const addStrenghts = async(body : StrenghtsType)=>{
  return  axios
    .post(END_POINT_STRENGHTS_CREATE,body)
    .then((response) => response.data)
}

const getGeneral = (): Promise<Array<GeneralType>>=>{
  return  axios
  .get(END_POINT_GENERAL)
  .then((d:AxiosResponse<Array<GeneralType>>) => d.data)
}

const addGeneral = (body: GeneralType)=>{
  return  axios
  .post(END_POINT_GENERAL_CREATE,body)
  .then((response) => response.data)
}

const deleteAgencement= (id:string)=>{
  return  axios
  .delete(`${DELETE_LISTINGS_VENTE_AGENCEMENT_URL}/${id}`)
  .then((response) => response.data)
}

const deleteImage = (id:string)=>{
  return  axios
  .delete(`${DELETE_LISTINGS_IMAGES_VENTE_URL}?id=${id}`)
  .then((response) => response.data)
}

const deleteDianostic = (id:string)=>{
  return  axios
  .delete(`${DELETE_LISTINGS_DIANOSTIC_VENTE_URL}?id=${id}`)
  .then((response) => response.data)
}

const addImage = (id:ID,body:FormData)=>{
  return  axios
  .post(`${ADD_LISTINGS_IMAGES_VENTE_URL}/${id}`,body,{
    headers:{
      "Content-Type": "multipart/form-data",
    }
  })
  .then((response) => response.data)
}

const addDianostic = (id:ID,body:FormData)=>{
  return  axios
  .post(`${ADD_LISTINGS_DIANOSTIC_VENTE_URL}/${id}`,body,{
    headers:{
      "Content-Type": "multipart/form-data",
    }
  })
  .then((response) => response.data)
}

export const updateStatusListingVente = async(slug:string,listed:{listed:boolean})=>{
  return  axios
  .put(GET_LISTINGS_VENTE_URL + '/' + slug+ END_POINT_LISTING_VENTE_UPDATE_STATUS,listed)
  .then((response) => response.data)
}



const getAddressTags = ()=> {
    return axios
      .get(`${END_POINT_ADDRESS_TAGS}/address-tags`)
      // .then((d:AxiosResponse<ResponseCategories>) =>  d.data)
      .then((response) => {
        if (response.status === 200) {
          // Assuming ResponseCategories is a custom type or type alias for your API response
          const data: string[]  = response.data;
            const categories:  string[]  = data;
            return categories;
        } else {
          // Handle the HTTP error status if needed
          console.error('API request failed with status:', response.status);
          return [];
        }
      })
      .catch((error) => {
        // Handle any Axios-related errors here
        console.error('Axios request failed:', error);
        return [];
      });
  }


export const getdestinationList = (): Promise<Array<DestinationList>>=>{
  const data=  axios
  .get(END_POINT_DESTINATION_LIST)
  .then((d:AxiosResponse<Array<DestinationList>>) => d.data)

  return data;
}


export {
  getAllListingsVente, 
  getListingsVenteBySlug,
  addListingsVente,
  editListingsVente,
  deleteListing,
  getGeneral,
  addGeneral,
  getStrenghts,
  addStrenghts,
  deleteImage,
  deleteDianostic,
  addImage,
  addDianostic,
  deleteAgencement,
  getAddressTags
}
