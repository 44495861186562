import React from 'react'
import FormControl from '../../../../../_components/formControl/FormControl'
import { Button } from 'react-bootstrap'
import moment from 'moment'
import { FastField } from 'formik'
import { ReservationDetailsResponse } from '../../../../modules/features/guesty/reservation-guesty/reservation-view/Core/_models'

type ContentProps = {
    reservation:ReservationDetailsResponse
    isSubmitting:boolean
}
const Content:React.FC<ContentProps> = ({reservation,isSubmitting}) =>{
  return (
    <> 
            <div className="row my-10 mx-lg-16">
              <div className="col-lg-12">
                <div className="card rounded-0 p-sm-0  p-lg-10">
                  <div className="card-body rounded-0">
                    <div className="row">
                      <div className="col-lg-6 mb-10">
                        <p className="text-secondary mb-0">Arrivée</p>
                        <p className="text-dark mb-0">{moment(reservation.checkIn).utc().locale('fr').format('DD MMMM YYYY [à] HH[h]mm')}</p>  
                      </div>
                      <div className="col-lg-6 mb-10">
                        <p className="text-secondary mb-0">Départ</p>
                        <p className="text-dark mb-0">{moment(reservation.checkOut).utc().locale('fr').format('DD MMMM YYYY [à] HH[h]mm')}</p>  
                      </div>
                      <div className="col-lg-6 mb-10">
                        <p className="text-secondary mb-0">Nombre d’invités</p>
                        <p className="text-dark mb-0">{`${reservation.guestsCount}  Adultes`}</p>  
                      </div>
                      <div className="col-lg-6 mb-10 d-flex">
                        <div className="form-check form-check-custom  form-check-sm my-4">
                          <FastField
                            name={'transport'}
                            type={'radio'}
                            value="Besoin de transfert"
                            className="form-check-input"
                          />
                            <p className="text-secondary ms-3 mb-0">Besoin de transfert</p>
                        </div>
                        <div className="form-check form-check-custom mx-3  form-check-sm my-4">
                          <FastField
                            name={'transport'}
                            type={'radio'}
                            value="Location de voiture"
                            className="form-check-input"
                          />
                            <p className="text-secondary ms-3 mb-0">Location de voiture</p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 mb-10">
                        <p className="text-secondary">Special requests and specials needs before and during your stay.</p>
                        <FormControl 
                            type='textarea'
                            name='requests'
                            placeholder='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis no strud exercitation ullamco. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 mb-10">
                        <p className="text-secondary mb-0">Prénom</p>
                        <p className="text-dark mb-0">{reservation.guest.firstName}</p>
                      </div>
                      <div className="col-lg-6 mb-10">
                        <p className="text-secondary mb-0">Nom</p>
                        <p className="text-dark mb-0">{reservation.guest.lastName}</p>
                      </div>
                      <div className="col-lg-6 mb-10">
                        <p className="text-secondary mb-0">Email</p>
                        <p className="text-dark mb-0">{reservation.guest.email}</p>
                      </div>
                      <div className="col-lg-6 mb-10">
                        <p className="text-secondary mb-0">Numéro de téléphone</p>
                        <p className="text-dark">{reservation.guest.phone}</p>
                      </div>
                      <div className="col-lg-6">
                        <p className="text-secondary">Nationalié</p>
                        <FormControl 
                          type="text"
                          name="nationality"
                          placeholder="Nationalié"
                        />
                      </div>
                      <div className="col-lg-6">
                        <p className="text-secondary">Anniversaire</p>
                        <FormControl 
                          type="date"
                          name="anniversaire"
                          placeholder="Anniversaire"
                        />
                      </div>
                      <div className="col-lg-6">
                        <p className="text-secondary">Addresse</p>
                        <FormControl 
                          type="text"
                          name="address"
                          placeholder="Addresse"
                        />
                      </div>
                      <div className="col-lg-6">
                        <div className="row">
                          <div className="col-lg-6">
                            <p className="text-secondary">Ville</p>
                            <FormControl 
                              type="text"
                              name="ville"
                              placeholder="Ville"
                            />
                          </div>
                          <div className="col-lg-6">
                            <p className="text-secondary">Code postal</p>
                            <FormControl 
                              type="text"
                              name="codePostal"
                              placeholder="Code postal"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="separator border-secondary border-5 my-16"></div>
                    <div className="row">
                      <div className="col-lg-2 mb-10">
                        <FormControl 
                          name="file"
                          type="image"
                          preview
                        />
                      </div>
                      <div className="col-lg-10 mb-10">
                        <p className="text-secondary mb-0">Veuillez télécharger une photo d’identité de l’invité principal.</p>
                        <p className="text-secondary mb-0">ex. : passeport, permis de conduire.</p>
                        <p className="text-secondary mb-0">Formats pris en charge : jpg, jpeg, png, pdf. Taille maximale du fichier : 4M</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 mb-10">
                        <FormControl 
                          name="confirm"
                          type="checkbox"
                          label="Je confirme par la présente que j’ai lu et confirme que les informations fournies ci-dessus sont vraies."
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 d-flex justify-content-end">
                        <Button type='submit' className='btn btn-outline rounded-0 btn-outline-dark btn-active-light-dark' disabled={isSubmitting} >
                          Sauvegardez {isSubmitting && (<span className="spinner-border spinner-border-sm align-middle ms-2"></span>)}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
    </>
  )
}

export default Content