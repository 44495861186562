
import ArticleFormCreate from './Components/ArticleFormCreate';


const ArticleFormCreateWarpper = () => {

  return (
    <>      
      <ArticleFormCreate />
    </>
  )
}

const ArticleCreate = () => (
 
      <ArticleFormCreate />

)

export {ArticleCreate}
