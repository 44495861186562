import { KTCard, KTCardBody } from "../../_metronic/helpers"

const Loading = () => {
  return (
    <KTCard>
        <KTCardBody>
            <div className="d-flex flex-column flex-center my-16">
                <span className="spinner-border spinner-border- align-middle ms-2"></span>
            </div>
        </KTCardBody>
    </KTCard>
  )
}

export default Loading