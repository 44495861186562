import { useSearchParams } from "react-router-dom";
import { QUERIES, isNotEmpty } from "../../../../../_metronic/helpers";
import { useQuery } from "react-query";
import { checkTokenExpire } from "../../core/_requests";
import Loading from "../../../../../_components/Loading/Loading";
import ResetPassword from "./ResetPassword";
import Expire from "../../../../../_components/errors/Expire";

const ResetPasswordWarrper = () => {
    const [searchParams] = useSearchParams()
    const token = searchParams.get('token')
    const enabledQuery: boolean = isNotEmpty(token)

    const {
      isLoading,
      data:user,
      error,
      isError,
    } = useQuery(
      `${QUERIES.TOKEN}-${token}`,
      () => {
        return checkTokenExpire(token ? token : '')
      },
      {
        cacheTime: 0,
        enabled: enabledQuery,
        onError: (err) => {
        },
      }
    )
    if(!token){
      return (<>
        <Expire />
      </>)
    }  
    if (isLoading) {
      return   <Loading />
    }

    if (isError) {
      return (<>
        <Expire />
      </>)
    }

    if (!isLoading && !error && user) {
      return <ResetPassword user={user} token={token} />
    }

    return null
  }

export default ResetPasswordWarrper