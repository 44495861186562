import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { isNotEmpty, QUERIES } from '../../../../../../_metronic/helpers';
import { getArticleByName, getSEOByType, addSEOArticle } from '../../../settings/core/_request';
import { SeoType } from '../../../settings/core/_models';
import ArticleFormEditInformation from './Components/ArticleFormEditInformation';

const ArticleEditInformationWarpper = () => {
  const { name } = useParams();
  const enabledQuery: boolean = isNotEmpty(name);

  const {
    isLoading: listingLoading,
    data: listing,
    error: listingError,
  } = useQuery(
    `${QUERIES.ARTICLE_LIST}-article-${name}`,
    () => getArticleByName(name || ''),
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        // Handle listing error if needed
      },
    }
  );

  const {
    data: seo,
    isLoading: seoLoading,
    error: seoError,
  } = useQuery(
    `${QUERIES.ARTICLE_SEO_LIST}-NAME-${name}`,
    () => getSEOByType('article-' + name),
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {

        // Handle SEO error if needed
      },
    }
  );

  if (!enabledQuery) {
    return null; // or Loading spinner or any other loading indicator
  }

  if (listingError ) {
    return <div>Error occurred while fetching data.</div>; // Render error message
  }

  if (listingLoading) {
    return <div>Loading...</div>; // Render loading indicator
  }

  if (listing) {
    // If listing is available, set SEO data to listing object
    listing.seo = {
      type: seo?.type || 'article-' + listing.name,
      id: seo?.id,
      title: seo?.title,
      titre: seo?.titre,
      descriptionFR: seo?.descriptionFR,
      descriptionENG: seo?.descriptionENG,
    };

    // Render your component with fetched data
    return <ArticleFormEditInformation isListingLoading={listingLoading} listing={listing} />;
  }

  return <div>Oops, something went wrong!</div>; // Render a generic error message
};

export { ArticleEditInformationWarpper };
