import { Formik, Form ,FormikConsumer} from 'formik';
import { Alert, Button } from 'react-bootstrap'

import { LoadingHelper } from '../../../../../../_metronic/helpers/LoadingHelper';
// import { ArticleInitState } from '../../../core/_initState
// import {  RestaurantSchema } from '../Core/_validation'
import {  addArticle, addSEOArticle, checkArticleNameIfExist, createArticleDetails } from '../../../settings/core/_request';

// import { hamdArticleObject } from '../../../core/_helpers';


//Components
import ListingImages from './ArticleImages';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { useLayout } from '../../../../../../_metronic/layout/core';
import { initialArticle } from '../../../settings/core/_initState';
import ArticleInfo from '../../article-edit/article-edit-information/Components/ArticleInfo/ArticleInfo';
import ArticleDetails from './ArticleDetails/ArticleDetails';
import ArticleSEO from '../../article-edit/article-edit-information/Components/SEO/ArticleSEO';
import EditDetailsPage from './ArticleDetails/EditDetailsPage';
import { Toast } from '../../../../../../_components/SweetAlerts/SweetAlert';
import { handleArticleFormData } from '../../../settings/core/_helpers';
import { ArticleSchema } from '../../../settings/core/_validation';



const ArticleFormCreate:React.FC= () => {
  const navigate = useNavigate()
  const {config} = useLayout()
  if (!config.app?.footer?.display) {
    return null
  }



  return (
    <>      
      <Formik
          initialValues={initialArticle}
          validationSchema={ArticleSchema}
        onSubmit={async (values, { setSubmitting }) => {
          const isAvailable = await checkArticleNameIfExist(values.name); 
          if(isAvailable==true)  { 
              setSubmitting(false)
              Toast.fire({
                    icon: 'error',
                    title: "Le nom de l'article est déjà utilisé, veuillez choisir un nouveau nom."
                  })
            }else{
              setSubmitting(true)
              values.contenue=values.title+"\n"+values.subtitle
              if(values.seo) values.seo.type="article-"+values.name;
                 try {
                    const formData = handleArticleFormData(values)
                  
                    const res = await addArticle(formData) 
                    
                    if(res.status === 200){
                      Toast.fire({
                        icon: 'success',
                        title: 'Article'+ res.data.name +' a été bien ajouté'
                      })
                      if(res.data.id){
                        var result=true;
                        values.listDetails.forEach((element, index)=>{
                          
                          values?.listDetails?.forEach((element, index) => {
                              if(element.id==undefined){
                                element.order=index+1;
                              }
                          });
                          createArticleDetails(element,res.data.id).then((res)=>{
                              
                          }).catch((err:any)=>{
                            result=false;
                            console.log('error',err);
                          })
                        })
                       {!result&&(
                        Toast.fire({
                        icon: 'error',
                        title: "erreur lors de la création d'un paragraphe mais l'article a été créé avec succès"
                          }))}
                      }
                      const seo= {
                        
                          "descriptionENG": values.seo?.descriptionENG,
                          "descriptionFR":   values.seo?.descriptionFR,
                          "title": values.seo?.title,
                          "titre":  values.seo?.titre,
                          "type":  "article-"+values.name,
                          "slug":  values.name,
                        
                      };
                      

                      const seoRes= await addSEOArticle(seo)
                      window.location.replace(`../../apps/articles/edit/information/${res.data.name}`);

                    }

                  } catch (ex) {
                    Toast.fire({
                      icon: 'error',
                      title: 'Oops error!!'
                    })
                  } finally {
                    setSubmitting(false)
                  }

                }
            }
         
          }
       
      
      >
        {({ errors,touched,isSubmitting , ...formikProps }) => (
        <Form >
            {Object.keys(errors).length > 0 && (
              
                 <Alert variant="danger">
                    <Alert.Heading>Veuillez vérifier votre formulaire</Alert.Heading>
                    {errors.name  ? (
                        <div className='mx-4'>
                             <p  style={{ color:'#222' }}>* {errors.name} </p> 
                        </div> 
                        
                      ) : null}
                      {errors.type  ? (
                       
                       <div className='mx-4'>
                          <p  style={{ color:'#222' }}>*{errors.type} </p> 
                     
                       </div> 
                      ) : null}
                        {errors.title   ? (
                       
                       <div className='mx-4'>
                          <p  style={{ color:'#222' }}>*{errors.title} </p> 
                     
                       </div> 
                      ) : null}
                       {errors.subtitle ? (
                       
                       <div className='mx-4'>
                          <p  style={{ color:'#222' }}>*{errors.subtitle} </p> 
                     
                       </div> 
                      ) : null}
                        {errors.urlWeb? (
                       
                       <div className='mx-4'>
                          <p  style={{ color:'#222' }}>*{errors.urlWeb} </p> 
                    
                       </div> 
                      ) : null}
                    {errors.imageList    ? (
                       
                       <div className='mx-4'>
                          <p  style={{ color:'#222' }}>*{errors.imageList.toString()} </p> 
                     
                       </div> 
                      ) : null}
                   
                   {errors.imageByte  ? (
                       
                       <div className='mx-4'>
                          <p  style={{ color:'#222' }}>*{errors.imageByte} </p> 
                     
                       </div> 
                      ) : null}
                          {errors.listDetails? (
                       
                       <div className='mx-4'>
                          <p  style={{ color:'#222' }}>*{errors.listDetails.toString()} </p> 
                    
                       </div> 
                      ) : null}
                   
                   
                  </Alert>
                             
                )}
          <div className='row g-5 g-xl-10 mb-2 mb-xl-2'>
            <div className='col-md-8 col-lg-8 col-xl-8 col-xxl-8 mb-md-5 '>
              <ArticleInfo  isForEdit={false}/>
            </div>
          
            <div className='col-md-4 col-lg-4 col-xl-4 col-xxl-4 mb-md-5 '>
              <div className="row">
              
                <div className='col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-md-5 '>
                  <ArticleDetails helpers={formikProps}/>
                </div>
              </div>
             
             
            </div>
          </div>
         {/* Paragraph */}
         <div className='row mb-10'>
            <div className='col-sm-12 mb-md-5 '>
            <FormikConsumer> 
                {formikProps => <EditDetailsPage formikProps={formikProps} />}
              </FormikConsumer>
              
            </div>
          </div>
          {/* Images */}
          <div className='row g-5 g-xl-10 mb-2 mb-xl-2'>
            <div className='col-lg-12 mb-md-5 '>
        
            <FormikConsumer> 
                {formikProps => <ListingImages formikProps={formikProps} />}
            </FormikConsumer>
      
            
            </div>
          </div>
      
          {/* SEO */}
          <div className='row mb-10'>
            <div className='col-sm-12 mb-md-5 '>
            <ArticleSEO /> 
              
            </div>
          </div>
          {/* save */}
          <div className='app-footer justify-content-end  fixed-bottom' id='kt_app_footer'>
            {config?.app?.footer?.containerClass ? (
              <div className={clsx('app-container', config.app.footer.containerClass)}>
                  <Button type='submit' className='btn btn-primary w-100' disabled={isSubmitting} >
                    Sauvegardez {isSubmitting && (<span className="spinner-border spinner-border-sm align-middle ms-2"></span>)}
                  </Button>
              </div>
            ) : (
              <Button type='submit' className='btn btn-primary w-100' disabled={isSubmitting} >
                Sauvegardez {isSubmitting} {isSubmitting && (<span className="spinner-border spinner-border-sm align-middle ms-2"></span>)}
              </Button>
            )}
          </div>
          {isSubmitting && (<LoadingHelper />)}
        </Form>
        )}
        
      </Formik>
    </>
  )
}
export default ArticleFormCreate
