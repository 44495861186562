import { Field, useField } from 'formik';
import React from 'react';

type HandleInputProps = {
  label?: string;
  name: string;
  type?:string
  placeholder?:string
  rows?:number
}

const HandelTextArea: React.FC<HandleInputProps> = ({ label, name,type,placeholder,rows }) => {

  const [field, meta] = useField(name);
  switch (type) {
    case "textarea":
      
      break;
  
    default:
      break;
  }
  return (
    <div className="mb-10">
      {label && (<label className="form-label required">{label}</label>)}
        <Field
          as={type} 
          id={name}
          rows={rows ? rows : 4}
          className="form-control"
          placeholder={placeholder}
          {...field}
        />
        {meta.touched && meta.error ? (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>{meta.error}</div>
          </div>
        ) : null}
    </div>
  );
};

export default HandelTextArea;