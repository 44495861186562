import React from 'react';
import { Button } from 'react-bootstrap';
import { useMutation, useQueryClient } from 'react-query';
import { Toast } from '../../../../../../../../_components/SweetAlerts/SweetAlert';
import { QUERIES } from '../../../../../../../../_metronic/helpers';
import { deleteImageArticle } from '../../../../../settings/core/_request'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import { ArticleType } from '../../../../../settings/core/_models';

type ImagesProps = {
  isListingLoading: boolean;
  listing: ArticleType;
};

const Images: React.FC<ImagesProps> = ({ isListingLoading, listing }) => {
  const queryClient = useQueryClient();

  const deleteItem = useMutation((id: any) => deleteImageArticle(id), {
    onSuccess: () => {
      Toast.fire({
        icon: 'success',
        title: 'Image deleted',
      });
      queryClient.invalidateQueries([`${QUERIES.RESTAURANT_LIST}-listing`]);
    },
    onError: (error) => {
      Toast.fire({
        icon: 'error',
        title: 'Oops! Error when deleting image',
      });
    },
  });

  return (
    <>
      <div className='mt-11'>
        {(listing?.imageList && listing?.imageList.length  > 0) ? (
          <>
            <Swiper
              pagination={{
                type: 'fraction',
              }}
              modules={[Pagination]}
              className='mySwiper'
              breakpoints={{
                // when window width is >= 640px
                200: {
                  width: 200,
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                // when window width is >= 640px
                640: {
                  width: 640,
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                // when window width is >= 768px
                768: {
                  width: 768,
                  slidesPerView: 4,
                  spaceBetween: 50,
                },
              }}
            >
              {listing.imageList.map((item, idx: number) => (
                <SwiperSlide key={`images-${idx}`}>
                  <div className='mb-10'>
                    <img
                      src={item.imageUrl}
                      alt={item.alt}
                      data-dz-thumbnail=''
                      width='150px'
                      height='150px'
                      className='rounded bg-light mb-2'
                    />
                    <Button
                      onClick={async () => await deleteItem.mutateAsync(item.id)}
                      className='btn btn-sm btn-danger mb-2'
                      style={{ width: '150px' }}
                    >
                      Supprimer
                    </Button>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </>
        ) : (
          <div className='d-flex align-items-center justify-content-center' style={{ height: '250px' }}>
            <h5 className='text-center m-6'>No images</h5>
          </div>
        )}
      </div>
    </>
  );
};

export default Images;
