import React, { FC, Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { isAdmin, isAdminWebSite } from '../../../../_metronic/helpers/_helpers'
import Error505 from '../../../../_components/errors/505'
import { useAuth } from '../../auth'
// import { SettingsArticles } from './settingsArticles/SettingsArticles'
import ConciergePage from '../Concierge/ConciergePage'
import TopBarProgress from 'react-topbar-progress-indicator'
import { WithChildren } from '../../../../_metronic/helpers'
import { getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'
import { SettingsSide } from '../settings/SettingsSide'
import { SettingsLocation } from '../settings/settingsLocation/SettingsLocation'
import { SettingsVente } from '../settings/settingsVente/SettingsVente'
import { SettingsDestination } from '../settings/Destinations/SettingsDestination'
import { SettingsDestinationSEO } from '../settings/settingsDestination/SettingsDestinationSEO'

const accountBreadCrumbs: Array<PageLink> = [
  {
    title: 'SEO',
    path: '/apps/settings/seo',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]


const SEOPage: React.FC = () => {
  const {currentUser} = useAuth()
  if(!currentUser){
    return <></>
  }
  return (
    <Routes>
      <Route
        element={
          <>
            {(isAdmin(currentUser.roles) || isAdminWebSite(currentUser.roles)) ? <SettingsSide /> : <Error505 />}
          </>
        }
      >
        <Route
          path='rent'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>Location</PageTitle>
              {(isAdmin(currentUser.roles) || isAdminWebSite(currentUser.roles)) ? <SettingsLocation /> : <Error505 />}
            </>
          }
        />
        
        <Route
          path='sale'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>Vente</PageTitle>
              {(isAdmin(currentUser.roles) || isAdminWebSite(currentUser.roles)) ? <SettingsVente /> : <Error505 />}
            </>
          }
        />
         <Route
          path='vente'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>Vente</PageTitle>
              {(isAdmin(currentUser.roles) || isAdminWebSite(currentUser.roles)) ? <SettingsVente /> : <Error505 />}
            </>
          }
        />
        {/* <Route
          path='pages'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>Pages</PageTitle>
              {(isAdmin(currentUser.roles) || isAdminWebSite(currentUser.roles)) ? <Pages /> : <Error505 />}

            </>
          }
        />
          <Route
          path='Sections'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>Sections</PageTitle>
              {(isAdmin(currentUser.roles) || isAdminWebSite(currentUser.roles)) ? <Sections /> : <Error505 />}

            </>
          }
        /> */}
      
        <Route
          path='location'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>Location</PageTitle>
              {(isAdmin(currentUser.roles) || isAdminWebSite(currentUser.roles)) ? <SettingsLocation /> : <Error505 />}

            </>
          }
        />
         <Route
          path='destination'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>Destination</PageTitle>
              {(isAdmin(currentUser.roles) || isAdminWebSite(currentUser.roles)) ? <SettingsDestinationSEO/>: <Error505 />}

            </>
          }
        />


        <Route
          path='Vente'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>Vente</PageTitle>
              {(isAdmin(currentUser.roles) || isAdminWebSite(currentUser.roles)) ? <SettingsVente /> : <Error505 />}

            </>
          }
        />
      

        <Route index element={<Navigate to='/apps/settings/seo' />} />
      </Route>
    </Routes>
  )
}

export default SEOPage
