/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Link} from 'react-router-dom'
import {Outlet, useLocation, useParams} from 'react-router'
import clsx from 'clsx'

const ArticleEditSide: React.FC = () => {
  const location = useLocation()
  const {name} = useParams()
  
  return (
    <div className="row">
      <div className="col-lg-12">
        <div className='card mb-5'>
          <div className='card-body'>
            <div className="d-flex">
              <Link
                to={`/apps/articles/edit/information/${name}`}
                className={clsx(`btn me-4  my-2 w-sm-100`, {
                  'btn-primary ':  location.pathname === `/apps/articles/edit/information/${name}`,
                  'btn-secondary':  location.pathname !== `/apps/articles/edit/information/${name}`,

                })}
              >
                <span className='btn-label'>Informations générales</span>
              </Link>
            <Link
                to={`/apps/articles/edit/contenu/${name}`}
                className={clsx(`btn  my-2 w-sm-100`, {
                  'btn-primary':  location.pathname === `/apps/articles/edit/contenu/${name}`,
                  'btn-secondary':  location.pathname !== `/apps/articles/edit/contenu/${name}`,
                })}
              >
                <span className='btn-label'>Contenu</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-12 mb-10">
        <Outlet />
      </div>
    </div>
    
  )
}

export {ArticleEditSide}
