import { ConciergeItemType, ConciergeServiceType, ConciergeType } from "../../Concierge/concierge-list/core/_models";
import { ArticleType, DestinationType, FaqType, ImageSubsection, LocationType, ReviewType, SelectedProp, SeoType, SubSectionExperiences, SubSectionType } from "./_models";


export const initialSubSection : SubSectionType  =  {
    title:' ',
    titre: ' ',
    descriptionFR:' ',
    descriptionENG:' ',
    name:' '
}

export const initialExperience : SubSectionExperiences  =  {
    title:'',
    titre:'',
    descriptionFR:'',
    descriptionENG:'',
    subSectionDTOKeyList:'',
    name:'',
    type:'',
    link:''

}

export const initialSubSectionKey : SubSectionType  =  {
    title:'',
    titre:'',
    descriptionFR:'',
    descriptionENG:'',
    subSectionDTOKeyList:'',
    name:''

}

export const initialSelectedProperty : SelectedProp  =  {
    tag:'',
    nickname:'',
    slug:'',
    city:'',
    cover_image:'',
    bathrooms:'',
    rooms:'',
    id:'',
    property_type:'',
    section_id:''
}


export const initialFaq : FaqType  =  {
    questionFR: '' ,
    questionEN: '' ,
    type:'' ,
    answerFR: '' ,
    answerEN: '',
    profileType:'',
    locationDTO:{
        name:'',
        fullPath:'',
        id:'',
    },
    destination:'',
    pageType:[]
}
export const initialLocation : LocationType  =  {
    name:'',
    fullPath:'',
    id:'',
}

export const initialArticle : ArticleType  =  {
   
    source: '',
    type: '',
    name:'',
    footerLinkDescription: '',
    contenue: '',
    imageUrl: '',
    urlWeb: '',
    imageList : [],
    listDetails:[],
    title:'',
    subtitle:''
}


export const initialDestination : DestinationType  =  {
    title:'',
    titre:'',
    destination:'',
    region:'',
    descriptionFR:'',
    descriptionENG:'',
    metaTitle:'',
    metaTitleEn:'',
    metaDescription:'',
    metaDescriptionEn:'',
  isCanonical:false,
    listUrl: [
        {
          link: "",
          name: ""
        }
    ]
}


export const initialConcierge : ConciergeType  =  {
    title:'',
    titre:'',
    sector:'',
    descriptionFR:'',
    descriptionEn:'',
    slug:'',
    items: [
        {
            title:'',
            titre:'',
            city:'',
            descriptionFR:'',
            descriptionEn:'',
            slug:''
        }
    ]
}
export const initialItemConcierge : ConciergeItemType =  {
    title:'',
    titre:'',
    city:'',
    descriptionFR:'',
    descriptionEn:'',
    slug:'',
    services: [
        {
            title:'',
            titre:'',
            name:'',
            descriptionFR:'',
            descriptionEn:'',
            slug:'',
            listDetails:[],
        }
    ]
    // items: initialConciergeItem []
}
export const initialServiceConcierge : ConciergeServiceType =  {
    title:'',
    titre:'',
    name:'',
    descriptionFR:'',
    descriptionEn:'',
    slug:'',
    listDetails:[],

}

export const initialReview : ReviewType  =  {
    message: '',
    name: '',
    place: '',
    date:'',
    imageUrl:'',
    propertyTitle:'',
    propertyId:''
}

export const initialSEO : SeoType  =  {
    type:'',
    descriptionENG:'',
    descriptionFR:'',
    title:'',
    titre:'',
    isCanonical:true
}
export const  SubsectionImageInitialState :  SubsectionImageType = {
    images: [] 
}

export type SubsectionImageType ={
    images: File[]
  }