import { cos } from "@amcharts/amcharts5/.internal/core/util/Math"
import { ArticleType, KeySubsectionType, SectionType, SubSectionExperiences, SubSectionType } from "./_models"
import { addSubSectionKeyByType } from "./_request"
import { ConciergeItemType, ConciergeServiceDetailsType, ConciergeServiceType, ConciergeType } from "../../Concierge/concierge-list/core/_models"

export const handleSectionFormData = (section:SectionType)=>{
    const fd = new FormData()
    fd.set('id',section.id as string)
    fd.set('title',section.title)
    fd.set('titre',section.titre)
    fd.set('descriptionENG',section.descriptionENG)
    fd.set('descriptionFR',section.descriptionFR)
    fd.set('type',section.type)
    
    if(section.imageByte){
        fd.set('imageByte',section.imageByte)
    }
    if(section.imageId && section.imageUrl){
        fd.set('imageId',section.imageId)
        fd.set('imageUrl',section.imageUrl)
    }
    return fd
}



export const handleSubSectionFormData = (section:SubSectionType) => {
    const fd = new FormData();
    if (section?.id) {
      fd.set('id', section.id.toString());
    }
  
    fd.set('title', section?.title);
    fd.set('titre', section?.titre);
    fd.set('descriptionENG', section?.descriptionENG);
    fd.set('descriptionFR', section?.descriptionFR);

    if(section?.name)
      fd.set('name', section?.name);

  
    section?.imageSubsectionDTOList?.forEach((element, index) => {
      if (element?.imageByte) {
        fd.set(`imagesList[${index}].imageByte`, element?.imageByte);

      }
  
      if (element.id && element.order && element.imageUrl) {
        fd.set(`imagesList[${index}].imageUrl`, element?.imageUrl);
        fd.set(`imagesList[${index}].order`, element?.order);
      }
    });

    if(section?.imageByte){
        fd.set('imageByte',section?.imageByte)
    }  
    console.log("name",section.name);
    return fd;
  };

  export const handleSubSectionExperiencesFormData = (section:SubSectionExperiences) => {
    const fd = new FormData();
    console.log("section",section);
    if (section?.id) {
      fd.set('id', section.id.toString());
    }
  
    fd.set('title', section?.title);
    fd.set('titre', section?.titre);

    
    fd.set('descriptionENG', section?.descriptionENG );
   
    fd.set('descriptionFR', section?.descriptionFR+"\n Lien : "+section?.link);

    // key.type=section?.type;
    console.log("sec",section);
    if(section?.name)
      fd.set('name', section?.name);

      fd.set('key', section?.type);
  
    section?.imageSubsectionDTOList?.forEach((element, index) => {
      if (element?.imageByte) {
        fd.set(`imagesList[${index}].imageByte`, element?.imageByte);

      }
  
      if (element.id && element.order && element.imageUrl) {
        fd.set(`imagesList[${index}].imageUrl`, element?.imageUrl);
        fd.set(`imagesList[${index}].order`, element?.order);
      }
    });

    if(section?.imageByte){
        fd.set('imageByte',section?.imageByte)
    }  
    const data = {
      type: section.type,
      id: '',
    };
    fd.set('subSectionDTOKeyList[0]', JSON.stringify(data));
    return fd;
  };
  
  
  export const handleSubSectionKeyFormData = (section:SubSectionType)=>{
    const fd = new FormData()
    
    if(section.id){
        fd.append('id',section.id.toString())
    }
    fd.append('title',section.title)
    fd.append('titre',section.titre)
    fd.append('descriptionENG',section.descriptionENG)
    fd.append('descriptionFR',section.descriptionFR)
    if(section.imageByte){
        fd.append('imageByte',section.imageByte)
    }
    if(section.imageId && section.imageUrl){
        fd.append('imageId',section.imageId)
        fd.append('imageUrl',section.imageUrl)
    }
    fd.append('name',section.name)
    
    fd.append('key',typeof section.subSectionDTOKeyList=='string'?section.subSectionDTOKeyList:section.subSectionDTOKeyList?section.subSectionDTOKeyList[0].type:'')
    
    
    section.imageSubsectionDTOList?.forEach((element, index) => {
        if (element.imageByte) {
          fd.append(`imagesList[${index}].imageByte`, element.imageByte);
        }
    
        if (element.id && element.order && element.imageUrl) {
          fd.append(`imagesList[${index}].imageUrl`, element.imageUrl);
          fd.append(`imagesList[${index}].order`, element.order);
        }
    })
    return fd
}


  
  

  

export const handleLogoFormData = (image:File,type:string)=>{
    const fd = new FormData()
    fd.set('label',type)
    fd.set('imageByte',image)

    return fd
}

export const handleUpdateArticleFormData=(article:ArticleType)=>{
  const fd = new FormData()
 
   
  if(article.id)
    fd.append('id',article.id.toString())

  fd.append('name',article.name)
  fd.append('contenue',article.contenue)
  fd.append('type',article.type)
  if(article.footerLinkDescription)
  fd.append('footerLinkDescription',article.footerLinkDescription)
  if(article.urlWeb)
    fd.append('urlWeb',article.urlWeb)
  fd.append('source',article.source)
    if(article.imageByte){
      fd.append('imageByte',article.imageByte)
  }
  if(article.imageId && article.imageUrl){
      fd.append('imageId',article.imageId)
      fd.append('imageUrl',article.imageUrl)
  }


  article?.imageList?.forEach((element:any, index) => {
    if (element.id && element.order && element.imageUrl) {
      fd.set(`imageList[${index}].imageUrl`, element?.imageUrl);
      fd.set(`imageList[${index}].order`, element?.order);
    } 
   
   
  });


  if(article?.imageByte){
      fd.set('imageByte',article?.imageByte)
  }  

  return fd
}
export const handleArticleFormData = (article:ArticleType)=>{
  const fd = new FormData()
 
   
  if(article.id)
    fd.append('id',article.id.toString())

  fd.append('source',article.source)
  fd.append('name',article.name)
  fd.append('contenue',article.contenue)
  fd.append('type',article.type)
  if(article.footerLinkDescription)
  fd.append('footerLinkDescription',article.footerLinkDescription)
  if(article.urlWeb)
    fd.append('urlWeb',article.urlWeb)
  fd.append('source',article.source)
    if(article.imageByte){
      fd.append('imageByte',article.imageByte)
  }
  if(article.imageId && article.imageUrl){
      fd.append('imageId',article.imageId)
      fd.append('imageUrl',article.imageUrl)
  }


  article?.imageList?.forEach((element:any, index) => {
 

    if (element.id && element.order && element.imageUrl) {
      fd.set(`imageList[${index}].imageUrl`, element?.imageUrl);
      fd.set(`imageList[${index}].order`, element?.order);
      console.log(element)
    } 
    else{
      element.imageByte=element;
      fd.set(`imageList[${index}].imageByte`, element.imageByte);
   }
   
  });


  if(article?.imageByte){
      fd.set('imageByte',article?.imageByte)
  }  

  return fd
}



export const handleConciergeFormData = (section:ConciergeType)=>{
  const fd = new FormData()
  fd.set('id',section.id as string)
  fd.set('title',section.title)
  fd.set('titre',section.titre)
  fd.set('descriptionEn',section.descriptionEn)
  fd.set('descriptionFR',section.descriptionFR)
  fd.set('sector',section.sector)
  fd.set('slug',section.slug)
  
  if(section.imageByte){
      fd.set('imageByte',section.imageByte)
  }
  if(section.imageId && section.imageUrl){
      fd.set('imageId',section.imageId)
      fd.set('imageUrl',section.imageUrl)
  }
  return fd
}

export const handleConciergeItemFormData = (section:ConciergeItemType)=>{
  const fd = new FormData()
  fd.set('id',section.id as string)
  fd.set('title',section.title)
  fd.set('titre',section.titre)
  fd.set('descriptionEn',section.descriptionEn)
  fd.set('descriptionFR',section.descriptionFR)
  fd.set('city',section.city)
  fd.set('slug',section.slug)
  
  if(section.imageByte){
      fd.set('imageByte',section.imageByte)
  }
  if(section.imageId && section.imageUrl){
      fd.set('imageId',section.imageId)
      fd.set('imageUrl',section.imageUrl)
  }
  return fd
}


export const handleConciergeServiceFormData = (section:ConciergeServiceType)=>{
  const fd = new FormData()
  
  fd.set('title',section.title)
  fd.set('titre',section.titre)
  fd.set('descriptionEn',section.descriptionEn)
  fd.set('descriptionFR',section.descriptionFR)
  fd.set('name',section.name)
  fd.set('slug',section.slug)
 
  if(section.id)
    fd.append('id',section.id.toString());
    

  if(section.imageByte){
      fd.set('imageByte',section.imageByte)
  }
  if(section.imageId && section.imageUrl){
      fd.set('imageId',section.imageId)
      fd.set('imageUrl',section.imageUrl)
  }
  return fd
}
export const ListhandleConciergeServiceFormData = (selectedOptions:ConciergeServiceType[])=>{
  const fd = new FormData()
  selectedOptions.forEach((serviceDTO, index) => {
    
      fd.append(`conciergeServiceDTOList[${index}].id`, serviceDTO.id+"");
      fd.append(`conciergeServiceDTOList[${index}].name`, serviceDTO.name);
      fd.append(`conciergeServiceDTOList[${index}].slug`, serviceDTO.slug);
      fd.append(`conciergeServiceDTOList[${index}].titre`, serviceDTO.titre);
      fd.append(`conciergeServiceDTOList[${index}].title`, serviceDTO.title);
      fd.append(`conciergeServiceDTOList[${index}].descriptionFR`, serviceDTO.descriptionFR);
      fd.append(`conciergeServiceDTOList[${index}].descriptionEn`, serviceDTO.descriptionEn);
      if(serviceDTO.id){
        fd.append(`conciergeServiceDTOList[${index}].id`,serviceDTO.id.toString())
    }
      if(serviceDTO.imageByte){
          fd.append(`conciergeServiceDTOList[${index}].imageByte`,serviceDTO.imageByte)
      }
      if(serviceDTO.imageId && serviceDTO.imageUrl){
          fd.append(`conciergeServiceDTOList[${index}].imageId`,serviceDTO.imageId)
          fd.append(`conciergeServiceDTOList[${index}].imageUrl`,serviceDTO.imageUrl)
      }
     
   
  });
  
  return fd
}

export const handleConciergeDetailsFormData = (article:ConciergeServiceDetailsType)=>{
  const fd = new FormData()
 
   
  if(article.id)
    fd.append('id',article.id.toString())

  fd.append('title',article.title)
  fd.append('titre',article.titre)
  fd.append('descriptionENG',article.descriptionENG)
  fd.append('descriptionFR',article.descriptionFR)
  if(article.imageByte){
    fd.set('imageByte',article.imageByte)
  }
  if(article.imageId && article.imageUrl){
        fd.set('imageId',article.imageId)
        fd.set('imageUrl',article.imageUrl)
    }
 

  return fd
}


